import {
  DocumentSubtype,
  OrbisDocumentSubtypes,
  RegulatoryReviewDocumentSubtypes,
  RelianceDocumentSubtypes,
} from "utils/constants/doc.constants";
import { ProjectType } from "utils/constants/project.constants";

export const getRequiredDocumentSubtypesByProjectType = (
  projectType: ProjectType | undefined,
): DocumentSubtype[] => {
  switch (projectType) {
    case ProjectType.Reliance:
      return RelianceDocumentSubtypes;

    case ProjectType.Orbis:
      return OrbisDocumentSubtypes;

    case ProjectType.RegulatoryReview:
      return RegulatoryReviewDocumentSubtypes;

    default:
      return [];
  }
};
