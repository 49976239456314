import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Grid } from "@mui/material";
import { FormSelect, FormTextInput } from "components/common/form-fields";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import useCreateProductFamily from "hooks/api/GQL/entityManagement/useCreateProductFamily";
import { TCreateProductFamily } from "hooks/api/GQL/entityManagement/useCreateProductFamily.models";
import { EntityStatus } from "models/entities.models";
import { productFamilyEntityFormDefaultValues } from "./CreateProductFamilyModal.constants";
import {
  ProductFamilyEntityFieldName,
  TProductFamilyEntityForm,
} from "./CreateProductFamilyModal.types";
import {
  getCreateProductFamilyModalSchema,
  getStatusFieldOptions,
} from "./CreateProductFamilyModal.utils";

type TCreateProductFamilyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateProductFamilyModal = ({
  isOpen,
  onClose,
}: TCreateProductFamilyModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { createProductFamily } = useCreateProductFamily();

  const statusFieldOptions = useMemo(() => getStatusFieldOptions(t), [t]);

  const methods = useForm<TProductFamilyEntityForm>({
    mode: "all",
    defaultValues: productFamilyEntityFormDefaultValues,
    resolver: zodResolver(getCreateProductFamilyModalSchema(t)),
  });

  const { formState, handleSubmit, reset } = methods;

  const onCancelCreateSubtance = () => {
    reset();
    onClose();
  };

  const onSubmit = (values: TProductFamilyEntityForm) => {
    const productFamily: TCreateProductFamily = {
      name: values[ProductFamilyEntityFieldName.Name],
      description: values[ProductFamilyEntityFieldName.Description],
      active:
        values[ProductFamilyEntityFieldName.Status] === EntityStatus.Active,
    };

    createProductFamily(productFamily).then(onCancelCreateSubtance);
  };

  const cancelButton = (
    <Button key="cancel" variant="text" onClick={onCancelCreateSubtance}>
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit-button"
      variant="contained"
      disabled={!formState.isValid}
      type="submit"
    >
      {t("button.submit", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      maxWidth="md"
      open={isOpen}
      title={t("createNewProductFamilyModalTitle", { ns: "administration" })}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} rowGap={1} mb={4}>
          <Grid item xs={6}>
            <FormTextInput
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Name}
              label={t("createProductFamilyFormFields.name")}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextInput
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Description}
              label={t("createProductFamilyFormFields.description")}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <FormSelect
              fullWidth
              unregisterOnUnmount={false}
              name={ProductFamilyEntityFieldName.Status}
              label={t("createProductFamilyFormFields.status")}
              options={statusFieldOptions}
            />
          </Grid>
        </Grid>

        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default CreateProductFamilyModal;
