import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ConfirmModal } from "components/shared";
import { TConfirmModalProps } from "components/shared/ConfirmModal";
import {
  TConfirmActions,
  TConfirmContextValue,
  TConfirmModalOptions,
} from "./ConfirmContext.types";

export const ConfirmContext = createContext<TConfirmContextValue | undefined>(
  undefined,
);

const DEFAULT_OPTIONS: TConfirmModalOptions = {
  title: "",
  confirmButtonText: "",
  cancelButtonText: "",
};

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);

  const optionsRef = useRef<
    Partial<TConfirmModalProps & { content: ReactNode }>
  >({});

  const onCancel = useRef<() => void>(() => {});
  const onConfirm = useRef<() => void>(() => {});

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm.current?.();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel.current?.();
  };

  const attemptConfirm = useCallback(
    (customOptions: TConfirmModalOptions, callbacks: TConfirmActions) => {
      const mergedOptions: Partial<TConfirmModalProps> = {
        ...DEFAULT_OPTIONS,
        ...customOptions,
      };

      optionsRef.current = mergedOptions;

      if (callbacks?.onConfirm) {
        onConfirm.current = callbacks.onConfirm;
      }

      if (callbacks?.onCancel) {
        onCancel.current = callbacks.onCancel;
      }

      setIsOpen(true);
    },
    [],
  );

  const title = optionsRef.current.title
    ? optionsRef.current.title
    : t("pleaseConfirm");

  const onConclude = (shouldConfirm: boolean) =>
    shouldConfirm ? handleConfirm() : handleCancel();

  const confirmButtonText = optionsRef.current.confirmButtonText
    ? optionsRef.current.confirmButtonText
    : t("button.confirm");

  const cancelButtonText = optionsRef.current.cancelButtonText
    ? optionsRef.current.cancelButtonText
    : t("button.cancel");

  return (
    <ConfirmContext.Provider value={{ attemptConfirm }}>
      {children}
      <ConfirmModal
        open={isOpen}
        onConclude={onConclude}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        title={title}
      >
        {optionsRef.current.content ? optionsRef.current.content : null}
      </ConfirmModal>
    </ConfirmContext.Provider>
  );
};

export const useConfirm = (): TConfirmContextValue => {
  const context = useContext(ConfirmContext);

  if (context === undefined) {
    throw new Error("useConfirm must be used within an ConfirmProvider");
  }

  return context;
};
