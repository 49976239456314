import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IProductFamiliesResponse } from "./useProductFamilies.models";

export const query = gql`
  query ProductFamilies {
    productFamilies {
      id
      name
      description
      sourceArn
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;

const useProductFamilies = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, error, isLoading, isError, isSuccess } =
    useQuery<IProductFamiliesResponse>(
      [QueryAPIKey.GetProductFamilies],
      () => services.graphQL.request<IProductFamiliesResponse>(query),
      {
        enabled,
        onError: () => {
          showAlert({
            severity: AlertSeverity.Error,
            message: t("notifications.getProductFamiliesFailure"),
          });
        },
      },
    );

  return {
    data: data?.productFamilies ?? [],
    error,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useProductFamilies;
