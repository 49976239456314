import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { AlertProps as TAlertProps } from "@mui/material";
import ToastNotification from "components/shared/ToastNotification";

export type TAlert = {
  message: string;
  title?: string;
  severity?: TAlertProps["severity"];
};

export type TShowAlert = (alert: TAlert) => void;

export type TAlertContextValue = {
  showAlert: TShowAlert;
};

const AlertContext = createContext<TAlertContextValue | undefined>(undefined);

export const useAlerts = (): TAlertContextValue => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error("useAlerts must be used within an AlertsProvider");
  }

  return context;
};

const AlertProvider = (props: PropsWithChildren) => {
  const [alerts, setAlerts] = useState<TAlert[]>([]);

  const showAlert = useCallback((alert: TAlert) => {
    setAlerts((alerts) => [...alerts, alert]);
  }, []);

  const currentNotification = useMemo(() => alerts?.[0], [alerts]);

  const handlePopNotification = useCallback(
    () => setAlerts(alerts.splice(1, alerts.length - 1)),
    [setAlerts, alerts],
  );

  const value = useMemo(() => ({ showAlert }), [showAlert]);

  return (
    <AlertContext.Provider value={value}>
      {alerts.length ? (
        <ToastNotification
          open={Boolean(currentNotification)}
          {...currentNotification}
          onClose={handlePopNotification}
        />
      ) : null}
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
