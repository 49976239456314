import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { TKeywordParams } from "components/shared/types/filter";

export type TUseKeywordFilterResult = {
  onKeywordChange: (keyword: string) => void;
  keywordParam: TKeywordParams;
  debouncedKeywordParam: string | undefined;
};

const useKeywordFilter = (
  postChange?: (keyword: string) => void,
): TUseKeywordFilterResult => {
  const [keywordParam, setKeywordParam] = useState<TKeywordParams>({});

  const onKeywordChange = useCallback((keyword: string) => {
    setKeywordParam({ ...(keyword && { keyword }) });
  }, []);

  const [debouncedKeywordParam] = useDebounce(keywordParam?.keyword, 400);

  useEffect(() => {
    if (debouncedKeywordParam !== undefined) {
      postChange?.(debouncedKeywordParam);
    }
  }, [debouncedKeywordParam, postChange]);

  return useMemo(
    () => ({
      keywordParam,
      onKeywordChange,
      debouncedKeywordParam,
    }),
    [keywordParam, onKeywordChange, debouncedKeywordParam],
  );
};

export default useKeywordFilter;
