import { createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "i18next";
import {
  ProductFamilyTable,
  TProductFamilyRowData,
} from "./ProductFamilyTable.types";

const columnHelper = createColumnHelper<TProductFamilyRowData>();

export const getColumns = (t: TFunction) => [
  columnHelper.accessor(ProductFamilyTable.Id, {
    header: "",
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }),
  columnHelper.accessor(ProductFamilyTable.Name, {
    header: t("productFamily.productFamilyColumns.name"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(ProductFamilyTable.Description, {
    header: t("productFamily.productFamilyColumns.description"),
    filterFn: "arrIncludes",
  }),
  columnHelper.accessor(ProductFamilyTable.Status, {
    header: t("productFamily.productFamilyColumns.status"),
    filterFn: "arrIncludes",
  }),

  columnHelper.display({
    header: t("productFamily.productFamilyColumns.actions"),
    meta: {
      width: "5%",
    },
    cell: ({ row }) => row.original.actions,
  }),
];
