import { TStyles } from "utils/Styles";

const styles: TStyles = {
  bodyTextContainer: {
    ml: 4,
  },

  unorderedList: {
    pl: 0,
  },

  orderedList: {
    ml: 2,
  },

  listItem: {
    pb: 1,
    py: 0.5,
    fontWeight: "inherit",
  },
};

export default styles;
