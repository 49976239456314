import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumbs from "components/shared/Breadcrumbs";
import { useProject } from "context/ProjectContext";
import useBreadcrumbs from "hooks/breadcrumbs/useBreadcrumbs";
import styles from "./AssetPageHeader.styles";

type TAssetPageHeaderProps = {
  title: string;
  actions?: React.ReactNode;
};

const AssetPageHeader = ({ title, actions }: TAssetPageHeaderProps) => {
  const { t } = useTranslation("common");

  const { project } = useProject();

  const breadcrumbs = useBreadcrumbs({
    pathNamesMapping: { projectId: project?.name },
    depth: 3,
  });

  return (
    <Box sx={styles.container}>
      <Stack direction="column">
        <Breadcrumbs
          ariaLabel={t("ariaLabels.breadcrumbs")}
          breadcrumbs={breadcrumbs}
        />
        <Typography variant="h5" component="h1">
          {title}
        </Typography>
      </Stack>
      {actions}
    </Box>
  );
};

export default AssetPageHeader;
