import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TCreateSubstanceEntityMutationData } from "./useCreateSubstanceEntity.models";

export const query = gql`
  mutation CreateSubstance($substanceEntity: SubstanceInput) {
    createSubstance(substanceEntity: $substanceEntity) {
      id
      name
      drugClassType {
        id
        code
        name
      }
      inn
      atcCode
      active
      entityOrganizationIds
    }
  }
`;

const useCreateSubstanceEntity = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutate } = useMutation(
    ({ substanceEntity }: TCreateSubstanceEntityMutationData) =>
      services.graphQL
        .request(query, {
          substanceEntity,
        })
        .then(() => {
          showAlert({
            message: t("createSubstanceSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetSubstances);
        })
        .catch((error) => {
          showAlert({
            message: t("createSubstanceFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, isError, isSuccess, createSubstance: mutate };
};

export default useCreateSubstanceEntity;
