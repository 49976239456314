import { matchRoutes } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { parsePath } from "utils/helpers";
import { TTab } from "utils/types";

export const getTabValue = (path: string) =>
  parsePath(path).pop()?.toLowerCase();

export const getCurrentTabValue = (
  tabs: TTab[],
  pathname: string,
  defaultPath: string,
) => {
  const matches = matchRoutes(tabs, pathname);
  const path = isEmpty(matches) ? defaultPath : pathname;

  return getTabValue(path);
};
