import { useState } from "react";
import { Stack } from "@mui/material";
import { Loading } from "components/shared";
import useSubstances from "hooks/api/GQL/entityManagement/useSubstances";
import ManageSubstanceModal from "./ManageSubstanceModal";
import { TSubstance } from "./Substances.types";
import SubstancesTable from "./SubstancesTable";

const Substances = () => {
  const [shouldShowSubstanceControlModal, setShouldShowSubstanceControlModal] =
    useState(false);

  const [substanceToEdit, setSubstanceToEdit] = useState<TSubstance>();

  const { data, isLoading } = useSubstances();

  const onClickManageSubstance = (substance?: TSubstance) => {
    substance && setSubstanceToEdit(substance);
    setShouldShowSubstanceControlModal(true);
  };

  return (
    <Stack direction="column" spacing={2}>
      {isLoading ? (
        <Loading />
      ) : (
        <SubstancesTable
          onClickManageSubstance={onClickManageSubstance}
          substances={data}
        />
      )}

      <ManageSubstanceModal
        open={shouldShowSubstanceControlModal}
        substanceToEdit={substanceToEdit}
        onClose={() => setShouldShowSubstanceControlModal(false)}
      />
    </Stack>
  );
};

export default Substances;
