export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE =
  MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const MILLISECONDS_IN_HOUR =
  SECONDS_IN_MINUTE * MINUTES_IN_HOUR * MILLISECONDS_IN_SECOND;
export const HOURS_IN_DAY = 24;
export const DUE_THRESHOLD = 72;
export const SATURDAY = 6;
export const SUNDAY = 0;
// 2 minute cause:
// business requirements says that you need to request continue editing each 2 minutes
export const REACQUIRE_EDIT_LOCK_THRESHOLD_IN_MINUTES = 2;
export const END_OF_DAY_TIME = "23:59:59";
export const DATE_TIME_INDICATOR = "T";
