import { assetsBasePart } from "./constants";

export default {
  byId: (id: string) => `${assetsBasePart}/projects/${id}`,
  all: () => `${assetsBasePart}/projects`,
  questions: (id: string) => `${assetsBasePart}/projects/${id}/questions`,
  members: (id: string) => `${assetsBasePart}/projects/${id}/members`,
  memberStatus: (id: string) =>
    `${assetsBasePart}/projects/${id}/members/status`,
  recent: () => `${assetsBasePart}/projects/recent`,
  status: (id: string) => `${assetsBasePart}/projects/${id}/status`,
  cancel: (id: string) => `${assetsBasePart}/projects/${id}/cancel`,
  update: (id: string) => `${assetsBasePart}/projects/${id}`,
  summary: (id: string) => `${assetsBasePart}/projects/${id}/summary`,
  membersById: (projectId: string, memberId: string) =>
    `${assetsBasePart}/projects/${projectId}/members/${memberId}`,
  subTypes: (projectType: string) =>
    `${assetsBasePart}/project-types/${projectType}/subtypes`,
  projectTypes: () => `${assetsBasePart}/project-types`,
  projectTypesDefinitions: () => `${assetsBasePart}/project-types/definitions`,
  byProjectType: (projectType: string) =>
    `${assetsBasePart}/project-types/${projectType}`,
};
