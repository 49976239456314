import { useState } from "react";
import { Stack } from "@mui/material";
import ChangeProductStatusModal from "./ChangeProductStatusModal";
import ChangeProductVariantStatusModal from "./ChangeProductVariantStatusModal";
import CreateProductModal from "./CreateProductModal";
import ManageProductVariantModal from "./ManageProductVariantModal";
import {
  ProductModificationAction,
  TEntityProduct,
  TProductVariant,
} from "./Products.types";
import ProductsTable from "./ProductsTable";

export const Products = () => {
  const [productToEdit, setProductToEdit] = useState<TEntityProduct>();

  const [productVariantToEdit, setProductVariantToEdit] =
    useState<TProductVariant>();

  const [productModificationAction, setProductModificationAction] =
    useState<ProductModificationAction | null>(null);

  const [activeVariantIndex, setActiveVariantIndex] = useState<number>();

  const onCreateProductVariant = (product: TEntityProduct) => {
    setProductToEdit(product);
    setProductModificationAction(ProductModificationAction.ManageVariant);
  };

  const onEditProductVariant = (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
  ) => {
    setProductToEdit(product);
    setProductVariantToEdit(productVariant);
    setProductModificationAction(ProductModificationAction.ManageVariant);
  };

  const onChangeProductVariantStatus = (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
    variantIndex: number,
  ) => {
    setProductToEdit(product);
    setProductVariantToEdit(productVariant);
    setActiveVariantIndex(variantIndex + 1);
    setProductModificationAction(ProductModificationAction.ChangeVariantStatus);
  };

  const onConcludeProductModification = () => {
    setProductModificationAction(null);
    setProductToEdit(undefined);
    setProductVariantToEdit(undefined);
    setActiveVariantIndex(undefined);
  };

  const onChangeProductStatus = (product: TEntityProduct | undefined) => {
    setProductToEdit(product);
    setProductModificationAction(ProductModificationAction.ChangeOverallStatus);
  };

  const onClickCreateProduct = () => {
    setProductModificationAction(ProductModificationAction.CreateProduct);
  };

  return (
    <Stack direction="column" spacing={2}>
      <ProductsTable
        onEditProductVariant={onEditProductVariant}
        onCreateProductVariant={onCreateProductVariant}
        onClickCreateProduct={onClickCreateProduct}
        onChangeProductVariantStatus={onChangeProductVariantStatus}
        onChangeProductStatus={onChangeProductStatus}
      />
      <ManageProductVariantModal
        isOpen={
          productModificationAction === ProductModificationAction.ManageVariant
        }
        product={productToEdit}
        variantToEdit={productVariantToEdit}
        onClose={onConcludeProductModification}
      />
      <CreateProductModal
        isOpen={
          productModificationAction === ProductModificationAction.CreateProduct
        }
        onClose={() => setProductModificationAction(null)}
      />
      <ChangeProductVariantStatusModal
        isOpen={
          productModificationAction ===
          ProductModificationAction.ChangeVariantStatus
        }
        product={productToEdit}
        productVariant={productVariantToEdit}
        onClose={onConcludeProductModification}
        variantIndex={activeVariantIndex}
      />
      <ChangeProductStatusModal
        isOpen={
          productModificationAction ===
          ProductModificationAction.ChangeOverallStatus
        }
        product={productToEdit}
        onClose={onConcludeProductModification}
      />
      <ChangeProductVariantStatusModal
        isOpen={
          productModificationAction ===
          ProductModificationAction.ChangeVariantStatus
        }
        product={productToEdit}
        productVariant={productVariantToEdit}
        onClose={onConcludeProductModification}
        variantIndex={activeVariantIndex}
      />
    </Stack>
  );
};

export default Products;
