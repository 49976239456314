import { forwardRef, Ref, useRef, useState } from "react";
import {
  AvatarGroup as MuiAvatarGroup,
  AvatarGroupProps as MuiAvatarGroupProps,
  Stack,
  Typography,
} from "@mui/material";
import { TCountry } from "utils/constants/countries.constants";
import Avatar, { TAvatarProps } from "../Avatar";
import Flag from "../Flag";
import Tooltip from "../Tooltip";
import getStyles from "./AvatarGroup.styles";

export type TAvatarItem = TAvatarProps & {
  id: string;
  value: string | keyof TCountry;
  tooltip?: string;
};

type TAvatarGroupProps = MuiAvatarGroupProps & {
  size?: number;
  type?: "default" | "flag";
  data: TAvatarItem[];
};

const AvatarGroup = forwardRef(
  (
    {
      sx,
      max = 3,
      type = "default",
      size,
      data,
      ...muiAvatarGroupProps
    }: TAvatarGroupProps,
    ref: Ref<HTMLElement>,
  ) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState<Boolean>(false);
    const avatarSize = size || type === "flag" ? 18 : 26;
    const styles = { ...getStyles(avatarSize).avatarGroup, ...sx };
    const hiddenData = data.slice(max - 1);

    const leaveTimer = useRef<ReturnType<typeof setTimeout>>();
    const LEAVE_DELAY = 70;

    const handleLeave = () => {
      clearTimeout(leaveTimer.current);
      leaveTimer.current = setTimeout(() => {
        setIsTooltipVisible(false);
      }, LEAVE_DELAY);
    };

    const renderContent = ({
      tooltip,
      value,
      id,
      ...otherProps
    }: TAvatarItem) => {
      return type === "default" ? (
        <Avatar alt={tooltip} key={id} {...otherProps}>
          {value}
        </Avatar>
      ) : (
        <Flag
          alt={tooltip}
          country={value as keyof TCountry}
          key={id}
          data-testid={value as keyof TCountry}
          data-qaid={value as keyof TCountry}
          {...otherProps}
        />
      );
    };

    return (
      <Stack direction="row">
        <Tooltip
          open={Boolean(isTooltipVisible)}
          title={
            <>
              {hiddenData.map((avatar, avatarIndex) => (
                <Typography key={`avatar-${avatarIndex}`} fontSize={11} mt={1}>
                  {avatar.tooltip || avatar.value}
                </Typography>
              ))}
            </>
          }
        >
          <MuiAvatarGroup
            ref={ref}
            max={max}
            sx={styles}
            spacing={6}
            componentsProps={{
              additionalAvatar: {
                onMouseEnter: () => {
                  clearTimeout(leaveTimer.current);
                  setIsTooltipVisible(true);
                },
                onMouseLeave: handleLeave,
              },
            }}
            {...muiAvatarGroupProps}
          >
            {data.map((avatar) =>
              avatar.tooltip ? (
                <Tooltip key={avatar.id} title={avatar.tooltip}>
                  {renderContent(avatar)}
                </Tooltip>
              ) : (
                renderContent(avatar)
              ),
            )}
          </MuiAvatarGroup>
        </Tooltip>
      </Stack>
    );
  },
);

export default AvatarGroup;
