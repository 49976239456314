/* istanbul ignore file */
import { Comment, CommentView } from "@ckeditor/ckeditor5-comments";
import { CommentViewConfig } from "@ckeditor/ckeditor5-comments/src/config";
import { ButtonView } from "@ckeditor/ckeditor5-ui";
import { type Locale } from "ckeditor5/src/utils";
import "./privatecomment.css";

export default class PrivateCommentView extends CommentView {
  _model: any;
  isPublic: any;
  isVisibilityEditable: any;
  shouldHideComment: any;

  constructor(locale: Locale, model: Comment, config: CommentViewConfig) {
    super(locale, model, config);

    this._model = model;

    // NOTE: All comments are public for now until we eventually develop a cross-tenancy strategy
    // for them. The functionality works from the frontend side, so it is being left in here (but
    // inaccessible to the user) as a model for doing other types of custom view changes.

    // Set initial values of attributes for new comments that come from CKEditor
    // rather than from the backend
    this._model.attributes.isPublic = true;
    this._model.attributes.isVisibilityEditable = false;
    this._model.attributes.initialContent = this._model.content;

    // Bind values to comment model custom attributes.
    // We have to declare the fields on the class to satisfy the compiler, but delete them before
    // trying to bind them to avoid bind throwing due to the field already existing.
    delete this.isPublic;
    this.bind("isPublic").to(this._model, "attributes", (attributes) => {
      return attributes.isPublic;
    });

    delete this.isVisibilityEditable;
    this.bind("isVisibilityEditable").to(
      this._model,
      "attributes",
      (attributes) => {
        return attributes.isVisibilityEditable;
      },
    );

    // Bind custom property instead of attributes when a value is only for UI use.
    // When custom attribute values change, CKEditor will run updateComment
    // This sends needless PUT requests to the Backend, which is not concerned with UI filters.
    delete this.shouldHideComment;
    this.set("shouldHideComment", false);
  }

  getTemplate() {
    // Use the original method to get the default template.
    // The default template definition structure is described in the comment view API.
    const templateDefinition: any = super.getTemplate();

    // If `isPublic` is `false` add the `ck-comment--private` class to the template.
    templateDefinition.children[0].attributes.class.push(
      this.bindTemplate.if(
        "isPublic",
        "ck-comment--private",
        (value) => !value,
      ),
    );

    // If `shouldHideComment` is `true`, add the `ck-hidden` class to the template
    templateDefinition.children[0].attributes.class.push(
      this.bindTemplate.if("shouldHideComment", "ck-hidden", (value) => value),
    );

    // Add the new button next to other comment buttons (edit and remove).
    templateDefinition.children[0].children[1].children[1].children._items.unshift(
      this._createPrivateButtonView(),
    );

    return templateDefinition;
  }

  _createPrivateButtonView() {
    // Create a new button view.
    const button = new ButtonView(this.locale);

    // Set the label and the icon for the button.
    const publicIcon =
      // eslint-disable-next-line
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M569.354 231.631C512.97 135.949 407.81 72 288 72 168.14 72 63.004 135.994 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.031 376.051 168.19 440 288 440c119.86 0 224.996-63.994 281.354-159.631a47.997 47.997 0 0 0 0-48.738zM288 392c-102.556 0-192.091-54.701-240-136 44.157-74.933 123.677-127.27 216.162-135.007C273.958 131.078 280 144.83 280 160c0 30.928-25.072 56-56 56s-56-25.072-56-56l.001-.042C157.794 179.043 152 200.844 152 224c0 75.111 60.889 136 136 136s136-60.889 136-136c0-31.031-10.4-59.629-27.895-82.515C451.704 164.638 498.009 205.106 528 256c-47.908 81.299-137.444 136-240 136z"/></svg>';

    const privateIcon =
      // eslint-disable-next-line
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M272.702 359.139c-80.483-9.011-136.212-86.886-116.93-167.042l116.93 167.042zM288 392c-102.556 0-192.092-54.701-240-136 21.755-36.917 52.1-68.342 88.344-91.658l-27.541-39.343C67.001 152.234 31.921 188.741 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.004 376.006 168.14 440 288 440a332.89 332.89 0 0 0 39.648-2.367l-32.021-45.744A284.16 284.16 0 0 1 288 392zm281.354-111.631c-33.232 56.394-83.421 101.742-143.554 129.492l48.116 68.74c3.801 5.429 2.48 12.912-2.949 16.712L450.23 509.83c-5.429 3.801-12.912 2.48-16.712-2.949L102.084 33.399c-3.801-5.429-2.48-12.912 2.949-16.712L125.77 2.17c5.429-3.801 12.912-2.48 16.712 2.949l55.526 79.325C226.612 76.343 256.808 72 288 72c119.86 0 224.996 63.994 281.354 159.631a48.002 48.002 0 0 1 0 48.738zM528 256c-44.157-74.933-123.677-127.27-216.162-135.007C302.042 131.078 296 144.83 296 160c0 30.928 25.072 56 56 56s56-25.072 56-56l-.001-.042c30.632 57.277 16.739 130.26-36.928 171.719l26.695 38.135C452.626 346.551 498.308 306.386 528 256z"/></svg>';

    // Use the localization service.
    // The feature will be translatable.
    const t = this.locale.t;

    button.set({
      icon: this._model.attributes.isPublic ? privateIcon : publicIcon,
      isToggleable: true,
      tooltip: t(
        this._model.attributes.isPublic ? "Set as Private" : "Set as Public",
      ),
      withText: true,
    });

    // Add a class to the button to style it.
    button.extendTemplate({
      attributes: {
        class: "ck-button-private",
      },
    });

    // The button should be enabled if the comment model is not in read-only mode.
    // The same setting is used for other comment buttons.
    button.bind("isEnabled").to(this._model, "isReadOnly", (value) => !value);

    // Set the button visibility according to attribute `isVisibilityEditable`.
    button.bind("isVisible").to(this, "isVisibilityEditable");

    // Bind the icon and tooltip values to the comment model's custom isPublic value.
    button
      .bind("icon")
      .to(this, "isPublic", (value) => (value ? privateIcon : publicIcon));
    button
      .bind("tooltip")
      .to(this, "isPublic", (value) =>
        t(value ? "Set as Private" : "Set as Public"),
      );

    // When the button is clicked, change the comment state in the comment model.
    // The `attributes.isPublic` value will be available together with other comment data.
    button.on("execute", () => {
      this._model.setAttribute("isPublic", !this._model.attributes.isPublic);
    });

    return button;
  }

  _showComment() {
    this.set("shouldHideComment", false);
  }

  _hideComment() {
    this.set("shouldHideComment", true);
  }
}
