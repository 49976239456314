import { SvgIconProps } from "@mui/material";
import { TFunction } from "i18next";
import {
  AddDocumentIcon,
  DocumentAttachmentIcon,
  DocumentIcon,
  GlobeLocatorIcon,
  SpreadSheetIcon,
} from "assets/icons";

const getDocumentIcons = (t: TFunction, color?: SvgIconProps["color"]) =>
  ({
    attachment: (
      <DocumentAttachmentIcon
        aria-label={t(
          "documentsConstants.documentIcons.documentAttachmentIcon",
          {
            ns: "documents",
          },
        )}
        color={color}
        data-testid="DocumentAttachmentIcon"
        data-qaid="DocumentAttachmentIcon"
      />
    ),
    document: (
      <DocumentIcon
        aria-label={t("documentsConstants.documentIcons.documentIcon", {
          ns: "documents",
        })}
        color={color}
        data-testid="DocumentIcon"
        data-qaid="DocumentIcon"
      />
    ),
    assessment: (
      <AddDocumentIcon
        aria-label={t("documentsConstants.documentIcons.addDocumentIcon", {
          ns: "documents",
        })}
        color={color}
        data-testid="AddDocumentIcon"
        data-qaid="AddDocumentIcon"
      />
    ),
    GSP: (
      <GlobeLocatorIcon
        aria-label={t("documentsConstants.documentIcons.globeLocatorIcon", {
          ns: "documents",
        })}
        color={color}
        data-testid="GSPIcon"
        data-qaid="GSPIcon"
      />
    ),
    spreadsheet: (
      <SpreadSheetIcon
        aria-label={t("documentsConstants.documentIcons.spreadsheetIcon", {
          ns: "documents",
        })}
        color={color}
        data-testid="SpreadsheetIcon"
        data-qaid="SpreadsheetIcon"
      />
    ),
  } as const);

export default getDocumentIcons;
