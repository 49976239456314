import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { ArrowDropDown } from "@mui/icons-material";
import { Stack, SxProps, Theme } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { Button, InfoButton } from "components/shared";
import ButtonMenu from "components/shared/ButtonMenu";
import { useAlerts } from "context/AlertContext";
import { useProject } from "context/ProjectContext";
import useHAQsWithContent from "hooks/api/GQL/haq/useHAQsWithContent";
import useLockAssetEditing from "hooks/api/REST/documents/useLockAssetEditing";
import useOrganizations from "hooks/api/REST/organizations/useOrganizations";
import useGetWorkflowExecution from "hooks/api/REST/workflow/useGetWorkflowExecution";
import useUser from "hooks/useUser";
import {
  IWorkflowExecution,
  WorkflowExecutionStatus,
} from "models/workflow.models";
import {
  ChangeHAQDueDateModal,
  ConfirmStatusChangeModalBasic,
  DeleteHAQ,
  RequestHAQExternalReview,
  SendHAQCopyModal,
  SubmitHAQResponseModal,
} from "screens/Project/screens/HAQ/components";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { AlertSeverity } from "utils/constants/alerts.constants";
import {
  DEFAULT_POLLING_INTERVAL,
  QueryAPIKey,
} from "utils/constants/api.constants";
import { AssetType } from "utils/constants/assets.constants";
import { HAQAction, HAQStatus } from "utils/constants/HAQ.constants";
import {
  getHAQShortName,
  getIsAssetOwnedByUserOrganization,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { useHAQActions } from "./useHAQActions";
import styles from "./HAQActions.styles";

const HAQActions = ({
  onToggleInfoDrawer,
  onDeleteHAQ,
  HAQId,
  HAQName,
  sx: propsSx,
}: {
  onToggleInfoDrawer?: (isOpen: boolean) => void;
  onDeleteHAQ?: () => void;
  HAQId: string;
  HAQName: string;
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslation(["HAQ", "notifications"]);

  const { project } = useProject();

  const { showAlert } = useAlerts();

  const { organization } = useUser();

  const { organizations } = useOrganizations();

  const queryClient = useQueryClient();

  const { data } = useHAQsWithContent({ HAQIds: [HAQId] });

  const HAQ = data?.questions[0]!;

  const HAQResponse = data?.questions[0]?.children?.find(
    (item) => item.type === AssetType.QuestionResponse,
  );

  const focusedAsset = isHAUser(organization) ? HAQ : HAQResponse;

  const { isAnyWorkflowInProgress, setIsAnyWorkflowInProgress } =
    useHAQContext();

  const {
    action,
    resetAction,
    actionOptions,
    workflowInProgressId,
    setWorkflowInProgressId,
    statusChangeActionQueued,
    onExecuteManualStatusChange,
    changeStatusModal,
  } = useHAQActions(HAQId);

  const { lockAssetEditing } = useLockAssetEditing();

  const handleStatusChange = (shouldConfirm: boolean) => {
    if (!shouldConfirm) {
      onExecuteManualStatusChange(false);
    }

    if (shouldConfirm) {
      focusedAsset?.id &&
        lockAssetEditing(focusedAsset.id, {
          onSuccess: () => onExecuteManualStatusChange(true),
        });
    }
  };

  useGetWorkflowExecution(workflowInProgressId ?? "", {
    enabled: Boolean(workflowInProgressId),
    refetchInterval: (data) => {
      return data?.status === WorkflowExecutionStatus.Complete
        ? false
        : DEFAULT_POLLING_INTERVAL;
    },
    onSuccess: (workflowInfoResponse: IWorkflowExecution) => {
      if (workflowInfoResponse?.status === WorkflowExecutionStatus.Complete) {
        showAlert({
          severity: AlertSeverity.Success,
          message: t("workflowMessages.HAQStatusChangeSuccessMessage", {
            HAQName,
            ns: "notifications",
          }),
        });
        queryClient.invalidateQueries(QueryAPIKey.GetAsset);
        queryClient.invalidateQueries(QueryAPIKey.GetAssetContent);
        queryClient.invalidateQueries(QueryAPIKey.GetAssetsWithContent);
        queryClient.invalidateQueries(QueryAPIKey.GetAssetShareHistory);
        setWorkflowInProgressId(null);
        setIsAnyWorkflowInProgress(false);
      }
    },
  });

  const isActionsDisabled =
    Boolean(isAnyWorkflowInProgress) || isEmpty(actionOptions);

  return (
    <Stack direction="row" spacing={2}>
      {onToggleInfoDrawer && (
        <InfoButton
          data-qaid="haq-info-button"
          onClick={() => onToggleInfoDrawer(true)}
          aria-label={t("label.infoButton")}
        />
      )}
      {project?.active && (
        <ButtonMenu
          value={focusedAsset?.status}
          options={actionOptions}
          buttonProps={{
            endIcon: <ArrowDropDown />,
            variant: "contained",
            sx: {
              ...(!isActionsDisabled
                ? styles.actionsButton
                : styles.disabledActionsButton),
              ...propsSx,
            } as SxProps<Theme>,
          }}
        >
          <Button
            data-qaid="haq-actions-menu-button"
            disabled={isActionsDisabled}
            variant="contained"
          >
            {t("viewPage.menuButton.label")}
          </Button>
        </ButtonMenu>
      )}

      <SendHAQCopyModal
        HAQName={HAQName}
        HAQId={HAQId}
        onClose={resetAction}
        isOpen={action === HAQAction.SendCopyToSponsor}
      />
      <SubmitHAQResponseModal
        HAQName={HAQName}
        HAQId={HAQId}
        onClose={resetAction}
        isOpen={action === HAQAction.SubmitToHA}
      />
      <RequestHAQExternalReview
        onClose={resetAction}
        isOpen={action === HAQAction.RequestExternalReview}
      />
      <DeleteHAQ
        HAQ={HAQ}
        onClose={resetAction}
        isOpen={action === HAQAction.Delete}
        onDelete={onDeleteHAQ}
      />
      {changeStatusModal}
      <ConfirmStatusChangeModalBasic
        isOpen={
          statusChangeActionQueued === HAQStatus.Draft ||
          statusChangeActionQueued === HAQStatus.Final
        }
        selectedContent={[
          getHAQShortName(HAQ, t("HAQAcronym", { ns: "HAQ" }), organizations),
        ]}
        handleConfirm={handleStatusChange}
        confirmationText={
          isSponsorUser(organization) &&
          getIsAssetOwnedByUserOrganization(HAQ, organization)
            ? t("HAQ:manualStatusChange.confirmIntentOfImportedStatusChange")
            : t("HAQ:manualStatusChange.confirmIntentOfStatusChange")
        }
      />
      {action === HAQAction.ChangeDueDate && (
        <ChangeHAQDueDateModal
          onClose={resetAction}
          selectedRows={[{ HAQ, HAQResponse: HAQ.children[0] }]}
          defaultDueDate={HAQ?.metadata.dueDate}
        />
      )}
    </Stack>
  );
};

export default HAQActions;
