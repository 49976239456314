import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IEntityProductsResponse } from "./useEntityProducts.models";

export const query = gql`
  query GetEntityProducts {
    entityProducts {
      id
      tradeName
      nonproprietaryName
      ownerTenantId
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      substances {
        id
        name
        drugClassType {
          id
          code
          name
        }
        INN
        ATC
        status
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
      }
      productFamilies {
        id
        createdBy
        createdDate
        lastModifiedBy
        lastModifiedDate
        name
        status
        description
      }
      linkedOrganizations {
        id
        name
        role
      }
      status
    }
  }
`;

const useEntityProducts = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } = useQuery<IEntityProductsResponse>(
    [QueryAPIKey.GetEntityProducts],
    () => services.graphQL.request<IEntityProductsResponse, Variables>(query),
    {
      enabled,
      onError: () => {
        showAlert({
          severity: AlertSeverity.Error,
          message: t("notifications.getEntityProductsFailure"),
        });
      },
    },
  );

  return {
    entityProducts: data?.entityProducts ?? [],
    isLoading,
    isSuccess,
  };
};

export default useEntityProducts;
