// TODO: Notification types are still a work in progress

import { ProjectStatus } from "utils/constants/project.constants";
import { TAtLeast } from "utils/types";
import { IPagination } from "./pagination.models";

// https://www.figma.com/file/hTJQhRbMv8NXHC5LgWUieE/Accumulus-Platform-Designs?node-id=8946%3A153815
export type TNotificationType =
  | "comment"
  | "mention"
  | "project"
  | "reply"
  | "suggestion"
  | "misc";

export type TNotificationMetadataAssetType = "DOCUMENT" | "TASK";

export type TNotificationCreatedBy = "workflow" | "asset" | string;

export type TNotificationTemplateType = string;

export enum NotificationCTAType {
  Task = "TASK",
  Asset = "ASSET",
  Project = "PROJECT",
  HAQs = "HA_QUESTIONS",
  Content = "CONTENT",
}

export enum NotificationAssetType {
  Document = "DOCUMENT",
  Component = "COMPONENT",
  GSP = "GSP",
  AssessmentAid = "ASSESSMENT_AID",
  LOA = "LOA",
  SupportingInformation = "SUPPORTING_INFORMATION",
  ToplineResult = "TOPLINE_RESULT",
  References = "REFERENCES",
  Comment = "COMMENT",
  Question = "QUESTION",
  QuestionResponse = "QUESTION_RESPONSE",
  Product = "PRODUCT",
  ProductFamily = "PRODUCT_FAMILY",
  Substance = "SUBSTANCE",
  GSPComponent = "GSP_COMPONENT",
  DossierDifference = "DOSSIER_DIFFERENCE",
  AssessmentAidSource = "ASSESSMENT_AID_SOURCE",
  AssessmentReport = "ASSESSMENT_REPORT",
  ImportedHAQ = "IMPORTED_HAQ",
  HADecisionLetter = "HA_DECISION_LETTER",
}

export type TNotificationTemplateMetadata = {
  assetId: string;
  ctaType: NotificationCTAType;
  assetName: string;
  assetType: NotificationAssetType;
  projectId: string;
  templateText: string;
  ctaRequiredTags: string;
  taskId?: string;
  taskOwner?: string;
  custom?: string;
  parentAssetId?: string;
  reason?: string;
  numOfDocuments?: string;
  documentNames?: string;
  targetStatus?: ProjectStatus;
  previousStatus?: ProjectStatus;
};

export interface INotification {
  id: string;
  createdBy: TNotificationCreatedBy;
  createdDate: Date | string;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | string;
  version?: number;
  templateType: TNotificationTemplateType;
  userId: string;
  read: boolean;
  metadata: TNotificationTemplateMetadata;
}

export type TNotificationItem = TAtLeast<INotification, "id" | "templateType">;

export interface IGetNotificationsForUser extends IPagination {
  content: INotification[];
}

export interface INotificationRequestParams {
  query: string;
  page: number;
  size: number;
}
