import { TProjectMetadata } from "models/projects.models";
import { HAQStatus } from "utils/constants/HAQ.constants";
import { TAtLeast, TISODateFormat } from "utils/types";

export interface IStartWorkflowPayload {
  originator: string;
  assignees: string[];
  asset: string;
  assets: string[];
  dueDate: TISODateFormat;
  instructions: string;
  project: string;
  projectMetadata: TProjectMetadata;
  targetTenant: string;
  targetTenants: string[];
  targetProject: string;
  message: string;
  status: string;
}

export enum WorkflowExecutionStatus {
  Running = "RUNNING",
  Complete = "COMPLETE",
  Failed = "FAILED",
}

export interface IWorkflowExecutionInput {
  assets: string[];
  originator: string;
  status: HAQStatus;
  targetTenant: string;
}

export interface IWorkflowExecution {
  id: string;
  input: IWorkflowExecutionInput;
  name: string;
  status: WorkflowExecutionStatus;
  workflowSpec: string;
}

export interface IEventRequestData {
  [key: string]: any;
}
export interface IStartWorkflowRequestData
  extends TAtLeast<IStartWorkflowPayload, "originator"> {}

export type TCancelWorkflowTasks = {
  workflowId: string;
};
