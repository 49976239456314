import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import SingleSelectTypeahead, {
  TSingleSelectTypeaheadOption,
  TSingleSelectTypeaheadProps,
} from "components/shared/SingleSelectTypeahead";

export type TFormSingleSelectTypeaheadProps<
  FormType extends FieldValues,
  T,
> = Omit<
  TSingleSelectTypeaheadProps<T>,
  "openText" | "closeText" | "clearText" | "noOptionsText" | "textFieldProps"
> & {
  name: FieldPath<FormType>;
};

const FormSingleSelectTypeahead = <FormType extends FieldValues>({
  name,
  helperText,
  getValue = (option: TSingleSelectTypeaheadOption) => option.value,
  onChange,
  onClose,
  ...props
}: TFormSingleSelectTypeaheadProps<FormType, string>) => {
  const { t } = useTranslation("common");

  const { control, trigger } = useFormContext<FormType>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <SingleSelectTypeahead<string>
          {...field}
          {...props}
          id={name}
          error={!!error}
          helperText={error?.message || helperText}
          openText={t("button.open")}
          closeText={t("button.close")}
          clearText={t("button.clear")}
          noOptionsText={t("emptyState.noResults")}
          textFieldProps={{
            size: "small",
          }}
          getValue={getValue}
          onChange={(value) => {
            field.onChange(value || "");
            onChange?.(value);
          }}
          onClose={() => {
            trigger(name);
            onClose?.();
          }}
        />
      )}
    />
  );
};

export default FormSingleSelectTypeahead;
