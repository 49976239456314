export const DESCRIPTION_OF_CHANGE_MAX_LENGTH = 400;
export const PROJECT_DESCRIPTION = 400;
export const STATUS_CHANGE_REASON_MAX_LENGTH = 255;
export const HA_FLAGS_MAX_LENGTH = 3;
export const PORTFOLIO_FLAGS_MAX_LENGTH = 3;

export enum ProjectStatus {
  Planned = "PLANNED",
  Acknowledged = "ACKNOWLEDGED",
  InProgress = "IN_PROGRESS",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
}

export enum ProjectType {
  Orbis = "ORBIS",
  Labeling = "LABELING",
  Reliance = "RELIANCE",
  RegulatoryReview = "REGULATORY_REVIEW",
}

export enum RelianceEventType {
  CMC = "CMC",
  OriginalApplication = "ORIGINAL_APPLICATION",
  Efficacy = "EFFICACY",
  Labeling = "LABELING",
  Safety = "SAFETY",
  Other = "OTHER",
}

export enum RelianceEventSubtype {
  DrugSubstance = "DRUG_SUBSTANCE",
  DrugProduct = "DRUG_PRODUCT",
  NotApplicable = "N/A",
  RawMaterials = "RAW_MATERIALS",
  Facilities = "FACILITIES",
  Placebo = "PLACEBO",
  Other = "OTHER",
  Biopharmaceutics = "BIOPHARMACEUTICS",
  Pharmacokinetics = "PHARMACOKINETICS",
  Pharmacodynamics = "PHARMACODYNAMICS",
  EfficacyAndSafety = "EFFICACY_AND_SAFETY",
  PostMarketing = "POST_MARKETING",
  NewIndication = "NEW_INDICATION",
  CMCChange = "CMC_CHANGE",
  SafetyUpdates = "SAFETY_UPDATES",
  Administrative = "ADMINISTRATIVE",
  Pharmacovigilance = "PHARMACOVIGILANCE",
  Pharmacology = "PHARMACOLOGY",
  Toxicology = "TOXICOLOGY",
}

export enum RegulatoryReviewEventType {
  CMC = "CMC",
  OriginalApplication = "ORIGINAL_APPLICATION",
  Efficacy = "EFFICACY",
  Labeling = "LABELING",
  Safety = "SAFETY",
  Other = "OTHER",
}

export enum RegulatoryReviewEventSubtype {
  DrugSubstance = "DRUG_SUBSTANCE",
  DrugProduct = "DRUG_PRODUCT",
  NotApplicable = "N/A",
  RawMaterials = "RAW_MATERIALS",
  Facilities = "FACILITIES",
  Placebo = "PLACEBO",
  Other = "OTHER",
  Biopharmaceutics = "BIOPHARMACEUTICS",
  Pharmacokinetics = "PHARMACOKINETICS",
  Pharmacodynamics = "PHARMACODYNAMICS",
  EfficacyAndSafety = "EFFICACY_AND_SAFETY",
  PostMarketing = "POST_MARKETING",
  NewIndication = "NEW_INDICATION",
  CMCChange = "CMC_CHANGE",
  SafetyUpdates = "SAFETY_UPDATES",
  Administrative = "ADMINISTRATIVE",
  Pharmacovigilance = "PHARMACOVIGILANCE",
  Pharmacology = "PHARMACOLOGY",
  Toxicology = "TOXICOLOGY",
}

export enum CreateProjectStep {
  ProjectType,
  ProjectInfo,
}

export enum RegulatoryEvent {
  Initial = "INITIAL",
  Supplemental = "SUPPLEMENTAL",
}

export enum RelianceRegulatoryEvent {
  InitialApplication = "INITIAL_APPLICATION",
  PostApprovalChange = "POST_APPROVAL_CHANGE",
  ScientificAdvice = "SCIENTIFIC_ADVICE",
  NonApplicationSpecific = "NON_APPLICATION_SPECIFIC",
  Investigational = "INVESTIGATIONAL",
  RegulatoryMeetings = "REGULATORY_MEETINGS",
}

export enum RegulatoryReviewRegulatoryEvent {
  InitialApplication = "INITIAL_APPLICATION",
  PostApprovalChange = "POST_APPROVAL_CHANGE",
  ScientificAdvice = "SCIENTIFIC_ADVICE",
  NonApplicationSpecific = "NON_APPLICATION_SPECIFIC",
  Investigational = "INVESTIGATIONAL",
  RegulatoryMeetings = "REGULATORY_MEETINGS",
}

export enum ProcessingStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Accepted = "ACCEPTED",
}

export enum DecisionStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Denied = "DENIED",
  NoLongerParticipating = "NO_LONGER_PARTICIPATING",
  Withdrawn = "WITHDRAWN",
}

export enum ParticipationType {
  LeadHA = "LEAD_HEALTH_AUTHORITY",
  ReferenceHA = "REFERENCE_HEALTH_AUTHORITY",
  Rapporteur = "RAPPORTEUR",
  CoRapporteur = "CO_RAPPORTEUR",
  Module3Reviewer = "MODULE_3_REVIEWER",
  Module4Reviewer = "MODULE_4_REVIEWER",
  Module5Reviewer = "MODULE_5_REVIEWER",
  Participant = "PARTICIPANT",
  RelianceHA = "RELIANCE_HEALTH_AUTHORITY",
  Observer = "OBSERVER",
}

export enum ProjectNamePrefix {
  Reliance = "RL",
  RegulatoryReview = "RR",
}

export type TProjectStatusTabs = {
  ALL: string;
  [ProjectStatus.Planned]: string;
  [ProjectStatus.Acknowledged]: string;
  [ProjectStatus.InProgress]: string;
  [ProjectStatus.Completed]: string;
  [ProjectStatus.Cancelled]: string;
};

export const PROJECT_STATUS_TABS: TProjectStatusTabs = {
  ALL: "utilsProjectConstants.projectStatus.all",
  [ProjectStatus.Planned]: "utilsProjectConstants.projectStatus.planned",
  [ProjectStatus.Acknowledged]:
    "utilsProjectConstants.projectStatus.acknowledged",
  [ProjectStatus.InProgress]: "utilsProjectConstants.projectStatus.inProgress",
  [ProjectStatus.Completed]: "utilsProjectConstants.projectStatus.completed",
  [ProjectStatus.Cancelled]: "utilsProjectConstants.projectStatus.cancelled",
};

export const DEFAULT_EVENT_TYPE = "CMC";
export const DEFAULT_DECISION_STATUS = DecisionStatus.Pending;
export const DEFAULT_DECISION_STATUS_ORDER = [
  DecisionStatus.Pending,
  DecisionStatus.Approved,
  DecisionStatus.Denied,
  DecisionStatus.NoLongerParticipating,
  DecisionStatus.Withdrawn,
];
export const HA_DECISION_STATUS_ORDER = [
  DecisionStatus.Approved,
  DecisionStatus.Pending,
  DecisionStatus.Denied,
  DecisionStatus.NoLongerParticipating,
  DecisionStatus.Withdrawn,
];

export const NUMBER_OF_VISIBLE_RECENT_PROJECTS = 3;

export const RegulatoryReviewEventTypes = {
  [RegulatoryReviewEventType.OriginalApplication]: [],
  [RegulatoryReviewEventType.CMC]: [
    RegulatoryReviewEventSubtype.DrugSubstance,
    RegulatoryReviewEventSubtype.DrugProduct,
    RegulatoryReviewEventSubtype.RawMaterials,
    RegulatoryReviewEventSubtype.Facilities,
    RegulatoryReviewEventSubtype.Placebo,
    RegulatoryReviewEventSubtype.Other,
  ],
  [RegulatoryReviewEventType.Efficacy]: [
    RegulatoryReviewEventSubtype.Biopharmaceutics,
    RegulatoryReviewEventSubtype.Pharmacokinetics,
    RegulatoryReviewEventSubtype.Pharmacodynamics,
    RegulatoryReviewEventSubtype.EfficacyAndSafety,
    RegulatoryReviewEventSubtype.PostMarketing,
    RegulatoryReviewEventSubtype.Other,
  ],
  [RegulatoryReviewEventType.Labeling]: [
    RegulatoryReviewEventSubtype.NewIndication,
    RegulatoryReviewEventSubtype.CMCChange,
    RegulatoryReviewEventSubtype.SafetyUpdates,
    RegulatoryReviewEventSubtype.Administrative,
    RegulatoryReviewEventSubtype.Other,
  ],
  [RegulatoryReviewEventType.Safety]: [
    RegulatoryReviewEventSubtype.Pharmacovigilance,
    RegulatoryReviewEventSubtype.Pharmacology,
    RegulatoryReviewEventSubtype.Pharmacokinetics,
    RegulatoryReviewEventSubtype.Toxicology,
    RegulatoryReviewEventSubtype.Other,
  ],
  [RegulatoryReviewEventType.Other]: [],
};

export const RelianceEventTypes = {
  [RelianceEventType.OriginalApplication]: [],
  [RelianceEventType.CMC]: [
    RelianceEventSubtype.DrugSubstance,
    RelianceEventSubtype.DrugProduct,
    RelianceEventSubtype.RawMaterials,
    RelianceEventSubtype.Facilities,
    RelianceEventSubtype.Placebo,
    RelianceEventSubtype.Other,
  ],
  [RelianceEventType.Efficacy]: [
    RelianceEventSubtype.Biopharmaceutics,
    RelianceEventSubtype.Pharmacokinetics,
    RelianceEventSubtype.Pharmacodynamics,
    RelianceEventSubtype.EfficacyAndSafety,
    RelianceEventSubtype.PostMarketing,
    RelianceEventSubtype.Other,
  ],
  [RelianceEventType.Labeling]: [
    RelianceEventSubtype.NewIndication,
    RelianceEventSubtype.CMCChange,
    RelianceEventSubtype.SafetyUpdates,
    RelianceEventSubtype.Administrative,
    RelianceEventSubtype.Other,
  ],
  [RelianceEventType.Safety]: [
    RelianceEventSubtype.Pharmacovigilance,
    RelianceEventSubtype.Pharmacology,
    RelianceEventSubtype.Pharmacokinetics,
    RelianceEventSubtype.Toxicology,
    RelianceEventSubtype.Other,
  ],
  [RelianceEventType.Other]: [],
};

export type TRegulatoryReviewEventTypes = typeof RegulatoryReviewEventTypes;

export type TRelianceEventTypes = typeof RelianceEventTypes;
