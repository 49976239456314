import { IProjectData } from "hooks/api/GQL/project/useProjectData.models";
import { TUserPermissions } from "hooks/permissions/usePermissions.types";
import { TUseUserRoles } from "hooks/user/useUserRoles/useUserRoles";
import { IDocument } from "models/documents.models";
import { IOrganization } from "models/organizations.models";
import { TProjectMetadata } from "models/projects.models";
import { TUserData } from "models/users.models";
import {
  DOCUMENT_TYPES_FOR_DOWNLOAD,
  DOCUMENT_TYPES_FOR_PREVIEW,
  DocumentStatus,
  DocumentSubtype,
} from "utils/constants/doc.constants";
import { ProjectType } from "utils/constants/project.constants";
import {
  IShareHistoryItem,
  ShareHistoryItemStatus,
} from "utils/constants/shareHistory.constants";
import {
  getIsAssetCreatedByUser,
  getIsAssetOwnedByUserOrganization,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";

export const getIsDocumentUnstructuredAAID = (document: IDocument | null) =>
  document?.status === DocumentStatus.Uploaded &&
  document?.type === DocumentSubtype.AssessmentAidSource;

export const getIsDocumentStructuredAAID = (document: IDocument | null) =>
  document?.type === DocumentSubtype.AssessmentAid &&
  document?.status === DocumentStatus.Draft;

export const getIsDocumentComposite = (document: IDocument | null) =>
  document?.type === DocumentSubtype.GSP ||
  document?.type === DocumentSubtype.AssessmentAid;

export const getCanDocumentBeSubmitted = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  !Boolean(document?.metadata?.submissionDate) &&
  document?.status === DocumentStatus.Final;

export const getIsDocumentReadyForSending = (
  status: DocumentStatus | undefined,
  organization: IOrganization | undefined,
) =>
  [DocumentStatus.Final, DocumentStatus.Cleared].includes(
    status as DocumentStatus,
  ) && isHAUser(organization);

export const getCanDocumentBeDeleted = (
  project: IProjectData<TProjectMetadata> | null | undefined,
  document: IDocument | null,
  user: TUserData | undefined,
  organization: IOrganization | undefined,
  userRoles: TUseUserRoles,
  permissions: Partial<TUserPermissions>,
) => {
  if (!project?.active) {
    return false;
  }

  switch (project?.type) {
    case ProjectType.Reliance:
      return getCanDocumentBeDeletedForReliance(
        document,
        user,
        organization,
        userRoles,
        Boolean(permissions.canDeleteContentToTenancy),
      );

    default:
      return getCanDocumentBeDeletedByDefault(document, organization);
  }
};

export const getCanDocumentBeDeletedForReliance = (
  document: IDocument | null,
  user: TUserData | undefined,
  organization: IOrganization | undefined,
  { isContentEditor }: TUseUserRoles,
  canDeleteContentToTenancy: boolean,
) => {
  if (isContentEditor && !getIsAssetCreatedByUser(document, user)) {
    return false;
  }

  // This permission is not covered fully by our usePermissions hook

  return (
    ((isSponsorUser(organization) && isContentEditor) ||
      canDeleteContentToTenancy) &&
    document?.status === DocumentStatus.InVerification &&
    getIsAssetOwnedByUserOrganization(document, organization)
  );
};

export const getCanDocumentBeDeletedByDefault = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  document?.status !== DocumentStatus.Final &&
  document?.type !== DocumentSubtype.GSP &&
  getIsAssetOwnedByUserOrganization(document, organization);

export const getCanDocumentBeSent = (
  document: IDocument | null,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  getIsDocumentReadyForSending(document?.status, organization);

export const getCanDocumentBeConverted = (
  document: IDocument | null,
  organization: IOrganization | undefined,
  permissions: Partial<TUserPermissions>,
) =>
  (permissions.canEditContentInAllProjects || permissions.canEditContent) &&
  getIsAssetOwnedByUserOrganization(document, organization) &&
  getIsDocumentUnstructuredAAID(document);

export const getCanDocumentBeShared = (
  document: IDocument,
  organization: IOrganization | undefined,
  { isProjectManager }: TUseUserRoles,
): Boolean => {
  const { status } = document;

  if (!getIsAssetOwnedByUserOrganization(document, organization)) {
    return false;
  }

  if (
    !Object.values(DocumentSubtype).includes(document.type) ||
    document.type === DocumentSubtype.GSP
  ) {
    return false;
  }

  if (!isProjectManager || status === DocumentStatus.Shared) {
    return false;
  }

  if (isSponsorUser(organization) && status === DocumentStatus.Final) {
    return true;
  }

  if (isHAUser(organization) && status === DocumentStatus.Final) {
    return true;
  }

  return false;
};

export const getIsDocumentTypeSupportedForPreview = (docType?: string) =>
  DOCUMENT_TYPES_FOR_PREVIEW.some((type) => type === docType);

export const getIsDocumentTypeSupportedForDownload = (docType?: string) =>
  DOCUMENT_TYPES_FOR_DOWNLOAD.some((type) => type === docType);

export const getCanDocumentBePreviewed = (
  document: IDocument | null,
  permissions: Partial<TUserPermissions>,
) =>
  (permissions.canViewContentInAllProjects || permissions.canViewContent) &&
  !getIsDocumentComposite(document) &&
  getIsDocumentTypeSupportedForPreview(document?.metadata?.fileType);

export const getCanDocumentBeDownloaded = (document: IDocument | null) =>
  !getIsDocumentComposite(document) &&
  getIsDocumentTypeSupportedForDownload(document?.metadata?.fileType);

export const getIsDocumentReadyForStartReview = (
  document: IDocument,
  organization: IOrganization | undefined,
) =>
  getIsAssetOwnedByUserOrganization(document, organization) &&
  document.status === DocumentStatus.Draft;

export const getIsDocumentShared = (
  documentShareHistory: IShareHistoryItem[] | undefined = [],
) =>
  documentShareHistory?.some(
    (shareHistoryItem) =>
      shareHistoryItem.status === ShareHistoryItemStatus.Shared,
  );

export const getCanDocumentBeHAQed = (
  document: IDocument,
  organization: IOrganization | undefined,
) => ![DocumentSubtype.GSP].includes(document.type) && isHAUser(organization);

export const getCanViewSubmitHistory = (
  document: IDocument,
  organization: IOrganization | undefined,
) =>
  // This permission is not covered by our usePermissions hook
  Boolean(
    document.status === DocumentStatus.Final &&
      isSponsorUser(organization) &&
      document.metadata?.submissionDate,
  );
