import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import Button from "components/shared/Button";
import Modal, { TModalProps } from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import TextField from "components/shared/TextField";
import useUpdateUser from "hooks/api/REST/user/useUpdateUser";
import { TUserData } from "models/users.models";
import {
  getUpdateTitleModalSchema,
  UpdateTitleModalForm,
} from "./UpdateTitleModal.types";
import styles from "./UpdateTitleModal.styles";

export type TUpdateTitleModalProps = Pick<TModalProps, "open"> & {
  user: TUserData;
  onClose?: () => void;
  onSave?: () => void;
};

type TUpdateTitleForm = {
  newTitle: string;
};

const UpdateTitleModal = (props: TUpdateTitleModalProps) => {
  const { user, onSave, onClose, ...modalProps } = props;
  const { updateUser } = useUpdateUser();

  const { t } = useTranslation(["user", "common"]);

  const onSubmit = async ({ newTitle }: TUpdateTitleForm) => {
    await updateUser({ ...user, title: newTitle });
    onSave?.();
    reset();
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<TUpdateTitleForm>({
    mode: "all",
    resolver: zodResolver(getUpdateTitleModalSchema(t)),
  });

  const handleClose = useCallback(() => {
    onClose?.();
    reset();
  }, [onClose, reset]);

  return (
    <Modal
      data-qaid="update-title-modal"
      title={t("profileUpdateTitleModal.modalTitle")}
      {...modalProps}
    >
      <Box
        sx={styles.content}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          data-qaid="new-title-text-field"
          {...register(UpdateTitleModalForm.NewTitle)}
          fullWidth
          label={t("profileUpdateTitleModal.newTitleLabel")}
          error={!!errors[UpdateTitleModalForm.NewTitle]}
          helperText={errors[UpdateTitleModalForm.NewTitle]?.message}
          errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
            ns: "common",
          })}
          defaultValue={user.title}
        />
        <ModalActionButtons>
          <Button
            key="cancel-button"
            data-qaid="cancel-button"
            onClick={handleClose}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="save-button"
            data-qaid="save-button"
            variant="contained"
            type="submit"
          >
            {t("button.save", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </Box>
    </Modal>
  );
};

export default UpdateTitleModal;
