import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArrowDropDown } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { Button, Tooltip } from "components/shared";
import ButtonMenu, {
  TButtonMenuProps,
  TMenuOption,
} from "components/shared/ButtonMenu";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { DocumentAction, DocumentStatus } from "utils/constants/doc.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { includeInArray } from "utils/helpers";
import { isSponsorUser } from "utils/user/organization";
import { useDocumentsContext } from "../DocumentsContext";
import { getAvailableDocumentStatusChangeOptions } from "../utils/documentStatusChanges";
import {
  getAllDocumentsHaveSameStatus,
  getAreDocumentsEligibleForDeletion,
  getAreDocumentsEligibleForStatusChange,
  getCanDocumentsBeSubmitted,
  getDocumentsContainSubmittedContent,
} from "./DocumentsBulkActions.utils";
import styles from "./DocumentsBulkActions.styles";

export const DocumentsBulkActions = () => {
  const { project } = useProject();
  const { permissions } = usePermissions(project?.id ?? "");
  const { user, organization } = useUser();
  const userRoles = useUserRoles(project?.id || "");

  const { selectedDocuments, setCurrentActionOnDocument } =
    useDocumentsContext();

  const selectedDocumentsCounter = selectedDocuments.length;

  const { t } = useTranslation(["documents"]);

  const documentStatusChangeOptions = getAvailableDocumentStatusChangeOptions(
    selectedDocuments[0],
    organization,
    user,
    permissions,
    t,
  );

  const availableActions = useMemo(() => {
    return (
      ([
        ...includeInArray(
          {
            id: DocumentAction.ChangeStatus,
            label: t("documents.utils.options.changeStatus"),
            items: documentStatusChangeOptions,
            onClick: (option) => {
              if (option && selectedDocuments[0].status !== option.id) {
                setCurrentActionOnDocument({
                  documents: selectedDocuments,
                  documentAction: DocumentAction.ChangeStatus,
                  targetStatus: option.id as DocumentStatus,
                });
              }
            },
          } as TMenuOption,
          getAreDocumentsEligibleForStatusChange(selectedDocuments) &&
            (permissions.canChangeStateContent ||
              permissions.canChangeStateContentInAssociatedProjects),
        ),
        ...includeInArray(
          {
            id: DocumentAction.Delete,
            label: t("documents.utils.options.deleteDocument"),
            onClick: () => {
              setCurrentActionOnDocument({
                documents: selectedDocuments,
                documentAction: DocumentAction.Delete,
              });
            },
          },
          getAreDocumentsEligibleForDeletion(selectedDocuments) &&
            permissions.canDeleteContentToTenancy,
        ),
        ...includeInArray(
          {
            id: DocumentAction.Submit,
            label: t("documents.utils.options.submitDocument"),
            onClick: () => {
              setCurrentActionOnDocument({
                documents: selectedDocuments,
                documentAction: DocumentAction.Submit,
              });
            },
          },
          getCanDocumentsBeSubmitted(selectedDocuments, organization) &&
            project?.status === ProjectStatus.InProgress &&
            permissions.canSendContentToTenancy,
        ),
      ] as TMenuOption[]) || []
    );
  }, [
    documentStatusChangeOptions,
    setCurrentActionOnDocument,
    selectedDocuments,
    organization,
    permissions,
    project,
    t,
  ]);

  const reasonWhyActionIsProhibited = useMemo(() => {
    if (
      isSponsorUser(organization) &&
      userRoles.isContentEditor &&
      getAllDocumentsHaveSameStatus(selectedDocuments, DocumentStatus.Final)
    ) {
      return t("documentsBulkActions.cannotBePerformedBySponsorEditor");
    }

    if (!isEmpty(selectedDocuments)) {
      if (
        !getAllDocumentsHaveSameStatus(
          selectedDocuments,
          selectedDocuments[0].status,
        )
      ) {
        return t("documentsBulkActions.contentNotInSameStatus");
      }

      if (getDocumentsContainSubmittedContent(selectedDocuments)) {
        return t("documentsBulkActions.contentAlreadySubmitted");
      }

      if (isEmpty(availableActions)) {
        return t("documentsBulkActions.noActiveActions");
      }
    }

    return null;
  }, [organization, selectedDocuments, t, userRoles, availableActions]);

  const buttonMenuProps: TButtonMenuProps = {
    buttonProps: {
      endIcon: <ArrowDropDown />,
      variant: "outlined",
    },
    options: availableActions,
    ...(documentStatusChangeOptions.length && {
      value: selectedDocuments[0].status,
    }),
  };

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={styles.actionMeta}
        >
          <Box sx={styles.selectedCounter}>
            <Typography variant="body2" sx={styles.counterText}>
              {t("documentsBulkActions.actionsCounterLabel", {
                count: selectedDocumentsCounter,
              })}
            </Typography>
          </Box>
          {reasonWhyActionIsProhibited && (
            <Tooltip title={reasonWhyActionIsProhibited}>
              <WarningAmberIcon
                aria-label={reasonWhyActionIsProhibited}
                fontSize="small"
                color="action"
                sx={styles.iconContainer}
              />
            </Tooltip>
          )}
        </Stack>

        <Box>
          <ButtonMenu {...buttonMenuProps}>
            <Button
              size="large"
              variant="outlined"
              disabled={Boolean(reasonWhyActionIsProhibited)}
            >
              {t("documentsBulkActions.actionsLabel")}
            </Button>
          </ButtonMenu>
        </Box>
      </>
    </Stack>
  );
};
