import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "@ckeditor/ckeditor5-core";
import { useAlerts } from "context/AlertContext";
import addComment from "./methods/addComment";
import addCommentThread from "./methods/addCommentThread";
import getCommentThread from "./methods/getCommentThread";
import removeComment from "./methods/removeComment";
import removeCommentThread from "./methods/removeCommentThread";
import reopenCommentThread from "./methods/reopenCommentThread";
import resolveCommentThread from "./methods/resolveCommentThread";
import updateComment from "./methods/updateComment";
import useAddComment from "./useAddComment";
import useRemoveComment from "./useRemoveComment";
import useUpdateComment from "./useUpdateComment";

export type TUseComments = (
  editorOrContext: Editor | Context,
) => CommentsAdapter;

export default function useComments(): TUseComments {
  const { t } = useTranslation("annotations", {
    keyPrefix: "notifications",
  });

  const { mutateAsync: addCommentMutation } = useAddComment();
  const { mutateAsync: updateCommentMutation } = useUpdateComment();
  const { mutateAsync: removeCommentMutation } = useRemoveComment();

  const { showAlert } = useAlerts();

  const queryClient = useQueryClient();

  return useCallback(
    (editorOrContext): CommentsAdapter => ({
      getCommentThread: getCommentThread(
        editorOrContext,
        showAlert,
        t,
        queryClient,
      ),
      addComment: addComment(editorOrContext, addCommentMutation),
      updateComment: updateComment(editorOrContext, updateCommentMutation),
      removeComment: removeComment(editorOrContext, removeCommentMutation),
      addCommentThread: addCommentThread(
        queryClient,
        showAlert,
        t,
        editorOrContext,
      ),

      // The backend doesn't support actual threads yet, which these methods are for. This
      // functionality won't work for now, but we can still emulate the new CKEditor thread data
      // model with the existing comment API endpoints.
      updateCommentThread: () => Promise.resolve(),
      resolveCommentThread: resolveCommentThread(
        queryClient,
        showAlert,
        t,
        editorOrContext,
      ),
      reopenCommentThread: reopenCommentThread(
        queryClient,
        showAlert,
        t,
        editorOrContext,
      ),
      removeCommentThread: removeCommentThread(editorOrContext, showAlert, t),
    }),
    [
      addCommentMutation,
      queryClient,
      removeCommentMutation,
      updateCommentMutation,
      t,
      showAlert,
    ],
  );
}
