import { getPaletteColor } from "theme/helpers";
import { JIRA_BUTTON_DIAMETER } from "theme/theme.constants";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  content: {
    flexGrow: 1,
    height: "100vh",
    overflowX: "auto",
    backgroundColor: getPaletteColor("background.default"),
    pb: JIRA_BUTTON_DIAMETER,
  },
};

export default styles;
