import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import Avatar from "components/shared/Avatar";
import { IOrganization } from "models/organizations.models";
import { TUserData } from "models/users.models";
import ProfileCard from "screens/Profile/ProfileCard";
import UpdateTitleModal from "screens/Profile/UpdateTitleModal";
import { getFullName, getInitials } from "utils/helpers";
import { includeInArray } from "utils/helpers";
import { isHAUser } from "utils/user/organization";
import PrivacyPolicy from "assets/pdf/accumulus_privacy_policy.pdf";
import TermsOfUse from "assets/pdf/accumulus_terms_of_use.pdf";

type TPersonalInfoProps = {
  user: TUserData;
  organization: IOrganization | undefined;
};

const PersonalInfo = ({ user, organization }: TPersonalInfoProps) => {
  const [shouldShowUpdateTitleModal, setShouldShowUpdateTitleModal] =
    useState(false);

  const { t } = useTranslation("user");

  const { title } = user;

  const fields = useMemo(() => {
    return [
      {
        id: "0",
        label: t("profilePersonalInfo.fields.fullName"),
        value: getFullName(user),
      },
      {
        id: "1",
        label: t("profilePersonalInfo.fields.org"),
        value: organization?.name,
      },
      {
        id: "2",
        label: t("profilePersonalInfo.fields.title"),
        value: title,
        onEdit: () => setShouldShowUpdateTitleModal(true),
      },
      {
        id: "3",
        label: t("profilePersonalInfo.fields.userType"),
        value: "", // ToDo (RK) UserType -> to be fixed once BE provide us the needed data
      },
      {
        id: "4",
        label: t("profilePersonalInfo.fields.avatar"),
        value: <Avatar>{getInitials(user)}</Avatar>,
      },
      {
        id: "5",
        label: t("authLayout.privacyPolicyLink"),
        value: (
          <Link to={PrivacyPolicy} rel="noreferrer" target="_blank">
            {t("profilePersonalInfo.fields.link")}
          </Link>
        ),
      },
      ...includeInArray(
        {
          id: "6",
          label: t("authLayout.termsOfUseLink"),
          value: (
            <Link to={TermsOfUse} rel="noreferrer" target="_blank">
              {t("profilePersonalInfo.fields.link")}
            </Link>
          ),
        },
        isHAUser(organization),
      ),
    ];
  }, [t, user, organization, title]);

  const closeModal = () => setShouldShowUpdateTitleModal(false);

  const handleSave = () => {
    closeModal();
  };

  return (
    <>
      <ProfileCard
        avatar={<PortraitOutlinedIcon />}
        title={t("profilePersonalInfo.profileCardTitle")}
        fields={fields}
      />
      <UpdateTitleModal
        user={user}
        open={shouldShowUpdateTitleModal}
        onClose={closeModal}
        onSave={handleSave}
      />
    </>
  );
};

export default PersonalInfo;
