import { CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "@ckeditor/ckeditor5-core";
import { TCommentForDelete } from "../useRemoveComment";

const removeComment =
  (
    _editorOrContext: Editor | Context, // We will need this later to implement handling of failed API requests
    mutationFn: (commentData: TCommentForDelete) => Promise<any>,
  ): CommentsAdapter["removeComment"] =>
  async (data) => {
    const { channelId, threadId, commentId } = data;

    const { data: responseData } = await mutationFn({
      componentId: String(channelId),
      threadId,
      commentId,
    });

    return responseData;
  };

export default removeComment;
