import { getBorderStyle, getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  teaserContainer: {
    border: getBorderStyle("1px", "solid", "blueGrey.100"),
    borderRadius: "8px",
    backgroundColor: getPaletteColor("common.white"),
    width: "270px",
    height: "194px",
  },

  textCenter: {
    textAlign: "center",
  },

  imgBlock: {
    width: "100%",
    margin: "17px 17px 0 17px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  contentBlock: {
    background: getPaletteColor("blueGrey.50"),
    borderRadius: "0 0 8px 8px",
    padding: "0 13px",

    "& button": {
      padding: "3px 8px",
    },
  },
};

export default styles;
