import { createContext, ReactNode, useMemo, useState } from "react";
import { QueryStatus } from "react-query";
import isEmpty from "lodash/isEmpty";
import { useProject } from "context/ProjectContext";
import useDocuments from "hooks/api/REST/documents/useDocuments";
import { IDocument } from "models/documents.models";
import { MAX_SIZE } from "utils/constants/api.constants";
import {
  DocumentAction,
  DocumentStatus,
  DocumentSubtype,
  ParseJobStatus,
} from "utils/constants/doc.constants";
import { compareDates } from "utils/helpers";
import { getRequiredDocumentSubtypesByProjectType } from "../utils/getRequiredDocumentSubtypesByProjectType";

type TApiData<DataType> = {
  data: DataType[] | undefined;
  status: QueryStatus;
};

export type TParseJobContext = {
  id: string;
  parsedAssetId: string;
  sourceAssetId: string;
  status: ParseJobStatus;
  documentName?: string;
  isImportedHAQ?: boolean;
};

type TDocumentsContextValue = {
  setSelectedDocumentsIds: (ids: Set<string>) => void;
  selectedDocuments: IDocument[] | [];
  currentActionOnDocument: TCurrentActionOnDocument;
  setCurrentActionOnDocument: (documents: TCurrentActionOnDocument) => void;
  parseJobContext: TParseJobContext | null;
  setParseJobContext: (state: TParseJobContext | null) => void;
  requiredDocuments: TApiData<IDocument>;
  documentSubtypes: DocumentSubtype[];
};

const DocumentsContext = createContext<TDocumentsContextValue | undefined>(
  undefined,
);

type TDocumentsContextProviderProps = {
  children: ReactNode;
};

export type TCurrentActionOnDocument = {
  documents: IDocument[];
  documentAction: DocumentAction | null;
  targetStatus?: DocumentStatus;
};

const DocumentsContextProvider = (props: TDocumentsContextProviderProps) => {
  const { project } = useProject();

  const documentSubtypes = getRequiredDocumentSubtypesByProjectType(
    project?.type,
  );

  const { documents, status } = useDocuments(
    {
      projectIds: project?.id,
      assetTypes: documentSubtypes.join(),
      size: MAX_SIZE,
    },
    { enabled: Boolean(project?.id) && !isEmpty(documentSubtypes) },
  );

  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<Set<string>>(
    new Set(),
  );

  const [currentActionOnDocument, setCurrentActionOnDocument] =
    useState<TCurrentActionOnDocument>({
      documents: [],
      documentAction: null,
    });

  const [parseJobContext, setParseJobContext] =
    useState<TParseJobContext | null>(null);

  const contextValue: TDocumentsContextValue = useMemo(
    () => ({
      setSelectedDocumentsIds,
      selectedDocuments:
        documents?.filter((document) =>
          selectedDocumentsIds.has(document.id),
        ) || [],
      currentActionOnDocument,
      setCurrentActionOnDocument,
      parseJobContext,
      setParseJobContext,
      requiredDocuments: {
        data: documents?.sort((a, b) =>
          compareDates(a.createdDate, b.createdDate),
        ),
        status,
      },
      documentSubtypes,
    }),
    [
      selectedDocumentsIds,
      parseJobContext,
      setParseJobContext,
      currentActionOnDocument,
      setCurrentActionOnDocument,
      documents,
      status,
      documentSubtypes,
    ],
  );

  return (
    <DocumentsContext.Provider value={contextValue}>
      {props.children}
    </DocumentsContext.Provider>
  );
};

export { DocumentsContextProvider, DocumentsContext };
export type { TDocumentsContextValue };
