import { TFunction } from "i18next";
import { z } from "zod";
import { TCreateProductEntity } from "hooks/api/GQL/entityManagement/useCreateProductEntity.models";
import { EntityStatus } from "models/entities.models";
import { MEMBER_SCHEMA } from "utils/validators/constraints/validationConstraints";
import {
  ProductEntityFieldName,
  TProductEntityForm,
} from "../ProductEntityFormFields/ProductEntityFormFields.types";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "../ProductVariantFormFields/ProductVariantFormFields.types";

const getCreateProductVariantSchema = (t: TFunction) =>
  z.object({
    [ProductVariantFieldName.ProductType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.RouteOfAdministration]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.ManufacturedDoseForm]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthValue]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthVolumeValue]: z.coerce.number().optional(),
    [ProductVariantFieldName.StrengthVolumeType]: z.string().optional(),
    [ProductVariantFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
  });

export const getCreateProductModalSchema = (t: TFunction) =>
  z.object({
    [ProductEntityFieldName.ProductName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.NonproprietaryName]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedSubstances]: z.array(MEMBER_SCHEMA).min(0, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.LinkedOrganizations]: z
      .array(z.string())
      .optional(),
    [ProductEntityFieldName.LinkedProductFamilies]: z
      .array(MEMBER_SCHEMA)
      .min(1, {
        message: t("validations.requiredField", { ns: "common" }),
      }),
    [ProductEntityFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductEntityFieldName.ProductVariants]: z
      .array(getCreateProductVariantSchema(t))
      .min(1),
  });

const getProductVariantValuesForCreation = (values: TProductVariantForm) => ({
  type: values[ProductVariantFieldName.ProductType],
  routeOfAdministration: values[ProductVariantFieldName.RouteOfAdministration],
  manufacturedDoseForm: values[ProductVariantFieldName.ManufacturedDoseForm],
  strengthValue: values[ProductVariantFieldName.StrengthValue],
  strengthType: values[ProductVariantFieldName.StrengthType],
  strengthVolumeValue:
    values[ProductVariantFieldName.StrengthVolumeValue] ?? null,
  strengthVolumeType: values[ProductVariantFieldName.StrengthVolumeType] ?? "",
  active: values[ProductVariantFieldName.Status] === EntityStatus.Active,
});

export const getProductValuesForCreation = (
  values: TProductEntityForm,
): TCreateProductEntity => {
  return {
    tradeName: values[ProductEntityFieldName.ProductName],
    nonproprietaryName: values[ProductEntityFieldName.NonproprietaryName],
    substanceIds:
      values[ProductEntityFieldName.LinkedSubstances]?.map(
        (linkedSubstance) => linkedSubstance.value,
      ) ?? [],
    entityOrganizationIds:
      values[ProductEntityFieldName.LinkedOrganizations] ?? [],
    productFamilyIds:
      values[ProductEntityFieldName.LinkedProductFamilies]?.map(
        (linkedProductFamily) => linkedProductFamily.value,
      ) ?? [],
    active: values[ProductEntityFieldName.Status] === EntityStatus.Active,
    productVariants:
      values[ProductEntityFieldName.ProductVariants]?.map((variant) =>
        getProductVariantValuesForCreation(variant),
      ) ?? [],
  };
};
