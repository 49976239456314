import { TFunction } from "i18next";
import { z } from "zod";
import { TProductVariant } from "../Products.types";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "./ProductVariantFormFields.types";

export const createProductVariantFormValues = (
  variant?: TProductVariant,
): TProductVariantForm => ({
  [ProductVariantFieldName.ProductType]: variant?.productType?.code ?? "",
  [ProductVariantFieldName.RouteOfAdministration]:
    variant?.routeOfAdministrationType?.code ?? "",
  [ProductVariantFieldName.ManufacturedDoseForm]:
    variant?.manufacturedDoseFormType?.code ?? "",
  [ProductVariantFieldName.StrengthValue]: variant?.strengthValue ?? "",
  [ProductVariantFieldName.StrengthType]: variant?.strengthType?.code ?? "",
  [ProductVariantFieldName.StrengthVolumeValue]:
    variant?.strengthVolumeValue ?? "",
  [ProductVariantFieldName.StrengthVolumeType]:
    variant?.strengthVolumeType?.code ?? "",
  [ProductVariantFieldName.Status]: variant?.status ?? "",
});

export const createProductVariantFormSchema = (t: TFunction) =>
  z.object({
    [ProductVariantFieldName.ProductType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.RouteOfAdministration]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.ManufacturedDoseForm]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthValue]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthVolumeValue]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.StrengthVolumeType]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
    [ProductVariantFieldName.Status]: z.string().min(1, {
      message: t("validations.requiredField", { ns: "common" }),
    }),
  });
