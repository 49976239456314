import { createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QueryStatus } from "react-query";
import { Outlet } from "react-router";
import isEmpty from "lodash/isEmpty";
import { UserContextFallback } from "components/common/ErrorBoundaries";
import Loading from "components/shared/Loading";
import useOrganization from "hooks/api/REST/organizations/useOrganization";
import useLegalAcknowledgements from "hooks/api/REST/user/legalAcknowledgement/useLegalAcknowledgements";
import useCurrentUserRoles from "hooks/api/REST/user/useCurrentUserRoles";
import useGetCurrentUser from "hooks/api/REST/user/useGetCurrentUser";
import { ILegalAcknowledgementData } from "models/legalAcknowledgement.models";
import { IOrganization } from "models/organizations.models";
import { TUserData } from "models/users.models";
import { signOut } from "utils/auth";
import { Role } from "utils/constants/users.constants";

export type TUserContextType = {
  loadingUserStatus: QueryStatus;
  user: TUserData | undefined;
  acknowledgements: ILegalAcknowledgementData[] | undefined;
  organization: IOrganization | undefined;
  isGlobalPM: boolean;
  isCEOnAnyOfTheProjects: boolean;
  isAccumulusAdmin: boolean;
  isProgramAdministrator: boolean;
  isOrganizationAdmin: boolean;
  isBusinessAdmin: boolean;
};

export const UserContext = createContext<TUserContextType | undefined>(
  undefined,
);

export const UserProvider = () => {
  const { t } = useTranslation("user");

  const {
    user,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError,
    status: loadingUserStatus,
  } = useGetCurrentUser();

  const {
    organization,
    isSuccess: isOrganizationSuccess,
    isError: isOrganizationError,
    error: organizationError,
  } = useOrganization(user?.org);

  const {
    acknowledgements,
    isSuccess: isLegalAcknowledgementsSuccess,
    isError: isLegalAcknowledgementsError,
    error: legalAcknowledgementsError,
  } = useLegalAcknowledgements();

  const { roles } = useCurrentUserRoles({ enabled: isUserSuccess });

  const handleLogout = () => {
    signOut({
      shouldRestorePath: false,
      shouldLogoutEverywhere: true,
    }).catch(console.error);
  };

  const value = useMemo(() => {
    const isCEOnAnyOfTheProjects = Boolean(
      roles?.some(
        (role) => role.roleId === Role.ContentEditor && !isEmpty(role.projects),
      ),
    );

    const isGlobalPM = Boolean(
      roles?.some((role) => role.roleId === Role.ProjectManager),
    );

    const isAccumulusAdmin = Boolean(
      roles?.some((role) => role.roleId === Role.AccumulusAdmin),
    );

    const isProgramAdministrator = Boolean(
      roles?.some((role) => role.roleId === Role.ProgramAdministrator),
    );

    const isOrganizationAdmin = Boolean(
      roles?.some((role) => role.roleId === Role.OrganizationAdmin),
    );

    const isBusinessAdmin = Boolean(
      roles?.some((role) => role.roleId === Role.BusinessAdmin),
    );

    return {
      user,
      acknowledgements,
      loadingUserStatus,
      organization,
      isGlobalPM,
      isCEOnAnyOfTheProjects,
      isAccumulusAdmin,
      isProgramAdministrator,
      isOrganizationAdmin,
      isBusinessAdmin,
    };
  }, [loadingUserStatus, user, acknowledgements, organization, roles]);

  const errorReason =
    userError?.response?.data?.message ||
    organizationError?.response?.data?.message ||
    legalAcknowledgementsError?.response?.data?.message ||
    t("errorBoundaryFallbacks.userContext.unknownUserTenant");

  const shouldRenderErrorFallback =
    isUserError || isOrganizationError || isLegalAcknowledgementsError;

  const shouldRenderApplication =
    isUserSuccess && isOrganizationSuccess && isLegalAcknowledgementsSuccess;

  return (
    <UserContext.Provider value={value}>
      {shouldRenderApplication ? (
        <Outlet />
      ) : shouldRenderErrorFallback ? (
        <UserContextFallback onLogout={handleLogout} reason={errorReason} />
      ) : (
        <Loading />
      )}
    </UserContext.Provider>
  );
};
