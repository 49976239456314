import ClearIcon from "@mui/icons-material/Clear";
import { Box, Stack, Typography } from "@mui/material";
import { DocumentIcon } from "assets/icons";
import styles from "./SelectedFile.styles";

type TSelectedFileProps = {
  fileName: string;
  onClear?: () => void;
  fileIcon?: JSX.Element;
  withoutIcon?: boolean;
  clearIconAriaLabel: string;
};

const SelectedFile = (props: TSelectedFileProps) => {
  return (
    <Stack sx={styles.container} direction="row" alignItems="center">
      {!props.withoutIcon && (
        <Stack sx={styles.documentIconWrapper} alignItems="center">
          {props.fileIcon || <DocumentIcon />}
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <Typography variant="body1" sx={styles.fileName}>
          {props.fileName}
        </Typography>

        {props.onClear && (
          <Box
            component="button"
            sx={styles.clearIconWrapper}
            onClick={props.onClear}
            aria-label={props.clearIconAriaLabel}
            data-testid="remove-button"
            data-qaid="remove-button"
          >
            <ClearIcon color="action" />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default SelectedFile;
