import {
  amber,
  blue,
  blueGrey,
  common,
  green,
  grey,
  indigo,
  lightBlue,
  orange,
  red,
  teal,
} from "@mui/material/colors";
import { alpha, PaletteOptions } from "@mui/material/styles";
import { primary, secondary } from "./theme.colors";

const palette: PaletteOptions = {
  primary: {
    main: primary[500],
    dark: primary[700],
    light: primary[300],
    contrastText: common.white,
    ...primary,
  },

  secondary: {
    main: secondary[500],
    dark: secondary[700],
    light: secondary[300],
    contrastText: common.white,
    ...secondary,
  },

  action: {
    active: alpha(common.black, 0.54),
    disabled: alpha(common.black, 0.26),
    disabledBackground: alpha(common.black, 0.12),
    focus: alpha(common.black, 0.12),
    hover: alpha(common.black, 0.04),
    selected: alpha(common.black, 0.08),
  },

  divider: alpha(common.black, 0.12),

  // timeStamp colors are not presented in MUI Kit
  timeStamp: {
    main: {
      backgroundColor: grey[100],
      color: grey[500],
    },
    overdue: {
      backgroundColor: "#fff0ef",
      color: "#581200",
    },
    due: {
      backgroundColor: "#f7e8b6",
      color: "#162b2e",
    },
    upcoming: {
      backgroundColor: "#e1eced",
      color: "#162b2e",
    },
  },

  info: {
    main: primary[500],
    dark: primary[700],
    light: primary[300],
    contrastText: common.white,
  },

  text: {
    primary: alpha(common.black, 0.87),
    secondary: alpha(common.black, 0.6),
    disabled: alpha(common.black, 0.38),
  },

  background: {
    default: "#f5f7f8",
    paper: common.white,
  },

  // custom palette colors
  custom: {
    hover: alpha(primary[500], 0.04),
    selected: alpha(primary[500], 0.08),
    enabled: alpha(primary[500], 0.5),

    // colors that are not presented in MUI Kit
    labelDefault: "#5a5a5a",
    globalNavigationDivider: "#475467",
    globalNavigationText: alpha(common.white, 0.7),
    cardBorder: "#e8edee",
    inputBorder: alpha(common.black, 0.23),
    fileInputBorder: alpha(lightBlue[700], 0.3),
    rejectButtonHover: alpha(common.black, 0.65),
  },

  // MUI colors (grey color is included by default)
  blue: blue,
  blueGrey: blueGrey,
  lightBlue: lightBlue,
  green: green,
  red: red,
  teal: teal,
  orange: orange,
  amber: amber,
  indigo: indigo,
};

export default palette;
