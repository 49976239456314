import { createContext, useContext, useMemo, useState } from "react";
import { AnnotationsUIs } from "@ckeditor/ckeditor5-comments";

type TAnnotationContextValue = {
  annotationUI: AnnotationsUIs;
  setAnnotationUI: React.Dispatch<React.SetStateAction<any>>;
};

type TAnnotationUIProvider = {
  children: React.ReactNode;
};

export const AnnotationUIContext = createContext<
  TAnnotationContextValue | undefined
>(undefined);

export const useAnnotationUIContext = () => {
  const context = useContext(AnnotationUIContext);

  if (context === undefined) {
    throw new Error(
      "useAnnotationUIContext must be used within an AnnotationUIProvider",
    );
  }

  return context;
};

const AnnotationUIProvider = ({ children }: TAnnotationUIProvider) => {
  const [annotationUI, setAnnotationUI] = useState<any>("");

  const value = useMemo(
    () => ({
      annotationUI,
      setAnnotationUI,
    }),
    [annotationUI, setAnnotationUI],
  );

  return (
    <AnnotationUIContext.Provider value={value}>
      {children}
    </AnnotationUIContext.Provider>
  );
};

export default AnnotationUIProvider;
