import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Editor } from "@ckeditor/ckeditor5-core";
import { TrackChangesAdapter } from "@ckeditor/ckeditor5-track-changes/src/trackchanges";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import addSuggestion from "./methods/addSuggestion";
import getSuggestion from "./methods/getSuggestion";
import updateSuggestion from "./methods/updateSuggestion";
import useAddSuggestion from "./useAddSuggestion";
import useUpdateSuggestion from "./useUpdateSuggestion";

export type TUseSuggestions = (editor: Editor) => TrackChangesAdapter;

export default function useSuggestions(): TUseSuggestions {
  const { suggestions } = services.documents;

  const { mutateAsync: addSuggestionMutation } = useAddSuggestion();
  const { mutateAsync: updateSuggestionMutation } = useUpdateSuggestion();

  const queryClient = useQueryClient();

  const { showAlert } = useAlerts();

  // `useRef` helps us to avoid adding `suggestions` and `showAlert` to dependencies array inside useMemo.
  // Empty dependencies array allows us to create methods that will not be re-created on subsequent renders,
  // but still be valid even `suggestions` or `showAlert` will change
  const suggestionsServiceRef = useRef(suggestions);
  const showAlertRef = useRef(showAlert);

  useEffect(() => {
    suggestionsServiceRef.current = suggestions;
  }, [suggestions]);

  const { t } = useTranslation("annotations");

  useEffect(() => {
    showAlertRef.current = showAlert;
  }, [showAlert]);

  return useCallback(
    (editor: Editor) => ({
      getSuggestion: getSuggestion(
        editor,
        suggestions.getSuggestion,
        showAlertRef,
        t,
        queryClient,
      ),
      addSuggestion: addSuggestion(editor, addSuggestionMutation),
      updateSuggestion: updateSuggestion(editor, updateSuggestionMutation),
    }),
    [
      addSuggestionMutation,
      suggestions.getSuggestion,
      queryClient,
      updateSuggestionMutation,
      t,
    ],
  );
}
