import { TOption } from "components/shared/Select";

export enum EntityStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type TControlledListEntry = {
  id: string;
  code: string;
  name: string;
};

export type TProductEntityControlledListOptions = {
  controlledProductTypes: TOption[];
  controlledStrengthTypes: TOption[];
  controlledStrengthVolumeTypes: TOption[];
  controlledRouteOfAdministrationTypes: TOption[];
  controlledManufacturedDoseFormTypes: TOption[];
};
