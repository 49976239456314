import { DragEvent, KeyboardEvent, useRef } from "react";
import { useCallback, useEffect } from "react";
import {
  FieldPath,
  FieldValue,
  FieldValues,
  get,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText, InputLabel, Typography } from "@mui/material";
import SelectedFile from "components/shared/SelectedFile/SelectedFile";
import {
  ALLOWED_IMPORT_MIME_TYPES,
  ZIP_MIME_TYPE,
} from "utils/constants/doc.constants";
import { Keyboard } from "utils/constants/keyboard.constants";
import { AddDocumentIcon } from "assets/icons";
import styles from "./FormFileInput.styles";

export type TFormFileInputProps<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  isFHIREnabled?: boolean;
  acceptedMimeTypes?: string[];
};

const FormFileInput = <FormType extends FieldValues>({
  name,
  acceptedMimeTypes = ALLOWED_IMPORT_MIME_TYPES,
}: TFormFileInputProps<FormType>) => {
  const { t } = useTranslation(["documents", "common"]);

  const { register, watch, formState, unregister, setValue } =
    useFormContext<FormType>();

  const inputProps = register(name);
  const files = watch(name);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const isZipMimeType =
    acceptedMimeTypes?.includes(ZIP_MIME_TYPE) &&
    acceptedMimeTypes?.length === 1;

  useEffect(() => () => unregister(name), [unregister, name]);

  const removeFile = useCallback(() => {
    setValue(name, null as FieldValue<FormType>, {
      shouldValidate: true,
    });
  }, [setValue, name]);

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();

    if (event.dataTransfer.files) {
      setValue(name, event.dataTransfer.files as FieldValue<FormType>, {
        shouldValidate: true,
      });
    }
  };

  // This means we imitate file input click to fix accessibility for the hidden one
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === Keyboard.Enter) {
      uploadInputRef.current?.click();
    }
  };

  const error = get(formState.errors, name);

  if (files?.[0]) {
    return (
      <Box>
        <SelectedFile
          onClear={removeFile}
          fileName={files[0].name}
          clearIconAriaLabel={t("ariaLabels.clearIcon", {
            ns: "common",
            fileName: files[0].name,
          })}
        />
        {error && (
          <FormHelperText variant="standard" error>
            {error.message}
          </FormHelperText>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        component="div"
        sx={styles.inputWrapper}
        role="button"
        aria-label={t("importDocument.fields.uploadField.selectFile")}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <InputLabel
          sx={styles.container}
          data-has-error={!!error}
          data-testid="select-file-button"
          data-qaid="select-file-button"
          draggable
          onDrop={handleDrop}
          onDragOver={(event: DragEvent<HTMLLabelElement>) =>
            event.preventDefault()
          }
        >
          <AddDocumentIcon />

          <Typography sx={styles.selectFile}>
            {t(
              `importDocument.fields.uploadField.${
                isZipMimeType ? "selectZipFile" : "selectFile"
              }`,
            )}
          </Typography>
          <Typography sx={styles.dragAndDrop}>
            {t("importDocument.fields.uploadField.dragAndDrop")}
          </Typography>

          <input
            {...inputProps}
            hidden
            accept={acceptedMimeTypes.join()}
            type="file"
            ref={(event) => {
              inputProps?.ref(event);
              uploadInputRef.current = event;
            }}
          />
        </InputLabel>
      </Box>
      {error && (
        <FormHelperText variant="standard" aria-live="polite" error>
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormFileInput;
