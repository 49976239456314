import React, { forwardRef, HTMLAttributes, Ref, SyntheticEvent } from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
} from "@mui/material";
import Chip from "components/shared/Chip";
import TextField, { TTextFieldProps } from "components/shared/TextField";
import styles from "./ComboBox.styles";

export type TComboBoxOption = {
  label: string;
  value: string;
  type?: string;
  disabled?: boolean;
};

export type TComboBoxProps = Omit<
  AutocompleteProps<
    TComboBoxOption, // type of values
    true, // Multiple
    false, // DisableClearable
    false // FreeSolo
  >,
  "onChange" | "renderInput"
> & {
  error?: boolean;
  helperText?: string;
  label?: string;
  onChange?: (selectedOptions: TComboBoxOption[]) => void;
  options: TComboBoxOption[];
  required?: boolean;
  textFieldProps?: TTextFieldProps;
  selectAllOption?: boolean;
  deselectAllLabelText?: string;
  selectAllLabelText?: string;
};

const Option = (
  props: HTMLAttributes<HTMLLIElement>,
  option: TComboBoxOption,
  state: AutocompleteRenderOptionState,
) => {
  return (
    <MenuItem {...props}>
      <Checkbox checked={state.selected} />
      <ListItemText>{option.label}</ListItemText>
    </MenuItem>
  );
};

const ComboBox = forwardRef((comboBoxProps: TComboBoxProps, ref: Ref<any>) => {
  const selectedOptions = comboBoxProps.value ?? [];

  // omitting custom `onChange` & `textFieldProps` props so that it doesn't conflict with the existing `onChange` prop
  const {
    error,
    helperText,
    onChange,
    textFieldProps,
    selectAllOption,
    deselectAllLabelText,
    selectAllLabelText,
    ...autocompleteProps
  } = comboBoxProps;

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    value: TComboBoxOption[],
    _reason: AutocompleteChangeReason,
  ) => {
    onChange?.(value);
  };

  const deselectAll = () => {
    onChange?.([]);
  };

  const selectAll = () => {
    onChange?.([...comboBoxProps.options]);
  };

  const ListBoxComponent = forwardRef<
    HTMLUListElement,
    HTMLAttributes<HTMLElement>
  >(({ children, ...rest }, ref) => {
    const optionsCount = comboBoxProps.options.length;
    const selectedCount = selectedOptions.length;
    const allOptionsAreSelected = selectedCount === optionsCount;
    const someOptionsAreSelected = selectedCount > 0;

    const selectAllText = allOptionsAreSelected
      ? deselectAllLabelText
      : selectAllLabelText;

    const selectAllLabel = `${selectAllText} ${comboBoxProps.label} (${selectedCount})`;

    const toggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectAll();
      } else {
        deselectAll();
      }
    };

    return (
      <ul {...rest} ref={ref}>
        {selectAllOption && (
          <li className="MuiAutocomplete-option">
            <div
              role="option"
              tabIndex={-1}
              aria-selected={allOptionsAreSelected}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allOptionsAreSelected}
                    indeterminate={
                      someOptionsAreSelected && !allOptionsAreSelected
                    }
                    onChange={toggleSelectAll}
                  />
                }
                label={selectAllLabel}
              />
            </div>
          </li>
        )}
        {children}
      </ul>
    );
  });

  return (
    <Autocomplete
      sx={styles.autocomplete}
      disableCloseOnSelect
      data-testid="autocomplete"
      data-qaid="autocomplete"
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      ListboxComponent={ListBoxComponent}
      renderOption={Option}
      multiple
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          error={error}
          helperText={helperText}
          ref={ref}
          label={comboBoxProps.label}
          required={autocompleteProps.required}
          {...params}
          {...textFieldProps}
        />
      )}
      renderTags={(value: readonly TComboBoxOption[], getTagProps) =>
        value
          .filter((opt) => !opt?.disabled)
          .map((option: TComboBoxOption, index: number) => (
            <Chip
              variant="outlined"
              color="default"
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
      }
      value={selectedOptions}
      {...autocompleteProps}
    />
  );
});

export default ComboBox;
