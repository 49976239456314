import { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { TSelectedHAQTableRow } from "components/shared/types";
import useFeatureFlags from "context/FeatureFlagsContext/useFeatureFlags";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { useHAQContext } from "screens/Project/screens/HAQ/HAQContext/HAQContextProvider";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import {
  HAQAction,
  HAQBulkActionType,
  HAQStatus,
} from "utils/constants/HAQ.constants";
import {
  getIsAssetCreatedByUser,
  getIsReferenceOrExternalHAQ,
} from "utils/helpers";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import {
  getPossibleStatusChangeMenuOptionsForHA,
  getPossibleStatusChangeMenuOptionsForSponsor,
} from "./useChangeStatusAction.utils";
import { useHandleActionClickV1 } from "./useHandleActionClickV1";
import { useHandleActionClickV2 } from "./useHandleActionClickV2";

type TUseChangeStatusAction = {
  selectedRows: TSelectedHAQTableRow[];
  isAnyHAQCopied: boolean;
  shouldEnableTrackingEvent?: boolean;
};

export const useChangeStatusAction = ({
  selectedRows,
  isAnyHAQCopied,
  shouldEnableTrackingEvent,
}: TUseChangeStatusAction) => {
  const { user, organization } = useUser();
  const { project } = useProject();
  const { permissions } = usePermissions(project?.id ?? "");
  const { isProjectManager } = useUserRoles(project?.id);
  const { t } = useTranslation(["projects", "HAQ", "asset", "common"]);
  const featureFlags = useFeatureFlags();

  const { isAnyWorkflowInProgress, setIsAnyWorkflowInProgress } =
    useHAQContext();

  const shouldUseHAQActionsV2 =
    featureFlags["FTE-13049_enableHAQActionsV2"] === FeatureFlag.On;

  const [changeStatusModal, setChangeStatusModal] = useState(<></>);

  const handleActionClickV1 = useHandleActionClickV1(
    selectedRows,
    setChangeStatusModal,
    setIsAnyWorkflowInProgress,
    shouldEnableTrackingEvent,
  );

  const handleActionClickV2 = useHandleActionClickV2(
    selectedRows,
    setChangeStatusModal,
    setIsAnyWorkflowInProgress,
    shouldEnableTrackingEvent,
  );

  const possibleStatusChangeMenuOptions = isSponsorUser(organization)
    ? getPossibleStatusChangeMenuOptionsForSponsor({
        t,
        organization,
        isProjectManager,
        handleClick: handleActionClickV1,
      })
    : getPossibleStatusChangeMenuOptionsForHA({
        selectedRows,
        t,
        organization,
        isProjectManager,
        handleClick: (status: HAQStatus) =>
          handleActionClickV1({
            actionType: HAQBulkActionType.QuestionOnly,
            status,
          }),
        isAnyHAQCopied,
      });

  const isAnyHAQReferenceOrExternal = Boolean(
    selectedRows.find((row) =>
      getIsReferenceOrExternalHAQ(row.HAQ.metadata?.importType),
    ),
  );

  const hasSponsorChangeStatusPermissions = isAnyHAQReferenceOrExternal
    ? permissions.canChangeReferenceOrExternalQuestionStatus
    : permissions.canChangeQuestionResponseStatus;

  const isAllHAQOwnedByUser = selectedRows.every((row) =>
    getIsAssetCreatedByUser(row.HAQ, user),
  );

  const hasHAChangeStatusPermission = !isAllHAQOwnedByUser
    ? permissions.canChangeQuestionStatus
    : permissions.canChangeQuestionOwnedStatus;

  const hasChangeStatusPermissions = isHAUser(organization)
    ? hasHAChangeStatusPermission
    : hasSponsorChangeStatusPermissions;

  const isShown =
    !isEmpty(selectedRows) &&
    project?.active &&
    hasChangeStatusPermissions &&
    ((shouldUseHAQActionsV2 && isSponsorUser(organization)) ||
      !isEmpty(possibleStatusChangeMenuOptions)) &&
    (isSponsorUser(organization) || !isAnyHAQCopied);

  return {
    changeStatusAction: isShown && {
      id: HAQAction.ChangeStatus,
      label: t("HAQs.actionOptionsChangeStatusLabel"),
      ...(shouldUseHAQActionsV2 && isSponsorUser(organization)
        ? { onClick: handleActionClickV2 }
        : { items: possibleStatusChangeMenuOptions }),
      disabled: isAnyWorkflowInProgress,
    },
    changeStatusModal,
  };
};
