import { alpha } from "@mui/material";
import { getPaletteColor } from "theme/helpers";
import { TStyles } from "utils/Styles";

const styles: TStyles = {
  tabs: {
    border: "none",

    "& .MuiTabs-flexContainer": {
      gap: 0,
    },
  },

  tab: {
    fontFamily: (theme) => theme.typography.fontFamilyReadexPro,

    "&.MuiButtonBase-root.Mui-selected": {
      backgroundColor: getPaletteColor("custom.hover"),
    },
  },

  divider: {
    borderColor: (theme) => alpha(theme.palette.divider, 0.06),
  },
};

export default styles;
