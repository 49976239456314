import { TEnvironmentalVariables, TEnvironmentType } from "./models/env.models";
import { MILLISECONDS_IN_MINUTE } from "./utils/constants/time.constants";

const VARIABLE_INDICATION_PREFIX = "${";

const requiredVars = new Set<Partial<keyof TEnvironmentalVariables>>([
  "tenant",
  "apiUrl",
  "oktaDomain",
  "oktaClientId",
  "CKEditorKey",
]);

const getEnvVar = (
  key: keyof TEnvironmentalVariables,
  fallback?: string,
): string => {
  if (!window.ENV) {
    throw new Error("Environmental variables config was not provided.");
  }

  if (
    !window.ENV[key] ||
    window.ENV[key].startsWith(VARIABLE_INDICATION_PREFIX)
  ) {
    const variableName = key.replace(VARIABLE_INDICATION_PREFIX, "");

    if (fallback) {
      if (requiredVars.has(key)) {
        console.warn(
          `${variableName} was not provided! "${fallback}" value is used.`,
        );
      }

      return fallback;
    } else {
      if (requiredVars.has(key)) {
        console.warn(
          `${variableName} was not provided as well as a fallback value!`,
        );
      }

      return "";
    }
  } else {
    return window.ENV[key];
  }
};

export default function getEnv(): TEnvironmentalVariables {
  const apiUrl = getEnvVar("apiUrl");
  const graphQLServicePath = getEnvVar("graphQLServicePath", "/graphql/");

  return {
    appTitle: getEnvVar("appTitle", "Accumulus"),
    appInsightsConnectionString: getEnvVar("appInsightsConnectionString"),
    mockApiKey: getEnvVar("mockApiKey"),
    mockApiUrl: getEnvVar("mockApiUrl"),
    graphQLUrl: getEnvVar("graphQLUrl", `${apiUrl}${graphQLServicePath}`),
    graphQLServicePath,
    apiUrl,
    assetServicePath: getEnvVar("assetServicePath", "/asset"),
    dataServicePath: getEnvVar("dataServicePath", "/data"),
    trackingServicePath: getEnvVar("trackingServicePath", "/audit"),
    identityServicePath: getEnvVar("identityServicePath", "/identity"),
    notificationsServicePath: getEnvVar(
      "notificationsServicePath",
      "/notification",
    ),
    workflowServicePath: getEnvVar("workflowServicePath", "/workflow"),
    oktaClientId: getEnvVar("oktaClientId"),
    oktaDomain: getEnvVar("oktaDomain"),
    issuerURI: getEnvVar("issuerURI"),
    CKEditorKey: getEnvVar("CKEditorKey"),
    tenant: getEnvVar("tenant"),
    platformTenantType: getEnvVar("platformTenantType"),
    environmentType: getEnvVar(
      "environmentType",
      "PREPROD",
    ) as TEnvironmentType,
    idleTimeout: getEnvVar(
      "idleTimeout",
      (MILLISECONDS_IN_MINUTE * 15).toString(),
    ),
    externalIdpEnabled: getEnvVar("externalIdpEnabled", "false"),
  };
}
