import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Typography } from "@mui/material";
import { Button } from "components/shared";
import useUserRoles from "hooks/user/useUserRoles";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import styles from "screens/Project/sections/Documents/DocumentsTeasers/DocumentsTeasers.styles";
import { ImportModalType } from "screens/Project/sections/Documents/modals/ImportDocument";
import { findCompletedDocument } from "screens/Project/sections/Documents/utils/documentHelper";
import getDocumentIcons from "screens/Project/sections/Documents/utils/getDocumentIcons";
import { getPaletteColor } from "theme/helpers";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import AddDocumentTeaser from "../AddDocumentTeaser";
import TeaserCard from "../TeaserCard";
import TeaserImgBox from "../TeaserImgBox";
import AAIDDocImg from "assets/images/aaid.png";

type TAAIDTeaserProps = {
  onAddDocument: (type: ImportModalType | null) => void;
};

const AAIDTeaser = ({ onAddDocument }: TAAIDTeaserProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { projectId } = useParams();

  const { requiredDocuments } = useDocumentsContext();
  const { isProjectManager } = useUserRoles(projectId);

  const document = useMemo(
    () =>
      findCompletedDocument(
        requiredDocuments.data,
        DocumentSubtype.AssessmentAid,
      ),
    [requiredDocuments.data],
  );

  // TODO: workflowStatus for document is deprecated
  const descriptionText = document?.workflowStatus?.toLocaleLowerCase();

  const isLoading = requiredDocuments.status === QueryFetchingStatus.Loading;

  // TODO: workflowStatus for document is deprecated
  const isAAIDSubmitted =
    !isLoading && document?.workflowStatus === "SUBMITTED";

  if (isLoading) {
    return (
      <TeaserCard
        title={t("documents.teaser.aaidTitle")}
        imgBox={<TeaserImgBox isLoading={true} img={AAIDDocImg} />}
        description={
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {t("teaserCard.loading")}
          </Typography>
        }
      />
    );
  }

  if (document) {
    return (
      <TeaserCard
        title={t("documents.teaser.aaidTitle")}
        imgBox={<TeaserImgBox isLoading={false} img={AAIDDocImg} />}
        description={
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {descriptionText}
          </Typography>
        }
      >
        {isAAIDSubmitted && (
          <CheckCircleRoundedIcon
            sx={styles.successIcon}
            aria-label={t("teaserCard.completeStatusAriaLabel")}
          />
        )}
      </TeaserCard>
    );
  }

  return (
    <AddDocumentTeaser
      data-qaid="add-aaid-teaser"
      icon={getDocumentIcons(t, "info").assessment}
      title={t("documents.teaser.aaidTitle")}
      description={
        <Typography variant="caption" sx={styles.textCenter}>
          {t("documents.teaser.aaidDescription")}
        </Typography>
      }
    >
      {isProjectManager && (
        <Button
          variant="outlined"
          onClick={() => onAddDocument(ImportModalType.AAID)}
        >
          {t("button.add", { ns: "common" })}
        </Button>
      )}
    </AddDocumentTeaser>
  );
};

export default AAIDTeaser;
