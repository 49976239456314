import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import { IAssetVersion } from "models/assets.models";
import { IDocument } from "models/documents.models";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { EMPTY_LABEL } from "utils/helpers";
import { getIsDocumentStructuredAAID } from "./documentStatuses";

export const getLastVersion = (versions: IAssetVersion[] | undefined) => {
  if (!versions || isEmpty(versions)) {
    return EMPTY_LABEL;
  }

  return last(
    versions.sort((prev, next) =>
      dayjs(prev.createdDate).diff(next.createdDate),
    ),
  )?.version;
};

export const getDocumentsByIds = (
  documentIds: Set<string>,
  documents?: IDocument[],
) =>
  documentIds?.size && documents?.length
    ? documents?.filter((doc) => documentIds.has(doc.id))
    : [];

export const findCompletedDocument = (
  documents: IDocument[] | undefined,
  subtypeName: DocumentSubtype,
) => {
  return documents?.find((document) => {
    switch (subtypeName) {
      case DocumentSubtype.AssessmentAid:
        return getIsDocumentStructuredAAID(document);

      default:
        return document.type === subtypeName;
    }
  });
};
