import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import Accumulus from "components/Accumulus";
import { Guard, SecureRoute } from "components/Auth";
import * as accessChecks from "components/Auth/accessChecks";
import Redirect from "components/common/Redirect";
import AuthLayout from "components/Layouts/AuthLayout";
import MainLayout from "components/Layouts/MainLayout";
import { FeatureFlagsProvider } from "context/FeatureFlagsContext";
import ProjectProvider from "context/ProjectContext/ProjectContext";
import { UserProvider } from "context/UserContext";
import { Substances } from "screens/EntityManagement/components";
import Organization from "screens/EntityManagement/components/Organization";
import ProductFamily from "screens/EntityManagement/components/ProductFamily";
import Products from "screens/EntityManagement/components/Products";
import FeatureFlags from "screens/FeatureFlags/FeatureFlags";
import TherapeuticSummary from "screens/Portfolio/TherapeuticSummary";
import Profile from "screens/Profile/Profile";
import FHIRResource from "screens/Project/screens/FHIRResource";
import GSP from "screens/Project/screens/GSP";
import { Path, RelativePath } from "./paths";

const UserManagement = lazy(
  () => import("screens/Administration/components/UserManagement"),
);

const AdminReset = lazy(
  () => import("screens/Administration/components/AdminReset"),
);

const EntityManagement = lazy(() => import("screens/EntityManagement"));
const Homepage = lazy(() => import("screens/Homepage"));
const AddUser = lazy(() => import("screens/Administration/components/AddUser"));

const Document = lazy(
  () => import("screens/Project/sections/Documents/Document/Document"),
);

const ProjectHAQ = lazy(() => import("screens/Project/screens/HAQ"));

const Login = lazy(() => import("screens/Login"));
const PageNotFound = lazy(() => import("screens/PageNotFound"));
const Projects = lazy(() => import("screens/Projects"));
const Project = lazy(() => import("screens/Project"));
const Tasks = lazy(() => import("screens/Tasks"));
const Portfolio = lazy(() => import("screens/Portfolio"));
const CountryProduct = lazy(() => import("screens/Portfolio/CountryProduct"));
const HealthCheck = lazy(() => import("screens/HealthCheck"));

const HAQAcceptancePreview = lazy(
  () => import("screens/Tasks/HAQAcceptanceTaskPreview"),
);

const LegalAcknowledgementsConsent = lazy(
  () => import("screens/LegalAcknowledgements/Consent"),
);

const LegalAcknowledgementDecline = lazy(
  () => import("screens/LegalAcknowledgements/Decline"),
);

const User = lazy(() => import("screens/Administration/components/User"));

const UserProfile = lazy(
  () => import("screens/Administration/components/User/Profile"),
);

const UserProjectsList = lazy(
  () => import("screens/Administration/components/User/ProjectsList"),
);

const AuditTrail = lazy(() => import("screens/AuditTrail"));

const createRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Accumulus />}>
        <Route element={<AuthLayout />}>
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.LoginCallback} element={<LoginCallback />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<SecureRoute />}>
          <Route element={<FeatureFlagsProvider />}>
            <Route element={<UserProvider />}>
              <Route element={<AuthLayout />}>
                <Route
                  element={
                    <Guard
                      check={accessChecks.hasLegalAcknowledgments}
                      onDenied={() => <Navigate to={Path.HomePage} replace />}
                    />
                  }
                >
                  <Route
                    path={Path.LegalAcknowledgementsConsent}
                    element={<LegalAcknowledgementsConsent />}
                  />
                  <Route
                    path={Path.LegalAcknowledgementDecline}
                    element={<LegalAcknowledgementDecline />}
                  />
                </Route>
              </Route>
              <Route
                element={
                  <Guard
                    check={accessChecks.canUseApp}
                    onDenied={() => (
                      <Navigate
                        to={Path.LegalAcknowledgementsConsent}
                        replace
                      />
                    )}
                  />
                }
              >
                <Route path="/" element={<MainLayout />}>
                  <Route
                    element={
                      <Guard
                        check={accessChecks.canAccessRegularApp}
                        onDenied={() => <Redirect path={Path.Admin} />}
                      />
                    }
                  >
                    <Route index element={<Homepage />} />
                    <Route path={Path.Projects} element={<Projects />} />

                    <Route element={<ProjectProvider />}>
                      <Route
                        path={RelativePath.Project}
                        element={<Project />}
                      />
                      <Route path={Path.ProjectHAQ} element={<ProjectHAQ />} />
                      <Route path={Path.ProjectGSP} element={<GSP />} />
                      <Route
                        path={Path.ProjectDocument}
                        element={<Document />}
                      />
                      <Route
                        element={
                          <Guard
                            check={accessChecks.getIsFeatureOn(
                              "enableFHIRDocuments",
                            )}
                          />
                        }
                      >
                        <Route
                          path={RelativePath.ProjectFHIRResource}
                          element={<FHIRResource />}
                        />
                      </Route>

                      {/* ToDo (AV): This might be a temporary route to display HAQ preview. TBD in future */}
                      <Route
                        path={Path.HAQAcceptanceTaskPreview}
                        element={<HAQAcceptancePreview />}
                      />
                    </Route>

                    <Route
                      element={<Guard check={accessChecks.shouldShowTasks} />}
                    >
                      <Route path={Path.Tasks} element={<Tasks />} />
                      <Route path={Path.Task} element={<Tasks />} />
                    </Route>
                    <Route
                      element={
                        <Guard
                          check={accessChecks.getIsFeatureOn(
                            "enableFHIRDocuments",
                          )}
                        />
                      }
                    >
                      <Route path={Path.Portfolio} element={<Portfolio />} />
                      <Route
                        path={Path.TherapeuticSummary}
                        element={<TherapeuticSummary />}
                      />
                      <Route
                        path={Path.CountryProduct}
                        element={<CountryProduct />}
                      />
                    </Route>
                  </Route>

                  <Route path={Path.Admin}>
                    <Route
                      element={
                        <Guard check={accessChecks.canManageFeatureFlags} />
                      }
                    >
                      <Route
                        path={Path.FeatureFlags}
                        element={<FeatureFlags />}
                      />
                    </Route>
                    <Route path={Path.HealthCheck} element={<HealthCheck />} />

                    <Route element={<Guard check={accessChecks.isAdmin} />}>
                      <Route
                        element={<Guard check={accessChecks.canManageUsers} />}
                      >
                        <Route
                          index
                          element={<Redirect path={Path.AdminUserManagement} />}
                        />

                        <Route
                          path={Path.AdminUserManagement}
                          element={<UserManagement />}
                        />

                        <Route
                          path={Path.AdminAddNewUser}
                          element={<AddUser />}
                        />

                        <Route path={Path.AdminUser} element={<User />}>
                          <Route
                            index
                            element={<Redirect path={Path.AdminUserProfile} />}
                          />
                          <Route
                            path={Path.AdminUserProfile}
                            element={<UserProfile />}
                          />
                          <Route
                            path={Path.AdminUserProjects}
                            element={<UserProjectsList />}
                          />
                        </Route>

                        <Route
                          element={
                            <Guard
                              check={accessChecks.canResetEnvironmentData}
                            />
                          }
                        >
                          <Route
                            path={Path.AdminReset}
                            element={<AdminReset />}
                          />
                        </Route>
                      </Route>

                      <Route
                        element={
                          <Guard check={accessChecks.canManageEntities} />
                        }
                      >
                        <Route
                          element={
                            <Guard
                              check={accessChecks.getIsFeatureOn(
                                "FTE-21267_enableEntityManagement",
                              )}
                            />
                          }
                        >
                          <Route
                            path={Path.AdminEntityManagement}
                            element={<EntityManagement />}
                          >
                            <Route
                              index
                              element={
                                <Redirect
                                  path={Path.AdminEntityManagementOrganization}
                                />
                              }
                            />
                            <Route
                              path={Path.AdminEntityManagementOrganization}
                              element={<Organization />}
                            />
                            <Route
                              path={Path.AdminEntityManagementSubstances}
                              element={<Substances />}
                            />
                            <Route
                              path={Path.AdminEntityManagementProducts}
                              element={<Products />}
                            />
                            <Route
                              path={Path.AdminEntityManagementProductFamily}
                              element={<ProductFamily />}
                            />
                          </Route>
                        </Route>
                      </Route>

                      <Route
                        element={
                          <Guard check={accessChecks.canManageAuditTrail} />
                        }
                      >
                        <Route
                          index
                          element={<Redirect path={Path.AdminAuditTrail} />}
                        />
                        <Route
                          path={Path.AdminAuditTrail}
                          element={<AuditTrail />}
                        />
                      </Route>
                    </Route>
                  </Route>

                  <Route path={Path.Profile} element={<Profile />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );

export default createRouter;

export { Path, RelativePath } from "./paths";
