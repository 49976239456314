import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useStartWorkflow from "hooks/api/REST/workflow/useStartWorkflow";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { getCanDocumentBeShared } from "screens/Project/sections/Documents/utils/documentStatuses";
import { QueryAPIKey } from "utils/constants/api.constants";
import { ISO_DATE_FORMAT } from "utils/constants/format.constants";
import { WorkflowId } from "utils/constants/workflows.constants";
import { formatDate } from "utils/helpers";
import { mapOrganizationsToSelectOptions } from "utils/helpers/mapOrganizationsToSelectOptions";
import { isSponsorUser } from "utils/user/organization";
import AddDocuments, { useAddDocuments } from "../fields/AddDocuments";
import FieldsLayout from "./components/FieldsLayout";
import SecurityNotice from "./components/SecurityNotice";
import {
  ShareDocumentFieldName,
  TShareDocumentForm,
} from "./ShareDocument.types";
import {
  getShareDocumentSchema,
  getShareDocumentsDefaultValues,
} from "./ShareDocument.utils";

export type TShareDocumentsModalProps = {
  documents: IDocument[];
  onClose: () => void;
  projectId: string | undefined;
};

const ShareDocument = ({
  documents,
  onClose,
  projectId,
}: TShareDocumentsModalProps) => {
  const [submissionData, setSubmissionData] =
    useState<TShareDocumentForm | null>(null);

  const { participatingHAOrganizationsExceptUserOrganization } = useProject();

  const userRoles = useUserRoles(projectId);

  const { organization, user } = useUser();
  const queryClient = useQueryClient();

  const { t } = useTranslation([
    "documents",
    "common",
    "user",
    "notifications",
  ]);

  const recipientsOptions = useMemo(
    () =>
      mapOrganizationsToSelectOptions(
        participatingHAOrganizationsExceptUserOrganization,
      ),
    [participatingHAOrganizationsExceptUserOrganization],
  );

  const methods = useForm<TShareDocumentForm>({
    mode: "all",
    defaultValues: getShareDocumentsDefaultValues(documents),
    resolver: zodResolver(
      getShareDocumentSchema(t, isSponsorUser(organization)),
    ),
  });

  const { formState, handleSubmit, setValue, clearErrors, watch } = methods;

  const selectedDocuments = watch(
    ShareDocumentFieldName.Documents,
  ) as IDocument[];

  const { isAddState, setIsAddState, docsToAdd, ...addDocumentsProps } =
    useAddDocuments({
      projectId,
      validateDocument: (doc: IDocument) =>
        getCanDocumentBeShared(doc, organization, userRoles),
      selectedDocuments,
    });

  const {
    startWorkflow,
    isSuccess: isSharingSuccess,
    isLoading: isSharingLoading,
  } = useStartWorkflow({
    successMessage: t("workflowMessages.shareDocumentSuccessMessage", {
      ns: "notifications",
    }),
    failureMessage: t("workflowMessages.shareDocumentErrorMessage", {
      ns: "notifications",
    }),
  });

  useEffect(() => {
    if (isSharingSuccess) {
      queryClient.invalidateQueries(QueryAPIKey.GetDocument);
      onClose();
    }
  }, [isSharingSuccess, onClose, queryClient]);

  const updateDocumentsValue = useCallback(() => {
    setValue(ShareDocumentFieldName.Documents, [
      ...selectedDocuments,
      ...docsToAdd,
    ]);
    clearErrors(ShareDocumentFieldName.Documents);
  }, [docsToAdd, selectedDocuments, setValue, clearErrors]);

  const addStateButtons = [
    <Button
      key="cancel"
      data-qaid="add-document-cancel-button"
      variant="text"
      onClick={() => setIsAddState(false)}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>,
    <Button
      color="primary"
      key="submit"
      data-qaid="add-document-submit-button"
      variant="contained"
      onClick={() => {
        updateDocumentsValue();
        setIsAddState(false);
      }}
      disabled={!Boolean(docsToAdd.length)}
    >
      {t("documents.submitDocuments.modalAddDocumentsSubmit")}
    </Button>,
  ];

  const defaultButtons = [
    <Button
      data-qaid="share-document-cancel-button"
      key="cancel"
      variant="text"
      onClick={onClose}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>,
    <Button
      color="primary"
      key="submit"
      data-qaid="share-document-submit-button"
      variant="contained"
      type="submit"
      disabled={!formState.isValid}
    >
      {t("button.send", { ns: "common" })}
    </Button>,
  ];

  const initiateShare = useCallback(() => {
    if (submissionData) {
      startWorkflow({
        id: WorkflowId.InitiateShare,
        payload: {
          targetTenants: submissionData.recipients.map(
            (recipient) => recipient.value,
          ),
          assets: documents.map((document) => document.id),
          dueDate: submissionData.dueDate
            ? formatDate(submissionData.dueDate, ISO_DATE_FORMAT)
            : undefined,
          message: submissionData?.message,
          originator: user?.id || "",
        },
      });
    }
  }, [submissionData, documents, startWorkflow, user?.id]);

  return (
    <>
      <Modal
        open
        data-qaid="share-document-modal"
        title={t("documents.shareDocument.modalTitle")}
      >
        <FormWrapper
          methods={methods}
          onSubmit={handleSubmit(setSubmissionData)}
        >
          <Stack gap={2} justifyContent="flex-start">
            {isAddState ? (
              <AddDocuments {...addDocumentsProps} />
            ) : (
              <FieldsLayout
                onAddClick={() => setIsAddState(true)}
                recipientsOptions={recipientsOptions || []}
              />
            )}
          </Stack>
          <ModalActionButtons
            buttons={isAddState ? addStateButtons : defaultButtons}
          />
        </FormWrapper>
      </Modal>
      <SecurityNotice
        isOpen={Boolean(submissionData)}
        onSubmit={initiateShare}
        onCancel={onClose}
        isSubmitting={isSharingLoading}
      />
    </>
  );
};

export default ShareDocument;
