import { AxiosResponse } from "axios";
import { get, post, routes } from "api";
import { ICreateTaskRequestData } from "models/tasks.models";
import {
  IEventRequestData,
  IStartWorkflowRequestData,
  IWorkflowExecution,
} from "models/workflow.models";
import { WorkflowId } from "utils/constants/workflows.constants";

const workflows = {
  startWorkflow(
    workflowId: WorkflowId,
    data: IStartWorkflowRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return post(routes.workflows.workflows.start(workflowId), {
      data,
      isMockApiOn,
    });
  },

  getWorkflowExecution(workflowId: string, isMockApiOn?: boolean) {
    return get<IWorkflowExecution>(
      routes.workflows.workflows.getWorkflowExecution(workflowId),
      {
        isMockApiOn,
      },
    );
  },

  createProjectTask(
    data: ICreateTaskRequestData,
    isMockApiOn?: boolean,
  ): Promise<AxiosResponse> {
    return post(routes.workflows.workflows.createProjectTask(), {
      data,
      isMockApiOn,
    });
  },

  createWorkflowEvent(
    workflowId: string,
    data: IEventRequestData,
    isMockApiOn?: boolean,
  ) {
    return post(routes.workflows.workflows.events(workflowId), {
      data,
      isMockApiOn,
    });
  },

  cancelWorkflowTasks(workflowExecutionId: string): Promise<AxiosResponse> {
    return post(
      routes.workflows.workflows.cancelWorkflowTasks(workflowExecutionId),
    );
  },
};

export default workflows;
