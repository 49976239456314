import { createContext, useMemo } from "react";
import { Outlet } from "react-router-dom";
import useGetFeatureFlags from "hooks/api/GQL/featureFlags/useGetFeatureFlags";
import { TFeatureFlagsMap } from "models/featureFlags.models";

export type TFeatureFlagsValue = TFeatureFlagsMap;

export const FeatureFlagsContext = createContext<
  TFeatureFlagsValue | undefined
>(undefined);

export const FeatureFlagsProvider = () => {
  const { featureFlags, isSuccess } = useGetFeatureFlags();

  const mappedFeatureFlags = useMemo(() => {
    return (
      featureFlags?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.name]: curr.value,
        }),
        {},
      ) || {}
    );
  }, [featureFlags]);

  const contextValue = useMemo(() => mappedFeatureFlags, [mappedFeatureFlags]);

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {isSuccess ? <Outlet /> : null}
    </FeatureFlagsContext.Provider>
  );
};
