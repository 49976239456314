import { useState } from "react";
import { useTranslation } from "react-i18next";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ProfileCard from "screens/Profile/ProfileCard";
import UpdateTimeZoneModal from "screens/Profile/UpdateTimeZoneModal";

type TSettingsProps = {
  timezone?: string;
};

const DEFAULT_TIMEZONE = "Europe/London";

const Settings = ({ timezone = DEFAULT_TIMEZONE }: TSettingsProps) => {
  const [shouldShowUpdateTimezoneModal, setShouldShowUpdateTimezoneModal] =
    useState(false);

  const { t } = useTranslation("user", {
    keyPrefix: "profileSettings",
  });

  const fields = [
    {
      id: "0",
      label: t("fields.timezone"),
      value: timezone,
      onEdit: () => setShouldShowUpdateTimezoneModal(true),
    },
  ];

  const closeModal = () => setShouldShowUpdateTimezoneModal(false);

  const handleSave = () => {
    closeModal();
  };

  return (
    <>
      <ProfileCard
        avatar={<SettingsOutlinedIcon />}
        title={t("profileCardTitle")}
        fields={fields}
      />
      <UpdateTimeZoneModal
        currentTimeZone={timezone}
        open={shouldShowUpdateTimezoneModal}
        onClose={closeModal}
        onSave={handleSave}
      />
    </>
  );
};

export default Settings;
