import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import Button from "components/shared/Button";
import DetailsCard from "components/shared/DetailsCard";
import styles from "./ProfileCard.styles";

type TProfileCardField = {
  id: string;
  label: string;
  onEdit?: () => void;
  value: string | ReactNode;
};

type TProfileCardProps = {
  avatar: ReactNode;
  fields: TProfileCardField[];
  title: string;
};

const ProfileCard = ({ avatar, fields, title }: TProfileCardProps) => {
  const { t } = useTranslation("common");

  return (
    <DetailsCard sx={styles.card} avatar={avatar} title={title}>
      <Stack
        direction="column"
        spacing={1}
        data-qaid="profile-card-fields-block"
      >
        {fields.map((field) => (
          <Stack
            direction="row"
            key={field.id}
            spacing={3}
            sx={styles.row}
            data-qaid="profile-card-row-line"
          >
            <Typography
              sx={styles.label}
              variant="body2"
              data-qaid="profile-card-row-name"
            >
              {field.label}
            </Typography>
            {typeof field.value === "string" ? (
              <Typography variant="body2" data-qaid="profile-card-row-value">
                {field.value}
              </Typography>
            ) : (
              field.value
            )}
            {field.onEdit ? (
              <Button
                data-qaid="edit-profile-button"
                onClick={field.onEdit}
                sx={styles.editButton}
                size="large"
              >
                {t("button.edit")}
              </Button>
            ) : null}
          </Stack>
        ))}
      </Stack>
    </DetailsCard>
  );
};

export default ProfileCard;
