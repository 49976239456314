import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import { EntityStatus } from "models/entities.models";
import { TEntityProduct, TProductVariant } from "../Products.types";
import ProductVariantFormFields from "../ProductVariantFormFields";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "../ProductVariantFormFields/ProductVariantFormFields.types";
import {
  createProductVariantFormSchema,
  createProductVariantFormValues,
} from "../ProductVariantFormFields/ProductVariantFormFields.utils";

export type TManageProductVariantModalProps = {
  isOpen: boolean;
  product?: TEntityProduct;
  variantToEdit?: TProductVariant;
  onClose: () => void;
};

const ManageProductVariantModal = ({
  isOpen,
  product,
  variantToEdit,
  onClose,
}: TManageProductVariantModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const isEditMode = Boolean(variantToEdit);

  const modalTitle = t(
    isEditMode
      ? "manageProductVariantModal.editProductVariantTitle"
      : "manageProductVariantModal.createNewProductVariantTitle",
    {
      ns: "administration",
      productName: product?.tradeName,
    },
  );

  const defaultValues = useMemo(
    () => createProductVariantFormValues(variantToEdit),
    [variantToEdit],
  );

  const methods = useForm<TProductVariantForm>({
    mode: "all",
    defaultValues: defaultValues,
    resolver: zodResolver(createProductVariantFormSchema(t)),
  });

  const { formState, handleSubmit, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = (values: TProductVariantForm) => {
    // TODO (AR) will be implemented within the framework of the task FTE-26091
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const variantToSave = {
      type: values[ProductVariantFieldName.ProductType],
      routeOfAdministration:
        values[ProductVariantFieldName.RouteOfAdministration],
      manufacturedDoseForm:
        values[ProductVariantFieldName.ManufacturedDoseForm],
      strengthValue: values[ProductVariantFieldName.StrengthValue],
      strengthType: values[ProductVariantFieldName.StrengthType],
      strengthVolumeValue: values[ProductVariantFieldName.StrengthVolumeValue],
      strengthVolumeType: values[ProductVariantFieldName.StrengthVolumeType],
      active: values[ProductVariantFieldName.Status] === EntityStatus.Active,
    };
  };

  const onCloseManageSubtance = () => {
    reset();
    onClose();
  };

  return (
    <Modal maxWidth="md" open={isOpen} title={modalTitle}>
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ProductVariantFormFields />

        <ModalActionButtons>
          <Button
            data-qaid="manage-product-variant-cancel-button"
            key="cancel"
            variant="text"
            onClick={onCloseManageSubtance}
          >
            {t("button.cancel", { ns: "common" })}
          </Button>
          <Button
            key="submit-button"
            data-qaid="manage-product-variant-submit-button"
            variant="contained"
            disabled={!formState.isValid}
            type="submit"
          >
            {t("button.submit", { ns: "common" })}
          </Button>
        </ModalActionButtons>
      </FormWrapper>
    </Modal>
  );
};

export default ManageProductVariantModal;
