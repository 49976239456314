import { TFunction } from "i18next";
import {
  DATE_TIME_INDICATOR,
  DUE_THRESHOLD,
  END_OF_DAY_TIME,
  HOURS_IN_DAY,
  MILLISECONDS_IN_HOUR,
  MINUTES_IN_HOUR,
} from "utils/constants/time.constants";
import { formatDate } from "utils/helpers";
import { TISODateFormat } from "utils/types";

export const isDue = (hours: number) => hours <= DUE_THRESHOLD && hours >= 0;
export const isUpcoming = (hours: number) => hours > DUE_THRESHOLD;
export const isOverdue = (hours: number) => hours < 0;

const getOffset = (timeZone = "GMT", date = new Date()) => {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "GMT" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));

  return (tzDate.getTime() - utcDate.getTime()) / MILLISECONDS_IN_HOUR;
};

const getThisMachineOffset = () =>
  new Date().getTimezoneOffset() / MINUTES_IN_HOUR;

const applyTimezone = (date: Date, timezone = "GMT") => {
  const thisMachineOffset = getThisMachineOffset();
  const userOffset = timezone ? getOffset(timezone) : getThisMachineOffset();

  return new Date(
    date.setHours(date.getHours() + thisMachineOffset + userOffset),
  );
};

const getDeltaHours = (startDate: Date, endDate: Date) =>
  Math.round((endDate.getTime() - startDate.getTime()) / MILLISECONDS_IN_HOUR);

export const getWorkingTimeDifference = (
  dueDateString: TISODateFormat | string,
  timezone?: string,
) => {
  let nowDate = new Date(new Date().toISOString());

  const [dateString, timeString] = dueDateString.split(DATE_TIME_INDICATOR);

  let dueDate = new Date(
    new Date(
      `${dateString}${DATE_TIME_INDICATOR}${timeString ?? END_OF_DAY_TIME}`,
    ).toISOString(),
  );

  nowDate = applyTimezone(nowDate, timezone);
  dueDate = applyTimezone(dueDate);

  return getDeltaHours(nowDate, dueDate);
};

export const getTimeString = (dueTime: number, currentLanguage: string) => {
  const rtf = new Intl.RelativeTimeFormat(currentLanguage, {
    numeric: "always",
    style: "long",
  });

  const days = Math.trunc(dueTime / HOURS_IN_DAY);
  const hours = dueTime % HOURS_IN_DAY;

  if (Math.abs(days) > 0) {
    return rtf.format(Math.round(days), "day");
  } else if (Math.abs(hours) >= 0) {
    return rtf.format(Math.round(hours), "hour");
  }
};

export const getDueTimeString = (
  dueTime: number,
  dueDate: TISODateFormat,
  t: TFunction,
  currentLanguage: string,
) => {
  if (isUpcoming(dueTime)) {
    return `${t("time.due")}: ${formatDate(dueDate)}`;
  } else if (isDue(dueTime)) {
    return `${t("time.due")} ${getTimeString(dueTime, currentLanguage)}`;
  } else {
    return `${t("time.overdue")} ${getTimeString(dueTime, currentLanguage)}`;
  }
};

/**
 * Create a human-readable relative date/time string.
 * @param relativeTo past date to use for relative time
 * @param currentLanguage taken from i18n
 * @param now current date (optional)
 * @returns relative date/time display string (e.g. "5 days ago", "1 hour ago", "20 minutes ago", "10 seconds ago")
 */
export const getRelativeTimeString = (
  relativeTo: Date,
  currentLanguage: string,
  now: Date = new Date(),
) => {
  if (!relativeTo || !now) {
    return "";
  }

  const secsSince = (now.getTime() - relativeTo.getTime()) / 1000;

  const daysSince = secsSince / (60 * 60 * 24);
  const hoursSince = secsSince / (60 * 60);
  const minutesSince = secsSince / 60;

  let delta: number;
  let unit: Intl.RelativeTimeFormatUnit;

  if (daysSince >= 1) {
    delta = daysSince;
    unit = "day";
  } else if (hoursSince >= 1) {
    delta = hoursSince;
    unit = "hour";
  } else if (minutesSince >= 1) {
    delta = minutesSince;
    unit = "minute";
  } else {
    delta = secsSince;
    unit = "second";
  }

  const rtf = new Intl.RelativeTimeFormat(currentLanguage, {
    numeric: "always",
    style: "long",
  });

  return rtf.format(-1 * Math.round(delta), unit);
};
