import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TUserContextType } from "context/UserContext";
import { TProductFamily } from "../ProductFamily.types";
import { TProductFamilyRowData } from "./ProductFamilyTable.types";

export const getProductFamilyForRender = (
  productFamilies: TProductFamily[] | undefined | null,
  t: TFunction,
  onEditSubstance: (productFamily: TProductFamily) => void,
  user: TUserContextType,
): TProductFamilyRowData[] =>
  productFamilies?.map((productFamily) => ({
    ...productFamily,
    actions: (
      <ButtonMenu
        options={[
          {
            id: "edit-productFamily",
            label: t("editProductFamily", { ns: "administration" }),
            onClick: () => {
              onEditSubstance(productFamily);
            },
          },
        ]}
      >
        <IconButton aria-label="actionsButton" disabled={!user.isBusinessAdmin}>
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  })) ?? [];
