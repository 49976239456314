export enum WorkflowId {
  StartGSP = "orbis-sponsor-gsp-create",
  StartSponsorReview = "orbis-review",
  InitiateShare = "orbis-asset-share",
  RevokeShare = "orbis-asset-revoke",
  SendHAQCopy = "haq-send-copy",
  ImportedHAQSendCopy = "imported-haq-send-copy",
  HAAssetManualStatusChange = "ha-asset-manual-state-change",
  SponsorAssetManualStatusChange = "sponsor-asset-manual-state-change",
  HAQRequestExternalReview = "orbis-ha-question-external-review-request",
  OrbisSubmitHAQResponse = "orbis-ha-question-response-send-copy-to-ha",
  RelianceSubmitImportedHAQ = "imported-haq-send-copy",
  RelianceSubmitHAQResponse = "reliance-haq-response-send-copy-to-ha",
  RelianceSubmitDocument = "pac-reliance-submit-for-documents",
  RelianceProjectChangeStatus = "reliance-project-change-status",
  ChangeHAQDueDate = "change-due-date",
}
