import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorOutline } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { CheckboxTree, ConfirmModal } from "components/shared";
import { ICheckboxOption } from "components/shared/CheckboxTree/CheckboxTree.utils";
import useProductVariants from "hooks/api/GQL/entityManagement/useProductVariants";
import useUpdateProductStatus from "hooks/api/GQL/entityManagement/useUpdateProductStatus";
import { EntityProductStatus, TEntityProduct } from "../Products.types";
import { getProductVariantOptions } from "./ChangeProductStatusModal.utils";
import styles from "./ChangeProductStatusModal.styles";

export type TChangeProductStatusModalProps = {
  isOpen: boolean;
  product?: TEntityProduct;
  onClose: () => void;
};

const ChangeProductStatusModal = ({
  isOpen,
  product,
  onClose,
}: TChangeProductStatusModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const isInactivation = product?.status === EntityProductStatus.Active;

  const { productVariants, isLoading } = useProductVariants(
    { productId: product?.id || "" },
    !isInactivation,
  );

  const { updateStatus, isLoading: isUpdatingStatus } =
    useUpdateProductStatus();

  const [selectedProductVariants, setSelectedProductVariants] = useState<
    ICheckboxOption[]
  >([]);

  const activationOptions = useMemo(() => {
    return getProductVariantOptions(t, productVariants) || [];
  }, [t, productVariants]);

  const actionDescriptionText = isInactivation
    ? t("inactivateProduct", {
        productName: product?.tradeName,
      })
    : t("activateProduct", {
        productName: product?.tradeName,
      });

  const actionVariantHelpText = isInactivation
    ? t("bulkInactivationHelpText")
    : t("bulkActivationHelpText");

  const onChangeStatus = async (shouldChangeStatus: boolean) => {
    if (
      shouldChangeStatus &&
      product &&
      (isInactivation || !isEmpty(selectedProductVariants))
    ) {
      await updateStatus({
        updateProductStatusBody: {
          productId: product.id,
          active: !isInactivation,
          productVariants: !isInactivation
            ? selectedProductVariants.map((variant) => ({
                id: variant.id,
                active: true,
              }))
            : [],
        },
      });
    }

    onClose();
  };

  return (
    <ConfirmModal
      maxWidth="md"
      open={isOpen}
      title={t("changeProductStatus")}
      onConclude={onChangeStatus}
      confirmButtonText={t("button.ok", { ns: "common" })}
      cancelButtonText={t("button.cancel", { ns: "common" })}
      isConfirmButtonDisabled={
        isLoading ||
        isUpdatingStatus ||
        (!isInactivation && isEmpty(selectedProductVariants))
      }
      confirmColor="primary"
    >
      <Stack direction="column" alignItems="center" spacing={2} mb={4}>
        <ErrorOutline fontSize="large" sx={styles.errorIcon} />
        <Typography variant="body1">{actionDescriptionText}</Typography>
        <Typography variant="body1">{actionVariantHelpText}</Typography>
        {!isInactivation && activationOptions && (
          <Box sx={styles.variantContainer}>
            <CheckboxTree
              onChange={setSelectedProductVariants}
              values={selectedProductVariants}
              options={activationOptions}
              isSelectAllEnabled={true}
              selectAllSectionsLabel={product?.tradeName || ""}
              expandButtonLabel="Expand"
              getValue={(option) => option}
            />
          </Box>
        )}
      </Stack>
    </ConfirmModal>
  );
};

export default ChangeProductStatusModal;
