/* istanbul ignore file */
import { QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { CssBaseline, ThemeProvider } from "@mui/material";
import getEnv from "env";
import { reactPlugin } from "components/ApplicationInsights/ApplicationInsights";
import { Security } from "components/Auth";
import HashElementScroll from "components/HashElementScroll";
import { queryClient } from "config/reactQueryClient";
import AlertProvider from "context/AlertContext/AlertContext";
import { ConfirmProvider } from "context/ConfirmationDialogContext";
import useBroadcastChannel from "hooks/useBroadcastChannel";
import { useDayjsLocalization } from "hooks/useDayjsLocalization";
import useDocumentTitle from "hooks/useDocumentTitle";
import theme from "theme";
import { logoutHandler } from "utils/auth";
import { LOGOUT_BROADCAST_CHANNEL_ID } from "utils/constants/broadcastChannel.constants";
import { AppInsightsFallback } from "./common/ErrorBoundaries";

const Accumulus = () => {
  useDocumentTitle(getEnv().appTitle);
  useBroadcastChannel({
    name: LOGOUT_BROADCAST_CHANNEL_ID,
    handler: logoutHandler,
  });
  useDayjsLocalization();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashElementScroll />
      <QueryClientProvider client={queryClient}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <AppInsightsErrorBoundary
            appInsights={reactPlugin}
            onError={() => <AppInsightsFallback />}
          >
            <ConfirmProvider>
              <AlertProvider>
                <Security>
                  <Outlet />
                </Security>
              </AlertProvider>
            </ConfirmProvider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default Accumulus;
