import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";
import getEnv from "env";
import PreviewDocumentLink from "components/common/Document/PreviewDocumentLink";
import { ProjectLink } from "components/common/Tasks/TaskDrawer/components/FooterLinks";
import { useProject } from "context/ProjectContext";
import useAsset from "hooks/api/REST/assets/useAsset";
import usePermissions from "hooks/permissions";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { IHAQ } from "models/HAQ.models";
import { getRequiredDocumentSubtypesByProjectType } from "screens/Project/sections/Documents";
import { getCanDocumentBePreviewed } from "screens/Project/sections/Documents/utils/documentStatuses";
import { EMPTY_LABEL, getParsedARN, getProjectPath } from "utils/helpers";
import styles from "./Footer.styles";

interface IHAQDetailsFooterProps {
  HAQ: IHAQ | undefined;
  projectId: string | undefined;
}

const Footer = ({ HAQ, projectId }: IHAQDetailsFooterProps) => {
  const { t } = useTranslation("HAQ");

  const { project } = useProject();
  const { permissions } = usePermissions(projectId || "");

  const assetRelation =
    typeof HAQ?.metadata?.assets?.[0] === "object"
      ? HAQ.metadata.assets[0]
      : { id: HAQ?.metadata?.assets?.[0], name: "" };

  const assetResponse = useAsset<IDocumentMetadata>(
    assetRelation.id || "",
    {},
    {
      enabled: getParsedARN(assetRelation.id)?.tenantId === getEnv().tenant,
    },
  );

  const document = assetResponse.asset as IDocument | undefined;

  return (
    <Stack rowGap={1.25}>
      <Grid container columnSpacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.label}>
            {t("detailsPreview.footer.domain")}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" data-qaid="haq-drawer-domain-value">
            {HAQ?.metadata?.domain
              ? t(`domains.${HAQ.metadata.domain}`)
              : EMPTY_LABEL}
          </Typography>
        </Grid>
      </Grid>

      <Grid container columnSpacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.label}>
            {t("detailsPreview.footer.topic")}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" data-qaid="haq-drawer-topic-value">
            {HAQ?.metadata?.topic
              ? t(`domains.${HAQ.metadata.topic}`)
              : EMPTY_LABEL}
          </Typography>
        </Grid>
      </Grid>

      <ProjectLink
        projectName={project?.name}
        projectPath={getProjectPath(projectId)}
      />

      {document
        ? getRequiredDocumentSubtypesByProjectType(project?.type).includes(
            document.type,
          ) &&
          getCanDocumentBePreviewed(document, permissions) && (
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="body2" sx={styles.label}>
                  {t("viewPage.infoMenu.information.document")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <PreviewDocumentLink document={document} />
              </Grid>
            </Grid>
          )
        : assetRelation.name && (
            <Grid container columnSpacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="body2" sx={styles.label}>
                  {t("viewPage.infoMenu.information.document")}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">{assetRelation.name}</Typography>
              </Grid>
            </Grid>
          )}

      <Grid container columnSpacing={1} alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2" sx={styles.label}>
            {t("detailsPreview.footer.contentReference")}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">
            {HAQ?.metadata?.contentReference || EMPTY_LABEL}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Footer;
