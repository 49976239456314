import { IRecentActivityAsset } from "hooks/api/GQL/recentActivity/useRecentActivity.models";
import { IAsset } from "models/assets.models";
import { IDocument, IDocumentMetadata } from "models/documents.models";
import { Path } from "router/paths";
import { getIsDocumentStructuredAAID } from "screens/Project/sections/Documents/utils/documentStatuses";
import { DocumentSubtype } from "utils/constants/doc.constants";

const getDocumentPath = (
  projectId: string | undefined,
  document:
    | IAsset
    | IDocument
    | IRecentActivityAsset<IDocumentMetadata>
    | undefined,
) => {
  if (document?.type === DocumentSubtype.GSP) {
    return Path.ProjectGSP.replace(":projectId", projectId ?? "").replace(
      ":GSPId",
      document?.id ?? "",
    );
  }

  if (getIsDocumentStructuredAAID(document as IDocument)) {
    return Path.ProjectDocument.replace(":projectId", projectId ?? "").replace(
      ":documentId",
      document?.id ?? "",
    );
  }

  return document
    ? `${Path.ProjectDocuments.replace(
        ":projectId",
        projectId ?? "",
      )}?documentId=${document?.id}`
    : Path.ProjectDocuments.replace(":projectId", projectId ?? "");
};

export default getDocumentPath;
