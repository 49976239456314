import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFeatureFlags } from "context/FeatureFlagsContext";
import { useShouldShowTasks } from "hooks/access";
import useUser from "hooks/useUser";
import { FeatureFlag } from "utils/constants/featureFlags.constants";
import {
  getAdminNavigationItems,
  getNonAdminNavigationItems,
} from "./useNavigationItems.utils";

export type TNavigationItem = {
  key: string;
  label: string;
  icon?: JSX.Element;
  href: string;
  items?: TNavigationItem[];
  disabled?: boolean;
};

const useNavigationItems = () => {
  const user = useUser();
  const featureFlags = useFeatureFlags();
  const { t } = useTranslation(["user", "common"]);
  const shouldShowTasks = useShouldShowTasks();

  const shouldShowPortfolio =
    featureFlags["enableFHIRDocuments"] === FeatureFlag.On;

  const accumulusAdminNavigationItems = useMemo(
    () => getAdminNavigationItems(t, user, featureFlags),
    [t, user, featureFlags],
  );

  const nonAdminNavigationItems = useMemo(
    () => getNonAdminNavigationItems(t, shouldShowTasks, shouldShowPortfolio),
    [t, shouldShowTasks, shouldShowPortfolio],
  );

  const navigationItems: TNavigationItem[] = [
    ...nonAdminNavigationItems,
    ...accumulusAdminNavigationItems,
  ];

  return navigationItems;
};

export default useNavigationItems;
