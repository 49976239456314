import { PropsWithChildren } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styles from "./AddDocumentTeaser.styles";

type TAddDocumentTeaserProps = PropsWithChildren<{
  icon: JSX.Element;
  title: string;
  description?: JSX.Element;
}>;

const AddDocumentTeaser = ({
  icon,
  title,
  description,
  children,
}: TAddDocumentTeaserProps) => {
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      flexWrap="nowrap"
      sx={styles.teaserContainer}
    >
      {icon}

      <Typography variant="h6" component="h2" maxWidth="100%" noWrap>
        {title}
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        data-testid="add-document-teaser"
        data-qaid="add-document-teaser"
      >
        {description}
      </Box>

      <Stack
        direction="column"
        justifyContent="flex-end"
        sx={styles.actionBlock}
      >
        <Box>{children}</Box>
      </Stack>
    </Stack>
  );
};

export default AddDocumentTeaser;
