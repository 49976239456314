import { isEmpty } from "lodash";
import { IDocument } from "models/documents.models";
import { IOrganization } from "models/organizations.models";
import { DocumentStatus } from "utils/constants/doc.constants";
import { getCanDocumentBeSubmitted } from "../utils/documentStatuses";

export const getAllDocumentsHaveSameStatus = (
  selectedDocuments: IDocument[],
  indicatedStatus: DocumentStatus,
) => {
  return selectedDocuments.every(
    (document) => document.status === indicatedStatus,
  );
};

export const getDocumentsContainSubmittedContent = (
  selectedDocuments: IDocument[],
) => {
  return selectedDocuments.some(
    (document) => document?.metadata?.submissionDate,
  );
};

export const getAreDocumentsEligibleForStatusChange = (
  selectedDocuments: IDocument[],
) => {
  if (!isEmpty(selectedDocuments)) {
    return getAllDocumentsHaveSameStatus(
      selectedDocuments,
      DocumentStatus.InVerification,
    );
  }

  return false;
};

export const getAreDocumentsEligibleForDeletion = (
  selectedDocuments: IDocument[],
) => {
  if (!isEmpty(selectedDocuments)) {
    return getAllDocumentsHaveSameStatus(
      selectedDocuments,
      DocumentStatus.InVerification,
    );
  }

  return false;
};

export const getCanDocumentsBeSubmitted = (
  selectedDocuments: IDocument[],
  organization: IOrganization | undefined,
) =>
  selectedDocuments.every((document) =>
    getCanDocumentBeSubmitted(document, organization),
  );
