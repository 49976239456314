import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Button } from "components/shared";
import { useProject } from "context/ProjectContext";
import usePermissions from "hooks/permissions";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import styles from "screens/Project/sections/Documents/DocumentsTeasers/DocumentsTeasers.styles";
import { findCompletedDocument } from "screens/Project/sections/Documents/utils/documentHelper";
import { getCanDocumentBePreviewed } from "screens/Project/sections/Documents/utils/documentStatuses";
import getDocumentIcons from "screens/Project/sections/Documents/utils/getDocumentIcons";
import { getPaletteColor } from "theme/helpers";
import { QueryFetchingStatus } from "utils/constants/api.constants";
import { DocumentAction, DocumentSubtype } from "utils/constants/doc.constants";
import { getDisplayedAssetStatus } from "utils/helpers";
import AddDocumentTeaser from "../AddDocumentTeaser";
import TeaserCard from "../TeaserCard";
import TeaserImgBox from "../TeaserImgBox";
import AAIDDocImg from "assets/images/aaid.png";

type TGenericTeaserProps = {
  documentType: DocumentSubtype;
  onAddDocument: () => void;
};

const GenericTeaser = ({
  documentType,
  onAddDocument,
}: TGenericTeaserProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { project } = useProject();

  const { requiredDocuments, setCurrentActionOnDocument } =
    useDocumentsContext();

  const { permissions } = usePermissions(project?.id ?? "");

  const { canUploadContentToAllProjects, canUploadContent } = permissions;

  const document = useMemo(
    () => findCompletedDocument(requiredDocuments.data, documentType),
    [requiredDocuments.data, documentType],
  );

  const documentTranslationKey =
    documentType === DocumentSubtype.ECTD ? "PROJECT_DOSSIER" : documentType;

  const isLoading = requiredDocuments.status === QueryFetchingStatus.Loading;

  const isAddButtonVisible = canUploadContentToAllProjects || canUploadContent;

  if (isLoading) {
    return (
      <TeaserCard
        title={t(`subtypeOptions.${documentTranslationKey}`)}
        imgBox={<TeaserImgBox isLoading={true} img={AAIDDocImg} />}
        description={
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {t("teaserCard.loading")}
          </Typography>
        }
      />
    );
  }

  if (document) {
    return (
      <TeaserCard
        title={t(`subtypeOptions.${documentTranslationKey}`)}
        imgBox={<TeaserImgBox isLoading={false} img={AAIDDocImg} />}
        description={
          <Typography
            variant="badgeLabel"
            color={getPaletteColor("text.secondary")}
          >
            {getDisplayedAssetStatus(
              document?.source?.tenantType,
              document.status,
              t,
            )}
          </Typography>
        }
      >
        {getCanDocumentBePreviewed(document, permissions) && (
          <Button
            data-testid={`${documentType}-teaser-view-button`}
            data-qaid={`${documentType}-teaser-view-button`}
            variant="outlined"
            onClick={() =>
              setCurrentActionOnDocument({
                documents: [document],
                documentAction: DocumentAction.Preview,
              })
            }
          >
            {t("button.view", { ns: "common" })}
          </Button>
        )}
      </TeaserCard>
    );
  }

  if (!project?.active) {
    return null;
  }

  return (
    <AddDocumentTeaser
      data-qaid="generic-document-teaser"
      icon={getDocumentIcons(t, "info").assessment}
      title={t(`subtypeOptions.${documentTranslationKey}`)}
      description={
        <Typography variant="caption" sx={styles.textCenter}>
          {t(`documents.teaser.${documentTranslationKey}.description`)}
        </Typography>
      }
    >
      {isAddButtonVisible && (
        <Button
          data-qaid={`${documentType}-teaser-add-button`}
          variant="outlined"
          onClick={onAddDocument}
        >
          {t("button.add", { ns: "common" })}
        </Button>
      )}
    </AddDocumentTeaser>
  );
};

export default GenericTeaser;
