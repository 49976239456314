import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { ConfirmModal } from "components/shared";
import { useProject } from "context/ProjectContext";
import { ProjectStatus } from "utils/constants/project.constants";
import styles from "./ParticipantsSubmissionConfirmModal.styles";

type TProjectParticipantsSubmissionConfirmModalProps = {
  isOpen: boolean;
  onConclude: (shouldSubmit: boolean) => void;
};

type TProjectParticipantsSubmissionConfirmModalContent = {
  modalTitle?: string;
  modalTextOutcomes?: string[];
  modalTextCopiedContent?: string[];
};

const ProjectParticipantsSubmissionConfirmModal = ({
  isOpen,
  onConclude,
}: TProjectParticipantsSubmissionConfirmModalProps) => {
  const { t } = useTranslation(["documents", "common"]);

  const { project } = useProject();

  const {
    modalTitle,
    modalTextOutcomes,
    modalTextCopiedContent,
  }: TProjectParticipantsSubmissionConfirmModalContent =
    project?.status === ProjectStatus.Planned
      ? t("importDocument.projectParticipantsFirstSubmissionConfirmModal", {
          returnObjects: true,
        })
      : t("importDocument.projectParticipantsSubmissionConfirmModal", {
          returnObjects: true,
        });

  return (
    <ConfirmModal
      open={isOpen}
      title={modalTitle}
      onConclude={onConclude}
      confirmButtonText={t("button.confirm", { ns: "common" })}
      cancelButtonText={t("button.cancel", { ns: "common" })}
      confirmColor="primary"
    >
      <Box sx={styles.bodyTextContainer}>
        {!isEmpty(modalTextOutcomes) && (
          <Box component="ul" sx={styles.unorderedList}>
            {modalTextOutcomes?.map((outcome: string, index: number) => (
              <Box component="li" key={index} sx={styles.listItem}>
                {outcome}
              </Box>
            ))}
          </Box>
        )}
        {!isEmpty(modalTextCopiedContent) && (
          <Box component="ol" sx={styles.orderedList}>
            {modalTextCopiedContent?.map((content: string, index: number) => (
              <Box component="li" key={index} sx={styles.listItem}>
                {content}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </ConfirmModal>
  );
};

export default ProjectParticipantsSubmissionConfirmModal;
