import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { TMenuOption } from "components/shared/ButtonMenu";
import useTasks from "hooks/api/REST/tasks/useTasks";
import useTriggerTaskEvent from "hooks/api/REST/tasks/useTriggerTaskEvent";
import useUser from "hooks/useUser";
import { getIsDocumentReadyForSending } from "screens/Project/sections/Documents/utils/documentStatuses";
import { QueryAPIKey } from "utils/constants/api.constants";
import { DocumentAction, DocumentStatus } from "utils/constants/doc.constants";
import { TaskEvent, TaskType } from "utils/constants/tasks.constants";
import { getDisplayedAssetStatus } from "utils/helpers";

type TUseGSPHeaderActions = {
  projectId: string | undefined;
  isEachHAHasSubmissionPlan: boolean;
  GSPStatus?: string;
};

const useGSPHeaderActions = ({
  projectId,
  isEachHAHasSubmissionPlan,
  GSPStatus,
}: TUseGSPHeaderActions) => {
  const { t } = useTranslation(["documents", "statuses"]);

  const { triggerTaskEvent } = useTriggerTaskEvent(
    t("notifications.updateGSPStatusSuccess"),
    t("notifications.updateGSPStatusFailure"),
  );

  const [isOpenSendCopyModal, setIsOpenSendCopyModal] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const { tasks } = useTasks({
    params: {
      includeTypes: TaskType.CreateGSP,
      onlyMyTasks: false,
      content: {
        project: projectId,
      },
    },
  });

  const { organization } = useUser();

  const fulfilGSPTaskId = useMemo(() => {
    if (tasks?.length && projectId) {
      return tasks[0]?.id || null;
    }
  }, [tasks, projectId]);

  const actionOptions = useMemo<TMenuOption[]>((): TMenuOption[] => {
    return [
      {
        id: DocumentAction.ChangeStatus,
        label: t("documentHeader.actions.changeStatus"),
        items: [
          {
            id: DocumentStatus.Draft,
            label: getDisplayedAssetStatus(
              organization?.type,
              DocumentStatus.Draft,
              t,
            ),
            disabled: true, // @TODO: FTE-2058 - Set to disabled until functionality exists
          },
          {
            id: DocumentStatus.Final,
            label: getDisplayedAssetStatus(
              organization?.type,
              DocumentStatus.Final,
              t,
            ),
            onClick: () => {
              fulfilGSPTaskId &&
                triggerTaskEvent(
                  {
                    id: fulfilGSPTaskId,
                    data: {
                      event: TaskEvent.ReadyHASubmission,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(QueryAPIKey.GetAsset);
                    },
                  },
                );
            },
            disabled:
              !fulfilGSPTaskId ||
              !isEachHAHasSubmissionPlan ||
              GSPStatus === DocumentStatus.Final,
          },
        ],
      },
      {
        id: DocumentAction.SendCopy,
        label: t("documentHeader.actions.sendCopy"),
        onClick: () => setIsOpenSendCopyModal(true),
        disabled: !getIsDocumentReadyForSending(
          GSPStatus as DocumentStatus,
          organization,
        ),
      },
    ];
  }, [
    fulfilGSPTaskId,
    triggerTaskEvent,
    queryClient,
    isEachHAHasSubmissionPlan,
    GSPStatus,
    t,
    organization,
  ]);

  return { actionOptions, isOpenSendCopyModal, setIsOpenSendCopyModal };
};

export default useGSPHeaderActions;
