import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TCreateProductEntityMutationData } from "./useCreateProductEntity.models";

export const query = gql`
  mutation CreateProduct(
    $productEntity: ProductInput
    $isMockVersion: Boolean
  ) {
    createProduct(
      productEntity: $productEntity
      isMockVersion: $isMockVersion
    ) {
      id
      name
      nonproprietaryName
      linkedSubstances
      linkedOrganizations
      linkedProductFamilies
      active
      productVariants {
        routeOfAdministration
        type
        manufacturedDoseForm
        numeratorValue
        numeratorUnit
        denominatorValue
        denominatorUnit
      }
    }
  }
`;

const useCreateProductEntity = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isError, isLoading, isSuccess, mutate } = useMutation(
    ({ productEntity, isMockVersion }: TCreateProductEntityMutationData) =>
      services.graphQL
        .request(query, {
          productEntity,
          isMockVersion,
        })
        .then(() => {
          showAlert({
            message: t("createProductSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityProducts);
        })
        .catch((error) => {
          showAlert({
            message: t("createProductFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, isError, isSuccess, createProduct: mutate };
};

export default useCreateProductEntity;
