import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { Button } from "components/shared";
import {
  MAX_PRODUCT_VARIANTS,
  productVariantFormDefaultValues,
} from "../CreateProductModal/CreateProductModal.constants";
import ProductEntityFormFields from "../ProductEntityFormFields";
import { ProductEntityFieldName } from "../ProductEntityFormFields/ProductEntityFormFields.types";
import ProductVariantFormFields from "../ProductVariantFormFields";
import { PlusIcon } from "assets/icons";

const CreateProductWithVariantForm = () => {
  const { t } = useTranslation(["administration", "common"]);

  const { fields, append, remove } = useFieldArray({
    name: ProductEntityFieldName.ProductVariants,
  });

  return (
    <>
      <ProductEntityFormFields />
      {fields.map((field, index) => (
        <ProductVariantFormFields
          key={index}
          index={index}
          onRemoveVariantGroup={remove}
          variantsCount={fields.length}
        />
      ))}
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="flex-end"
        mb={4}
      >
        <Button
          startIcon={<PlusIcon />}
          variant="contained"
          onClick={() => append(productVariantFormDefaultValues)}
          aria-label={t("product.addAdditionalVariant")}
          disabled={fields.length >= MAX_PRODUCT_VARIANTS}
        >
          {t("product.addAdditionalVariant")}
        </Button>
      </Stack>
    </>
  );
};

export default CreateProductWithVariantForm;
