import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { gql } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TUpdateSubstanceEntityMutationData } from "./useUpdateSubstanceEntity.models";

export const query = gql`
  mutation UpdateSubstance($substanceEntity: SubstanceUpdateInput) {
    updateSubstance(substanceEntity: $substanceEntity) {
      id
      name
      drugClassType {
        id
        name
        code
      }
      inn
      atcCode
      entityOrganizationIds
      active
    }
  }
`;

const useUpdateSubstanceEntity = () => {
  const { t } = useTranslation("administration", {
    keyPrefix: "notifications",
  });

  const { showAlert } = useAlerts();
  const queryClient = useQueryClient();

  const { data, isLoading, mutate } = useMutation(
    ({ substanceEntity }: TUpdateSubstanceEntityMutationData) =>
      services.graphQL
        .request(query, {
          substanceEntity,
        })
        .then(() => {
          showAlert({
            message: t("updateSubstanceSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetSubstances);
        })
        .catch((error) => {
          showAlert({
            message: t("updateSubstanceFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return { data, isLoading, updateSubstance: mutate };
};

export default useUpdateSubstanceEntity;
