import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import isEmpty from "lodash/isEmpty";
import { Button, Card, ReactTable, TableFilter } from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useUser from "hooks/useUser";
import EditOrganizationModal from "../EditOrganizationModal";
import { TEntityOrganization } from "../Organization.types";
import { getColumns } from "./columns";
import {
  OrganizationsTable as OrganizationsTableColumns,
  TOrganizationsRowData,
} from "./OrganizationsTable.types";
import { getOrganizationsForRender } from "./OrganizationsTable.utils";
import { useOrganizationsFilters } from "./useOrganizationsFilters";
import { PlusIcon } from "assets/icons";
import styles from "./OrganizationsTable.styles";

type TOrganizationsTableProps = {
  onClickCreateOrganization?: () => void;
  organizations: TEntityOrganization[] | undefined;
};

const OrganizationsTable = ({
  onClickCreateOrganization,
  organizations,
}: TOrganizationsTableProps) => {
  const { t } = useTranslation([
    "administration",
    "projects",
    "common",
    "countries",
  ]);

  const [selectedOrganization, setSelectedOrganization] =
    useState<TEntityOrganization | null>(null);

  const user = useUser();

  const noResults = isEmpty(organizations);

  const columnsData = useMemo(() => getColumns(t), [t]);

  const onEditOrganization = (selectedOrg: TEntityOrganization) => {
    setSelectedOrganization(selectedOrg);
  };

  const rowData = useMemo(
    () => getOrganizationsForRender(organizations, t, onEditOrganization, user),
    [organizations, t, user],
  );

  const handleCloseEditOrganizationModal = () => {
    setSelectedOrganization(null);
  };

  const {
    setColumnFilters,
    setGlobalFilter,
    ...tableInstance
  }: Table<TOrganizationsRowData> = useReactTable({
    columns: columnsData,
    data: rowData,
    enableColumnFilters: true,
    enableFilters: true,
    enableSorting: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableGlobalFilter: true,
    globalFilterFn: "includesString",
    initialState: {
      columnVisibility: {
        [OrganizationsTableColumns.Id]: false,
      },
      pagination: {
        pageSize: 20,
      },
    },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const { filterParams, keywordParam, onKeywordChange, onFiltersChange } =
    useReactTableFilters(setGlobalFilter, setColumnFilters);

  const organizationsFilters = useOrganizationsFilters({ organizations });

  return (
    <Stack direction="column" spacing={2}>
      <TableFilter
        filters={organizationsFilters}
        selectedFilters={filterParams}
        keywordFilterValue={keywordParam}
        onFiltersChange={onFiltersChange}
        onKeywordChange={onKeywordChange}
        filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
          ns: "common",
        })}
        showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
        hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
        clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
          ns: "common",
        })}
        errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
          ns: "common",
        })}
      />
      <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
        {user.isBusinessAdmin && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onClickCreateOrganization}
            aria-label={t("organization.addNewOrganization")}
            sx={{ ml: 2 }}
          >
            {t("organization.addNewOrganization")}
          </Button>
        )}
      </Stack>
      {noResults ? (
        <NoDataFallback
          sx={{ mt: 2 }}
          message={t("organization.noOrganizationsFound")}
        />
      ) : (
        <Card>
          <ReactTable<TOrganizationsRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
            bodyRowHover={false}
            onRowClick={() => {
              // TODO FTE-23910
            }}
          />
        </Card>
      )}
      <EditOrganizationModal
        onClose={handleCloseEditOrganizationModal}
        selectedOrganization={selectedOrganization}
      />
    </Stack>
  );
};

export default OrganizationsTable;
