import { Row } from "@tanstack/react-table";
import dayjs from "dayjs";
import { TFunction } from "i18next";
import { isDateValid } from "utils/validators/date.validator";
import { compareDates } from "../compareDates";
import { defaultCompare } from "../defaultCompare";
import { getFullName } from "../userName";

export function compareStringValues(
  stringA: string = "",
  stringB: string = "",
) {
  const valueA = stringA?.toLowerCase();
  const valueB = stringB?.toLowerCase();

  if (!valueB && valueA) {
    return -1;
  }

  if (valueB && !valueA) {
    return 1;
  }

  return defaultCompare(valueA, valueB);
}

export function sortByUser<T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
  _desc?: boolean | undefined,
): number {
  const valueA = getFullName(rowA.getValue(columnId));
  const valueB = getFullName(rowB.getValue(columnId));

  return compareStringValues(valueA, valueB);
}

export function sortByDate<T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
  _desc?: boolean | undefined,
): number {
  const dateA = rowA.getValue(columnId) as string;
  const dateB = rowB.getValue(columnId) as string;

  return compareDates(dateA, dateB, _desc);
}

function getSortByDateCompareValue<T extends object>(
  t: TFunction,
  row: Row<T>,
) {
  const dueDate: string = row.renderValue("dueDate");

  if (dayjs(dueDate).isValid()) {
    return 1;
  }

  if (dueDate === t("HAQs.dueDateReceived")) {
    return 2;
  }

  if (dueDate === t("HAQs.dueDateComplete")) {
    return 3;
  }

  return 4;
}

export const getSortByDueDate =
  (t: TFunction) =>
  <T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: string): number => {
    const sortValueA = getSortByDateCompareValue(t, rowA);
    const sortValueB = getSortByDateCompareValue(t, rowB);

    if (sortValueA === sortValueB && sortValueA === 1) {
      return compareDates(
        rowA.renderValue(columnId),
        rowB.renderValue(columnId),
      );
    }

    return sortValueA - sortValueB;
  };

function getSortBySubmissionStatusValue<T extends {}>(
  row: Row<T>,
  t: TFunction,
): number {
  const submissionStatus: string = row.renderValue("submissionStatus");
  const readyForHAText = t("readyForHA", { ns: "HAQ" });

  if (submissionStatus === readyForHAText) {
    return 1;
  }

  if (submissionStatus === "—") {
    return 2;
  }

  return 3;
}

export const getSortBySubmissionStatus =
  (t: TFunction) =>
  <T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: string): number => {
    if (
      isDateValid(rowA.renderValue("submissionStatus")) &&
      isDateValid(rowB.renderValue("submissionStatus"))
    ) {
      const descendingOrder = true;

      return sortByDate(rowA, rowB, columnId, descendingOrder);
    }

    return (
      getSortBySubmissionStatusValue(rowA, t) -
      getSortBySubmissionStatusValue(rowB, t)
    );
  };
