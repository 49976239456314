import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

//  TODO: Implementation of this functionality within the task FTE-10007

export const deriveAssignTasksToIndividualsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCompleteTaskPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCancelTasksInAssociatedProjectsPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveCancelTaskPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCancelTasksPermission_deprecated: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveForceCompleteionTaskPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveUpdateTaskDueDatePermission_deprecated: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }

          if (userRoles.isContentEditor) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };

export const deriveUpdateTaskDueDatePermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveReassignTaskPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveCanManageTaskAssigneesPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveViewAllTasksPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      if (isSponsorUser(organization)) {
        if (isGlobalPM) {
          return true;
        }
      }

      return false;
  }
};

export const deriveViewAssignToAllGroupedTaskPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    switch (project?.type) {
      case ProjectType.Reliance:
        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return true;
          }
        }

        return false;

      default:
        return false;
    }
  };
