import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormWrapper,
  Modal,
  ModalActionButtons,
} from "components/shared";
import useCreateProductEntity from "hooks/api/GQL/entityManagement/useCreateProductEntity";
import { TCreateProductEntityMutationData } from "hooks/api/GQL/entityManagement/useCreateProductEntity.models";
import CreateProductWithVariantForm from "../CreateProductWithVariantForm";
import { TProductEntityForm } from "../ProductEntityFormFields/ProductEntityFormFields.types";
import { productEntityFormDefaultValues } from "./CreateProductModal.constants";
import {
  getCreateProductModalSchema,
  getProductValuesForCreation,
} from "./CreateProductModal.utils";

export type TCreateProductModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateProductModal = ({ isOpen, onClose }: TCreateProductModalProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const methods = useForm<TProductEntityForm>({
    mode: "all",
    defaultValues: productEntityFormDefaultValues,
    resolver: zodResolver(getCreateProductModalSchema(t)),
  });

  const { createProduct, isLoading } = useCreateProductEntity();

  const { formState, handleSubmit, reset } = methods;

  const onConcludeCreateProduct = () => {
    reset();
    onClose();
  };

  const onSubmit = (values: TProductEntityForm) => {
    const params: TCreateProductEntityMutationData = {
      productEntity: getProductValuesForCreation(values),
      isMockVersion: true,
    };

    const handlePostActions = {
      onSuccess: () => {
        reset();
        onClose();
      },
    };

    createProduct(params, handlePostActions);
  };

  const cancelButton = (
    <Button
      data-qaid="create-product-entity-cancel-button"
      key="cancel"
      variant="text"
      onClick={onConcludeCreateProduct}
    >
      {t("button.cancel", { ns: "common" })}
    </Button>
  );

  const submitButton = (
    <Button
      key="submit-button"
      data-qaid="create-product-entity-submit-button"
      variant="contained"
      disabled={!formState.isValid || isLoading}
      type="submit"
    >
      {t("button.submit", { ns: "common" })}
    </Button>
  );

  return (
    <Modal
      maxWidth="lg"
      open={isOpen}
      title={t("createNewProductModalTitle", { ns: "administration" })}
    >
      <FormWrapper methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <CreateProductWithVariantForm />
        <ModalActionButtons buttons={[cancelButton, submitButton]} />
      </FormWrapper>
    </Modal>
  );
};

export default CreateProductModal;
