import { useMutation } from "react-query";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { IStartWorkflowRequestData } from "models/workflow.models";
import { services } from "services";
import { WorkflowId } from "utils/constants/workflows.constants";

export type TStartWorkflowMutation = {
  id: WorkflowId;
  payload: IStartWorkflowRequestData;
};

export async function startWorkflow(
  showAlert: TShowAlert,
  params: TStartWorkflowMutation,
  successMessage?: string,
  failureMessage?: string,
) {
  const { id, payload } = params;

  try {
    const response = await services.workflows.startWorkflow(id, payload);

    if (successMessage) {
      showAlert({
        message: successMessage,
        severity: "success",
      });
    }

    return response;
  } catch (error) {
    if (failureMessage) {
      showAlert({
        message: failureMessage,
        severity: "error",
      });
    }

    throw error;
  }
}

export default function useStartWorkflow({
  successMessage,
  failureMessage,
}: {
  successMessage?: string;
  failureMessage?: string;
} = {}) {
  const { showAlert } = useAlerts();

  const {
    data: workflowResponse,
    isLoading,
    isSuccess,
    error,
    mutate,
    mutateAsync,
  } = useMutation((params: TStartWorkflowMutation) =>
    startWorkflow(showAlert, params, successMessage, failureMessage),
  );

  return {
    workflowResponse,
    isLoading,
    isSuccess,
    error,
    startWorkflow: mutate,
    startWorkflowAsync: mutateAsync,
  };
}
