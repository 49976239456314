import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useAlerts } from "context/AlertContext";
import { ILegalAcknowledgementData } from "models/legalAcknowledgement.models";
import { services } from "services";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TErrorData } from "utils/types";

export default function useLegalAcknowledgements() {
  const { showAlert } = useAlerts();

  const { t } = useTranslation("user", {
    keyPrefix: "notifications",
  });

  const {
    data: acknowledgements,
    error,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<ILegalAcknowledgementData[], AxiosError<TErrorData>>(
    [QueryAPIKey.GetUserLegalAcknowledgement],
    () =>
      services.user
        .getUserLegalAcknowledgements()
        .then((res) => res.data)
        .catch(() => {
          showAlert({
            message: t("getLegalAcknowledgementsFailure"),
            severity: "error",
          });
        }),
  );

  return {
    acknowledgements,
    isLoading,
    isError,
    isSuccess,
    error,
  };
}
