import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import uniqBy from "lodash/uniqBy";
import { FilterType, TSelectFilter } from "components/shared/types/filter";
import { TEntityOrganization } from "screens/EntityManagement/components/Organization/Organization.types";
import COUNTRIES, { TCountry } from "utils/constants/countries.constants";
import { OrganizationsTable } from "./OrganizationsTable.types";

type TUseOrganizationsFilters = {
  organizations: TEntityOrganization[] | undefined;
};

export const useOrganizationsFilters = ({
  organizations,
}: TUseOrganizationsFilters): Record<string, TSelectFilter> => {
  const { t } = useTranslation(["administration", "common", "projects"]);

  return useMemo(
    () => ({
      [OrganizationsTable.Type]: {
        type: FilterType.Select,
        label: t("organizationColumns.type"),
        options:
          uniqBy(
            organizations?.map((organization) => ({
              text: t(`organization.types.${organization.type}`),
              value: organization.type,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [OrganizationsTable.Country]: {
        type: FilterType.Select,
        label: t("organizationColumns.country"),
        options: organizations
          ? uniqBy(
              organizations.map((organization) => ({
                text: t(
                  COUNTRIES[organization.country as keyof TCountry]?.name,
                  {
                    ns: "countries",
                  },
                ),
                value: organization.country,
              })),
              "value",
            )
          : [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
      [OrganizationsTable.Status]: {
        type: FilterType.Select,
        label: t("organizationColumns.status"),
        options:
          uniqBy(
            organizations?.map((organization) => ({
              text: t(`${organization.status}`),
              value: organization.status,
            })),
            "value",
          ) ?? [],
        selectAllLabel: t("multiSelect.selectAll", { ns: "common" }),
      },
    }),
    [t, organizations],
  );
};
