import { MutableRefObject } from "react";
import { QueryClient } from "react-query";
import { Editor } from "@ckeditor/ckeditor5-core";
import { TrackChangesAdapter } from "@ckeditor/ckeditor5-track-changes/src/trackchanges";
import { TFunction } from "i18next";
import { TAlert } from "context/AlertContext";
import { ISuggestion } from "models/annotations.models";
import { QueryAPIKey } from "utils/constants/api.constants";

const getSuggestion =
  (
    editor: Editor,
    getSuggestionMethod: (
      componentId: string,
      suggestionId: string,
    ) => Promise<any>,
    showAlertRef: MutableRefObject<(alert: TAlert) => void>,
    t: TFunction,
    queryClient: QueryClient,
  ): TrackChangesAdapter["getSuggestion"] =>
  async (suggestionId) => {
    const componentId = editor.config.get("collaboration.channelId")!;

    try {
      const { data: responseData }: { data: ISuggestion } =
        await queryClient.fetchQuery(
          [QueryAPIKey.GetSuggestion, suggestionId],
          () => getSuggestionMethod(componentId, suggestionId),
        );

      const {
        id,
        originalSuggestionId,
        type,
        authorId,
        createdDate,
        data,
        hasComments,
      } = responseData;

      return {
        id,
        originalSuggestionId,
        type,
        authorId,
        createdAt: new Date(createdDate),
        data: data.length > 0 ? JSON.parse(data) : undefined,
        hasComments,
        attributes: {},
      };
    } catch (error) {
      showAlertRef.current({
        message: t("notifications.getSuggestionFailure"),
        severity: "error",
      });
    }

    // CKEditor assumes all requests are successful, so there's nothing we can do about the return
    // value if it isn't. Future work will actually handle the errors and do something more useful
    // than alerting the user.
    return undefined as any;
  };

export default getSuggestion;
