export enum ParsingService {
  DocX4J = "DOCX4J",
}

export enum ConversionService {
  CKEditor = "CKEDITOR",
  DocX4J = "DOCX4J",
}

export enum DocumentStatus {
  Uploaded = "UPLOADED",
  Processing = "PROCESSING",
  Completed = "COMPLETED",
  Failure = "FAILURE",
  Draft = "DRAFT",
  Final = "FINAL",
  InVerification = "IN_VERIFICATION",
  // ToDo (AV): status Shared. Back-end doesn't provide such a status to the client side. We have to revisit it.
  Shared = "SHARED",
  Reviewed = "REVIEWED",
  Acknowledged = "ACKNOWLEDGED",
  Cleared = "CLEARED",
  Cancelled = "CANCELLED",
}

export enum DocumentComponentStatus {
  Draft = "DRAFT",
  InReview = "IN_REVIEW",
  Final = "FINAL",
  Cleared = "CLEARED",
}

export enum ParseJobStatus {
  Processing = "PROCESSING",
  Completed = "COMPLETED",
  Failure = "FAILURE",
}

export enum DocumentViewMode {
  Editing = "Editing",
  Viewing = "Viewing",
  Review = "Review",
}

export enum DocumentSubtype {
  SupportingInformation = "SUPPORTING_INFORMATION",
  AssessmentAidSource = "ASSESSMENT_AID_SOURCE",
  AssessmentAid = "ASSESSMENT_AID",
  ToplineResult = "TOPLINE_RESULT",
  References = "REFERENCES",
  LOA = "LOA",
  GSP = "GSP",
  GSPComponent = "GSP_COMPONENT",
  Spreadsheet = "SPREADSHEET",
  Question = "QUESTION",
  QuestionResponse = "QUESTION_RESPONSE",
  FHIR = "FHIR",
  HADecisionLetter = "HA_DECISION_LETTER",
  AssessmentReport = "ASSESSMENT_REPORT",
  ProjectParticipants = "PROJECT_PARTICIPANTS",
  ImportedHAQ = "IMPORTED_HAQ",
  InspectionReports = "INSPECTION_REPORTS",
  CoverLetter = "COVER_LETTER",
  ApplicantsStrategy = "APPLICANTS_STRATEGY",
  ECTD = "ECTD",
}

export enum DocumentAction {
  Edit = "EDIT",
  Preview = "PREVIEW",
  Convert = "CONVERT",
  Submit = "SUBMIT",
  Share = "SHARE",
  SendCopy = "SEND_COPY",
  Delete = "DELETE",
  Download = "DOWNLOAD",
  ChangeStatus = "CHANGE_STATUS",
  ViewContentInfo = "VIEW_CONTENT_INFO",
  ViewShareHistory = "VIEW_SHARE_HISTORY",
  ViewSubmitHistory = "VIEW_SUBMIT_HISTORY",
  DeleteVersions = "DELETE_VERSIONS",
  StartSponsorReview = "START_SPONSOR_REVIEW",
  HandleClearedStatus = "HANDLE_CLEARED_STATUS",
  AddQuestion = "ADD_QUESTION",
}

export enum DocumentActionId {
  SetToDraft = "set-to-draft",
  StartSponsorReview = "start-sponsor-review",
  StartQC = "start-qc",
  SubmitToHA = "submit-to-ha",
  ChangeStatus = "change-status",
  DeleteVersions = "delete-versions",
  SendCopy = "send-copy",
  AddHAQ = "add-HAQ",
  HandleClearedStatus = "handle-cleared-status",
}

export const ENG_LANG_CODE = "eng";

const DOCX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const PDF_MIME_TYPE = "application/pdf";

const XLSX_MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const JSON_MIME_TYPE = "application/json";

const XLSM_MIME_TYPE = "application/vnd.ms-excel.sheet.macroenabled.12";

export const CSV_MIME_TYPE = "text/csv";
export const CSV_DOC_TYPE = "csv";
export const ZIP_MIME_TYPE = "application/zip";

export const ALLOWED_DOCS = [
  {
    mimeType: PDF_MIME_TYPE,
    docType: "pdf",
  },
  {
    mimeType: DOCX_MIME_TYPE,
    docType: "docx",
  },
  {
    mimeType: XLSX_MIME_TYPE,
    docType: "xlsx",
  },
  {
    mimeType: XLSM_MIME_TYPE,
    docType: "xlsm",
  },
] as const;

export const ALLOWED_IMPORT_MIME_TYPES = ALLOWED_DOCS.map(
  (doc) => doc.mimeType,
);

export const ALLOWED_FHIR_MIME_TYPES = [JSON_MIME_TYPE] as const;

export const ALLOWED_PROJECT_PARTICIPANTS_MIME_TYPES = [
  XLSX_MIME_TYPE,
] as const;

export const DOCUMENT_TYPES_FOR_PREVIEW = ["doc", "docx", "pdf"] as const;

export const DOCUMENT_TYPES_FOR_DOWNLOAD = ["doc", "docx", "pdf"] as const;

export const ALLOWED_GENERAL_DOC_MIME_TYPES = [
  DOCX_MIME_TYPE,
  PDF_MIME_TYPE,
] as const;

export const ALLOWED_IMPORTED_HAQ_MIME_TYPE = [XLSM_MIME_TYPE] as const;

export const ALLOWED_IMPORTED_ETCD_MIME_TYPE = [ZIP_MIME_TYPE] as const;

export const MAX_DOC_UPLOAD_SIZE = 2147483648; // 2GB in bytes

export const EXCLUDED_SUBTYPES = [
  DocumentSubtype.GSPComponent,
  DocumentSubtype.Spreadsheet,
  DocumentSubtype.Question,
  DocumentSubtype.QuestionResponse,
  DocumentSubtype.FHIR,
  DocumentSubtype.ImportedHAQ,
];

export const RelianceDocumentSubtypes = [
  DocumentSubtype.AssessmentReport,
  DocumentSubtype.HADecisionLetter,
  DocumentSubtype.ProjectParticipants,
  DocumentSubtype.SupportingInformation,
  DocumentSubtype.InspectionReports,
  DocumentSubtype.CoverLetter,
  DocumentSubtype.ApplicantsStrategy,
];

export const RegulatoryReviewDocumentSubtypes = [
  DocumentSubtype.AssessmentReport,
  DocumentSubtype.HADecisionLetter,
  DocumentSubtype.ProjectParticipants,
  DocumentSubtype.SupportingInformation,
  DocumentSubtype.InspectionReports,
  DocumentSubtype.CoverLetter,
  DocumentSubtype.ApplicantsStrategy,
];

export const OrbisDocumentSubtypes = [
  DocumentSubtype.AssessmentAid,
  DocumentSubtype.GSP,
  DocumentSubtype.LOA,
  DocumentSubtype.References,
  DocumentSubtype.SupportingInformation,
];

export const SUPPORTED_SUBTYPES = Object.values(DocumentSubtype).filter(
  (subtype) => !EXCLUDED_SUBTYPES.includes(subtype),
);
