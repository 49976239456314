import { useMemo } from "react";
import { useParams } from "react-router";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { getCanDocumentBeShared } from "screens/Project/sections/Documents/utils/documentStatuses";

const useShareableDocuments = (
  documents: IDocument[],
): { shareableDocuments: IDocument[] } => {
  const { projectId } = useParams();
  const { organization } = useUser();
  const userRoles = useUserRoles(projectId);

  const shareableDocuments = useMemo(
    () =>
      documents.filter((doc) =>
        getCanDocumentBeShared(doc, organization, userRoles),
      ),
    [documents, organization, userRoles],
  );

  return {
    shareableDocuments,
  };
};

export default useShareableDocuments;
