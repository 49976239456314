import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TUserContextType } from "context/UserContext";
import { TProductVariant } from "screens/EntityManagement/components/Products/Products.types";
import {
  ProductVariantsTable,
  TProductVariantsRowData,
} from "./ProductVariantsTable.types";

export const getProductVariantMenuOptions = (
  productVariant: TProductVariant,
  index: number,
  t: TFunction,
  onEditProductVariant: (productVariant: TProductVariant) => void,
  onChangeProductVariantStatus: (
    productVariant: TProductVariant,
    index: number,
  ) => void,
  user: TUserContextType,
) =>
  user?.isBusinessAdmin
    ? [
        {
          id: "edit-productVariant",
          label: t("editProductVariant", { ns: "administration" }),
          onClick: () => {
            onEditProductVariant(productVariant);
          },
        },
        {
          id: "change-productVariant-status",
          label: t("changeProductVariantStatus", {
            ns: "administration",
          }),
          onClick: () => {
            onChangeProductVariantStatus(productVariant, index);
          },
        },
      ]
    : [];

export const getProductVariantsForRender = (
  productVariants: TProductVariant[],
  t: TFunction,
  onEditProductVariant: (productVariant: TProductVariant) => void,
  onChangeProductVariantStatus: (
    productVariant: TProductVariant,
    index: number,
  ) => void,
  user: TUserContextType,
): TProductVariantsRowData[] =>
  productVariants.map((productVariant, index) => ({
    [ProductVariantsTable.Id]: productVariant.id,
    [ProductVariantsTable.Type]: productVariant.productType.name,
    [ProductVariantsTable.RouteOfAdministration]:
      productVariant.routeOfAdministrationType.name,
    [ProductVariantsTable.ManufacturedDose]:
      productVariant.manufacturedDoseFormType.name,
    [ProductVariantsTable.Strength]: getProductStrength(productVariant),
    [ProductVariantsTable.Status]: t(productVariant.status),
    [ProductVariantsTable.Actions]: (
      <ButtonMenu
        options={getProductVariantMenuOptions(
          productVariant,
          index,
          t,
          onEditProductVariant,
          onChangeProductVariantStatus,
          user,
        )}
      >
        <IconButton aria-label="actionsButton">
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  }));

export const getProductStrength = (productVariant: TProductVariant): string =>
  // eslint-disable-next-line max-len
  `${productVariant.strengthValue} ${productVariant.strengthType.code} / ${productVariant.strengthVolumeValue} ${productVariant.strengthVolumeType.code}`;
