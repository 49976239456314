import { ProjectType } from "utils/constants/project.constants";
import { isHAUser, isSponsorUser } from "utils/user/organization";
import { TPermissionFunction } from "../usePermissions.types";

//  TODO: Implementation of this functionality within the task FTE-11499

export const deriveViewContentInAllProjectsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
  isGlobalPM,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        // All HA users are PM
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager || isGlobalPM) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        // All HA users are PM
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager || isGlobalPM) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isHAUser(organization)) {
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveViewContentPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isHAUser(organization)) {
        return true;
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }

        if (userRoles.isContentViewer) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveUploadContentToAllProjectsPermission: TPermissionFunction =
  ({ project, organization, userRoles }) => {
    // TODO: (AR) Very strange but that's what it says in the permissions file

    switch (project?.type) {
      case ProjectType.Reliance:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.RegulatoryReview:
        if (isHAUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }
        }

        if (isSponsorUser(organization)) {
          if (userRoles.isProjectManager) {
            return false;
          }

          if (userRoles.isContentEditor) {
            return false;
          }
        }

        return false;

      case ProjectType.Orbis:
        return false;

      default:
        return false;
    }
  };

export const deriveUploadContentPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (userRoles.isProjectManager) {
        return true;
      }

      if (userRoles.isContentEditor) {
        return true;
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditContentInAllProjectsPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  // TODO: (AR) Very strange but that's what it says in the permissions file

  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditContentPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveEditContentMetadataPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveSendContentToTenancyPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    case ProjectType.Orbis:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return false;
        }
      }

      return false;

    default:
      return false;
  }
};

export const deriveDeleteContentToTenancyPermission: TPermissionFunction = ({
  project,
  organization,
  userRoles,
}) => {
  switch (project?.type) {
    case ProjectType.Reliance:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    case ProjectType.RegulatoryReview:
      if (isHAUser(organization)) {
        if (userRoles.isProjectManager) {
          return false;
        }
      }

      if (isSponsorUser(organization)) {
        if (userRoles.isProjectManager) {
          return true;
        }

        if (userRoles.isContentEditor) {
          return true;
        }
      }

      return false;

    default:
      return false;
  }
};
