import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterType,
  TFilteringParams,
  TFilters,
  TPossibleFilter,
} from "components/shared/types/filter";
import { useProject } from "context/ProjectContext";
import useKeywordFilter, {
  TUseKeywordFilterResult,
} from "hooks/filters/useKeywordFilter";
import { formatFilterParams } from "utils/helpers";
import { useDocumentsContext } from "../DocumentsContext";
import { getStatusFilter } from "./staticFilters";

type TUseFilterResult = TUseKeywordFilterResult & {
  filterParams: TFilteringParams;
  onFiltersChange: (filters: TFilteringParams) => void;
  filters: TFilters;
  filtersRequestParams: Record<string, string>;
};

const useFilters = (resetPagination: () => void): TUseFilterResult => {
  const [filterParams, setFilterParams] = useState<TFilteringParams>({});

  const { documentSubtypes } = useDocumentsContext();

  const { project } = useProject();

  const { t } = useTranslation("documents");

  const onFiltersChange = useCallback(
    (filters: TFilteringParams) => {
      setFilterParams(filters);
      resetPagination();
    },
    [resetPagination],
  );

  const keywordFilter = useKeywordFilter(resetPagination);

  const subtypeOptions = useMemo(
    () =>
      documentSubtypes.map((subtype) => ({
        text: t(`subtypeOptions.${subtype}`),
        value: subtype,
      })),
    [t, documentSubtypes],
  );

  const filters: Record<string, TPossibleFilter> = useMemo(
    () => ({
      assetTypes: {
        type: FilterType.Select,
        label: t("filters.subtypes.label"),
        options: subtypeOptions,
        selectAllLabel: t("filters.subtypes.selectAllLabel"),
      },
      statuses: getStatusFilter(project?.type, t),
    }),
    [subtypeOptions, project, t],
  );

  const filtersRequestParams = useMemo(
    () => formatFilterParams(filterParams),
    [filterParams],
  );

  return useMemo(
    () => ({
      filters,
      filterParams,
      onFiltersChange,
      filtersRequestParams,
      ...keywordFilter,
    }),
    [
      filterParams,
      filters,
      onFiltersChange,
      filtersRequestParams,
      keywordFilter,
    ],
  );
};

export default useFilters;

export type { TUseFilterResult };
