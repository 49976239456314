import { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import {
  IGroupedTaskParticipants,
  ITaskBaseData,
  ITaskBaseDataDeprecated,
  ITaskOrganizationParticipant,
  ITaskUserParticipant,
  TPossibleTaskContent,
  TTaskGenericContent,
  TTaskParticipant,
} from "models/tasks.models";
import {
  RESOLVED_TASKS_STATUSES,
  TaskStatus,
} from "utils/constants/tasks.constants";
import { compareDates } from "../compareDates";
import { getTextValue } from "../getTextValue";

export const getVisibleTasks = <T extends ITaskBaseDataDeprecated>(
  tasks: T[] | undefined,
) => {
  return tasks
    ?.sort((prev, next) =>
      compareDates(prev.content?.dueDate, next.content?.dueDate),
    )
    .slice(0, 3);
};

export const getTaskName = (
  task:
    | ITaskBaseData<TPossibleTaskContent>
    | ITaskBaseDataDeprecated<TPossibleTaskContent>
    | undefined,
) =>
  task && checkIfProjectTask(task)
    ? getTextValue(task?.content?.taskName || task?.content?.description)
    : getTextValue(task?.content?.description || task?.content?.taskName);

export const getTaskSuperType = (
  task:
    | ITaskBaseData<TPossibleTaskContent>
    | ITaskBaseDataDeprecated<TPossibleTaskContent>,
  t: TFunction,
) =>
  checkIfProjectTask(task)
    ? t("supertype.project", { ns: "tasks" })
    : t("supertype.workflow", { ns: "tasks" });

export const getIsTaskOngoing = (task: { status: TaskStatus }): boolean =>
  !RESOLVED_TASKS_STATUSES.includes(task?.status);

export const checkIfProjectTask = (
  task:
    | ITaskBaseData<TPossibleTaskContent>
    | ITaskBaseDataDeprecated<TPossibleTaskContent>,
) => !isEmpty((task.content as TTaskGenericContent).taskType);

export const checkIfTaskParticipantIsUser = (
  participant: TTaskParticipant,
): participant is ITaskUserParticipant =>
  (participant as ITaskUserParticipant)?.firstName !== undefined &&
  (participant as ITaskUserParticipant)?.lastName !== undefined;

export const checkIfTaskParticipantIsUserGroup = (
  participant: TTaskParticipant,
): participant is IGroupedTaskParticipants[] => Array.isArray(participant);

export const checkIfTaskParticipantIsOrganization = (
  participant: TTaskParticipant,
): participant is ITaskOrganizationParticipant =>
  (participant as ITaskOrganizationParticipant)?.name !== undefined;
