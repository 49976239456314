import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { HttpStatusCode } from "axios";
import { TFunction } from "i18next";
import { TShowAlert, useAlerts } from "context/AlertContext";
import { ILockBulkAssetResponseData } from "models/assets.models";
import { services } from "services";
import { getStatusCode } from "utils/auth";
import { getErrorMessageFromAPI } from "utils/helpers";

export async function lockBulkAssetsEditing(
  t: TFunction,
  showAlert: TShowAlert,
  assetIds: string[],
) {
  try {
    const response = await services.assets.lockBulkAssetsEditing(assetIds);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorResponse = error.response;
      const statusCode = getStatusCode(error) || 0;

      switch (statusCode) {
        case HttpStatusCode.NotFound:
          const arn = getErrorMessageFromAPI(error).split("=")[1];
          showAlert({
            message: t("asset:notifications.lockBulkAssetNotExistFailure", {
              arn,
            }),
            severity: "error",
          });
          break;
        case HttpStatusCode.Locked:
          const arns = (errorResponse?.data as ILockBulkAssetResponseData[])
            .map(({ assetId }) => assetId)
            .join();

          showAlert({
            message: t(
              "asset:notifications.lockBulkAssetSimultaneousEditingFailure",
              { arns },
            ),
            severity: "error",
          });
          break;
        default:
          showAlert({
            message: t("asset:notifications.lockBulkAssetEditingFailure"),
            severity: "error",
          });
      }
    }

    throw error;
  }
}

export default function useLockBulkAssetsEditing() {
  const { t } = useTranslation("asset");

  const { showAlert } = useAlerts();

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (assetIds: string[]) => lockBulkAssetsEditing(t, showAlert, assetIds),
  );

  return {
    isEditingAllowed: isSuccess,
    error,
    isLoading,
    isError,
    isSuccess,
    lockBulkAssetsEditing: mutate,
  };
}
