import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { FormSelect, FormTextInput } from "components/common/form-fields";
import { Button } from "components/shared";
import { TOption } from "components/shared/Select";
import useProductEntityControlledLists from "hooks/api/GQL/entityManagement/useProductEntityControlledLists";
import { TProductEntityControlledLists } from "hooks/api/GQL/entityManagement/useProductEntityControlledLists.models";
import {
  TControlledListEntry,
  TProductEntityControlledListOptions,
} from "models/entities.models";
import { MIN_PRODUCT_VARIANTS } from "../CreateProductModal/CreateProductModal.constants";
import { ProductEntityFieldName } from "../ProductEntityFormFields/ProductEntityFormFields.types";
import { getProductAndProductVariantStatusOptions } from "../ProductsTable/ProductsTable.utils";
import {
  ProductVariantFieldName,
  TProductVariantForm,
} from "./ProductVariantFormFields.types";
import styles from "./ProductVariantFormFields.styles";

export type TProductVariantFieldGroup = TProductVariantForm & { id: string };

export type TProductVariantFormFieldsProps = Partial<{
  index: number;
  onRemoveVariantGroup: (index: number) => void;
  variantsCount: number;
}>;

const ProductVariantFormFields = ({
  index = 0,
  onRemoveVariantGroup,
  variantsCount,
}: TProductVariantFormFieldsProps) => {
  const { t } = useTranslation(["administration", "common"]);

  const { data: controlledListData } = useProductEntityControlledLists();

  const statusOptions = useMemo(
    () => getProductAndProductVariantStatusOptions(t),
    [t],
  );

  const isProductCreationModal = onRemoveVariantGroup && variantsCount;

  const getVariantFieldName = (name: ProductVariantFieldName) =>
    isProductCreationModal
      ? `${ProductEntityFieldName.ProductVariants}.${index}.${name}`
      : name;

  const controlledLists = useMemo(() => {
    const listCollection = controlledListData?.productEntityControlledLists;

    const formattedLists:
      | TProductEntityControlledListOptions
      | Record<string, TOption[]> = {};

    if (listCollection) {
      Object.keys(listCollection).forEach((key) => {
        formattedLists[key] = listCollection[
          key as keyof TProductEntityControlledLists
        ].map((entry: TControlledListEntry) => ({
          label: entry.name,
          value: entry.code,
          id: entry.id,
        }));
      });
    }

    return formattedLists;
  }, [controlledListData]);

  return (
    <Box>
      <Grid container spacing={2} rowGap={1} mb={4}>
        {isProductCreationModal && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Typography variant="subtitle1">
                  {t("product.createNewVariant", { index: index + 1 })}
                </Typography>
                <Button
                  type="button"
                  variant="text"
                  startIcon={<CloseIcon />}
                  onClick={() => onRemoveVariantGroup?.(index)}
                  disabled={variantsCount <= MIN_PRODUCT_VARIANTS}
                  aria-label={t("product.removeVariant")}
                >
                  {t("product.removeVariant")}
                </Button>
              </Stack>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <FormSelect
            fullWidth
            name={getVariantFieldName(ProductVariantFieldName.ProductType)}
            label={t("manageProductVariantFormFields.type")}
            options={controlledLists.controlledProductTypes}
          />
        </Grid>

        <Grid item xs={6}>
          <FormSelect
            fullWidth
            name={getVariantFieldName(
              ProductVariantFieldName.RouteOfAdministration,
            )}
            label={t("manageProductVariantFormFields.routeOfAdministration")}
            options={controlledLists.controlledRouteOfAdministrationTypes}
          />
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            fullWidth
            name={getVariantFieldName(
              ProductVariantFieldName.ManufacturedDoseForm,
            )}
            label={t("manageProductVariantFormFields.manufacturedDoseForm")}
            options={controlledLists.controlledManufacturedDoseFormTypes}
          />
        </Grid>
      </Grid>

      <Paper elevation={0} variant="outlined" sx={styles.strengthContainer}>
        <Grid container spacing={1} rowGap={1} mb={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              {t("product.strengthLabel", { index: index + 1 })}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <FormTextInput
              fullWidth
              name={getVariantFieldName(ProductVariantFieldName.StrengthValue)}
              label={t("manageProductVariantFormFields.strengthValue")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              fullWidth
              name={getVariantFieldName(ProductVariantFieldName.StrengthType)}
              label={t("manageProductVariantFormFields.strengthType")}
              options={controlledLists.controlledStrengthTypes}
            />
          </Grid>
          <Grid item xs={3}>
            <FormTextInput
              fullWidth
              name={getVariantFieldName(
                ProductVariantFieldName.StrengthVolumeValue,
              )}
              label={t("manageProductVariantFormFields.strengthVolumeValue")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              fullWidth
              name={getVariantFieldName(
                ProductVariantFieldName.StrengthVolumeType,
              )}
              label={t("manageProductVariantFormFields.strengthVolumeType")}
              options={controlledLists.controlledStrengthVolumeTypes}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={2} rowGap={1} mb={4} mt={1}>
        <Grid item xs={6}>
          <FormSelect
            fullWidth
            name={getVariantFieldName(ProductVariantFieldName.Status)}
            label={t("manageProductVariantFormFields.status")}
            options={statusOptions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductVariantFormFields;
