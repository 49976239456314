import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import { TFunction } from "i18next";
import ButtonMenu from "components/shared/ButtonMenu";
import { TUserContextType } from "context/UserContext";
import { TEntityOrganization } from "../Organization.types";
import { TOrganizationsRowData } from "./OrganizationsTable.types";

export const getOrganizationsForRender = (
  organizations: TEntityOrganization[] | undefined | null,
  t: TFunction,
  onEditOrganization: (organization: TEntityOrganization) => void,
  user: TUserContextType,
): TOrganizationsRowData[] =>
  organizations?.map((organization) => ({
    ...organization,
    actions: (
      <ButtonMenu
        options={[
          {
            id: "edit-Organization",
            label: t("organization.editOrganization", { ns: "administration" }),
            onClick: () => {
              onEditOrganization(organization);
            },
          },
        ]}
        onClick={(event) => {
          event.stopPropagation(); // Prevents row selection from firing when clicking a menu option
        }}
      >
        <IconButton
          role="button"
          aria-label="actionsButton"
          disabled={!user.isBusinessAdmin}
        >
          <MoreVertIcon />
        </IconButton>
      </ButtonMenu>
    ),
  })) ?? [];
