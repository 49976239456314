export enum ModalTypes {
  Add = "ADD",
  Edit = "EDIT",
}

export enum TaskDrawerModalType {
  ManageAssignees,
  Cancellation,
  ChangeDueDate,
}

export enum TaskDrawerModalType_deprecated {
  ReassignModal,
  ChangeDueDateModal,
  RejectGSPModal,
  CancelModal,
}
