export enum AssetType {
  GSP = "GSP",
  GSPComponent = "GSP_COMPONENT",
  Product = "PRODUCT",
  DossierDifference = "DOSSIER_DIFFERENCE",
  Document = "DOCUMENT",
  Question = "QUESTION",
  QuestionResponse = "QUESTION_RESPONSE",
  QuestionResponseDocument = "QUESTION_RESPONSE_DOCUMENT",
  Component = "COMPONENT",
  Substance = "SUBSTANCE",
  DetailedDescriptionOfChange = "DETAILED_DESCRIPTION_OF_CHANGE",
}

export type THAQAssetType = Extract<
  AssetType,
  AssetType.Question | AssetType.QuestionResponse
>;

export enum AssetImportType {
  External = "EXTERNAL",
  Reference = "REFERENCE",
}

export enum TransferField {
  AssetDTO = "assetDto",
  File = "file",
}

export enum AssetDomain {
  Regulatory = "REGULATORY",
  System = "SYSTEM",
}

export enum AssetGroup {
  Administrative = "ADMINISTRATIVE",
  Correspondence = "CORRESPONDENCE",
  Data = "DATA",
  Users = "USERS",
  Submission = "SUBMISSION",
}

export enum AssetDefaultName {
  DossierDifference = "Dossier Difference",
  HAQ = "Health Authority Question",
}

export enum AssetRelationship {
  ParentOf = "PARENT_OF",
  HasAttachment = "HAS_ATTACHMENT",
  References = "REFERENCES",
  BelongsTo = "BELONGS_TO",
  Contains = "CONTAINS",
}

export enum AssetStatus {
  Undefined = "UNDEFINED",
  Draft = "DRAFT",
  ApprovedForSubmission = "APPROVED_FOR_SUBMISSION",
  InReview = "IN_REVIEW",
  Reviewed = "REVIEWED",
  Ready = "READY",
  Submitted = "SUBMITTED",
  Sent = "SENT",
  QAReady = "QA_READY",
  Received = "RECEIVED",
  Acknowledged = "ACKNOWLEDGED",
  Cancelled = "CANCELLED",
  Final = "FINAL",
  // ToDo (AV): status Shared. Back-end doesn't provide such a status to the client side. We have to revisit it.
  Shared = "SHARED",
  Cleared = "CLEARED",
  InQualityControl = "IN_QC",
}
