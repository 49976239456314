import { TFunction } from "i18next";
import { chain, intersection } from "lodash";
import { getCanChangeHAQStatus } from "components/common/HAQ/HAQHeader/components/HAQActions/HAQActions.utils";
import { TMenuOption } from "components/shared/ButtonMenu";
import {
  TBulkStatusChangeAction,
  TSelectedHAQTableRow,
} from "components/shared/types";
import { IOrganization } from "models/organizations.models";
import { HAQBulkActionType, HAQStatus } from "utils/constants/HAQ.constants";
import {
  getDisplayedAssetStatus,
  getHAQStatusChangeOptions,
  includeInArray,
} from "utils/helpers";
import { TManualHAQStatusOptions } from "utils/helpers/HAQ/getHAQStatusChangeOptions/getHAQStatusChangeOptions";

type TGetPossibleStatusChangeMenuOptionsForSponsor = {
  organization: IOrganization | undefined;
  t: TFunction;
  isProjectManager: boolean;
  handleClick: (bulkAction: TBulkStatusChangeAction) => void;
};

export const getPossibleStatusChangeMenuOptionsForSponsor = ({
  organization,
  isProjectManager,
  handleClick,
  t,
}: TGetPossibleStatusChangeMenuOptionsForSponsor): TMenuOption[] => {
  const statuses = [
    ...includeInArray(HAQStatus.Draft, isProjectManager),
    HAQStatus.Final,
  ];

  return [
    HAQBulkActionType.QuestionOnly,
    HAQBulkActionType.ResponseOnly,
    HAQBulkActionType.QuestionAndResponse,
  ].map((actionType) => ({
    id: actionType,
    label: t(`HAQs.${actionType}`),
    items: statuses.map((status: HAQStatus) => ({
      id: `${actionType}-${status}`,
      label: getDisplayedAssetStatus(organization?.type, status, t),
      onClick: () =>
        handleClick({
          actionType,
          status,
        }),
    })),
  }));
};

type TGetPossibleStatusChangeMenuOptionsForHA = {
  selectedRows: TSelectedHAQTableRow[];
  organization: IOrganization | undefined;
  t: TFunction;
  isProjectManager: boolean;
  handleClick: (status: HAQStatus) => void;
  isAnyHAQCopied: boolean;
};

export const getPossibleStatusChangeMenuOptionsForHA = ({
  selectedRows,
  organization,
  isProjectManager,
  handleClick,
  isAnyHAQCopied,
  t,
}: TGetPossibleStatusChangeMenuOptionsForHA): TMenuOption[] =>
  chain(selectedRows)
    .map((row) => {
      const statusChangeOptions = getHAQStatusChangeOptions({
        HAQ: row.HAQ,
        HAQResponse: row.HAQResponse,
        organization,
        isProjectManager,
        hasHAQContent: Boolean(row.HAQ.assetContent?.content),
        hasHAQResponseContent: Boolean(row.HAQResponse?.assetContent?.content),
        isHAQCopied: isAnyHAQCopied,
        isHAQRCopied: false, // doesn't apply for HA users
      });

      return getCanChangeHAQStatus(row.HAQ, row.HAQResponse, organization)
        ? statusChangeOptions
        : [];
    })
    .reduce(
      (acc: TManualHAQStatusOptions[], option: TManualHAQStatusOptions[]) =>
        intersection(acc, option),
    )
    .compact()
    .map((status: HAQStatus) => ({
      id: status,
      label: getDisplayedAssetStatus(organization?.type, status, t),
      onClick: () => handleClick(status),
    }))
    .value();
