import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import {
  FormComboBox,
  FormMultiSelectTypeahead,
  FormSelect,
  FormTextInput,
} from "components/common/form-fields";
import useEntityOrganizations from "hooks/api/GQL/entityManagement/useEntityOrganizations";
import useProductFamilies from "hooks/api/GQL/entityManagement/useProductFamilies";
import useSubstances from "hooks/api/GQL/entityManagement/useSubstances";
import { formatValuesToOptions } from "utils/helpers";
import {
  getOrganizationsOptions,
  getProductAndProductVariantStatusOptions,
} from "../ProductsTable/ProductsTable.utils";
import { ProductEntityFieldName } from "./ProductEntityFormFields.types";

const ProductEntityFormFields = () => {
  const { t } = useTranslation(["administration", "common"]);

  const { data: substanceData } = useSubstances();

  const { data: productFamiliesData } = useProductFamilies();

  const { organizations } = useEntityOrganizations();

  const organizationsOptions = useMemo(
    () => getOrganizationsOptions(organizations, t),
    [organizations, t],
  );

  const substanceOptions = useMemo(
    () =>
      formatValuesToOptions(
        substanceData,
        (substance) => substance.name,
        (substance) => substance.id,
      ),
    [substanceData],
  );

  const productFamiliesOptions = useMemo(
    () =>
      formatValuesToOptions(
        productFamiliesData,
        (productFamily) => productFamily.name,
        (productFamily) => productFamily.id,
      ),
    [productFamiliesData],
  );

  const statusOptions = useMemo(
    () => getProductAndProductVariantStatusOptions(t),
    [t],
  );

  return (
    <Grid container spacing={2} rowGap={1} mb={4}>
      <Grid item xs={6}>
        <FormTextInput
          fullWidth
          name={ProductEntityFieldName.ProductName}
          label={t("manageProductFormFields.name")}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextInput
          fullWidth
          name={ProductEntityFieldName.NonproprietaryName}
          label={t("manageProductFormFields.nonproprietaryName")}
        />
      </Grid>

      <Grid item xs={6}>
        <FormComboBox
          fullWidth
          name={ProductEntityFieldName.LinkedSubstances}
          label={t("manageProductFormFields.linkedSubstances")}
          options={substanceOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <FormComboBox
          fullWidth
          name={ProductEntityFieldName.LinkedProductFamilies}
          label={t("manageProductFormFields.linkedProductFamilies")}
          options={productFamiliesOptions}
        />
      </Grid>
      <Grid item xs={6}>
        <FormMultiSelectTypeahead
          fullWidth
          name={ProductEntityFieldName.LinkedOrganizations}
          label={t("manageProductFormFields.linkedOrganizations")}
          options={organizationsOptions}
        />
      </Grid>

      <Grid item xs={6}>
        <FormSelect
          fullWidth
          name={ProductEntityFieldName.Status}
          label={t("manageProductFormFields.status")}
          options={statusOptions}
        />
      </Grid>
    </Grid>
  );
};

export default ProductEntityFormFields;
