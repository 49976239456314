import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { gql } from "graphql-request";
import { queryClient } from "config/reactQueryClient";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { TUpdateEntityOrganizationBody } from "./useUpdateEntityOrganization.models";

export const query = gql`
  mutation UpdateEntityOrganization(
    $entityOrganization: EntityOrganizationUpdateInput
  ) {
    updateEntityOrganization(entityOrganization: $entityOrganization) {
      id
      name
      alias
      type
      roleTypes
      address
      city
      country
      zipCode
      status
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
    }
  }
`;

type TUseUpdateEntityOrganizationMutationData = {
  entityOrganization: TUpdateEntityOrganizationBody;
};

const useUpdateEntityOrganization = () => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess, isError, mutateAsync } = useMutation(
    ({ entityOrganization }: TUseUpdateEntityOrganizationMutationData) =>
      services.graphQL
        .request(query, {
          entityOrganization,
        })
        .then(() => {
          showAlert({
            message: t("notifications.updateEntityOrganizationSuccess"),
            severity: AlertSeverity.Success,
          });

          queryClient.invalidateQueries(QueryAPIKey.GetEntityOrganizations);
        })
        .catch((error) => {
          showAlert({
            message: t("notifications.updateEntityOrganizationFailure"),
            severity: AlertSeverity.Error,
          });

          throw error;
        }),
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    updateOrganization: mutateAsync,
  };
};

export default useUpdateEntityOrganization;
