import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Button,
  Card,
  Loading,
  ReactTable,
  TableFilter,
} from "components/shared";
import NoDataFallback from "components/shared/NoDataFallback";
import ReactTableRow from "components/shared/ReactTable/ReactTableRow";
import useEntityProducts from "hooks/api/GQL/entityManagement/useEntityProducts";
import useReactTableFilters from "hooks/filters/useReactTableFilters";
import useUser from "hooks/useUser";
import { TEntityProduct, TProductVariant } from "../Products.types";
import { getColumns } from "./columns";
import {
  ProductsTable as ProductsTableColumns,
  TProductsRowData,
} from "./ProductsTable.types";
import { getProductsForRender } from "./ProductsTable.utils";
import ProductVariantsTable from "./ProductVariantsTable";
import { useProductsFilters } from "./useProductsFilters";
import { PlusIcon } from "assets/icons";
import styles from "./ProductsTable.styles";

type TProductsTableProps = {
  onEditProductVariant: (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
  ) => void;
  onCreateProductVariant: (product: TEntityProduct) => void;
  onClickCreateProduct: () => void;
  onChangeProductVariantStatus: (
    product: TEntityProduct | undefined,
    productVariant: TProductVariant,
    variantIndex: number,
  ) => void;
  onChangeProductStatus: (product: TEntityProduct | undefined) => void;
};

const ProductsTable = ({
  onEditProductVariant,
  onCreateProductVariant,
  onClickCreateProduct,
  onChangeProductVariantStatus,
  onChangeProductStatus,
}: TProductsTableProps) => {
  const { t } = useTranslation(["administration", "common"]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { entityProducts, isLoading } = useEntityProducts();

  const user = useUser();

  const columnsData = useMemo(() => getColumns(t), [t]);

  const onEditProduct = () => {
    // https://accumulus.atlassian.net/browse/FTE-26080
  };

  const rowData = useMemo(
    () =>
      getProductsForRender(
        entityProducts,
        t,
        onEditProduct,
        onCreateProductVariant,
        onChangeProductStatus,
        user,
      ),
    [entityProducts, onCreateProductVariant, onChangeProductStatus, t, user],
  );

  const { setGlobalFilter, setColumnFilters, ...tableInstance } =
    useReactTable<TProductsRowData>({
      columns: columnsData,
      data: rowData,
      state: { expanded },
      enableColumnFilters: true,
      enableFilters: true,
      enableSorting: true,
      enableRowSelection: false,
      enableMultiRowSelection: false,
      enableGlobalFilter: true,
      meta: {
        expandableRowProps: {
          allColumnsSpanned: false,
        },
      },
      initialState: {
        columnVisibility: {
          [ProductsTableColumns.Id]: false,
        },
        pagination: {
          pageSize: 20,
        },
      },
      onExpandedChange: setExpanded,
      getPaginationRowModel: getPaginationRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

  const { filterParams, keywordParam, onKeywordChange, onFiltersChange } =
    useReactTableFilters(setGlobalFilter, setColumnFilters);

  const productsFilters = useProductsFilters({ products: entityProducts });

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <TableFilter
          filters={productsFilters}
          selectedFilters={filterParams}
          keywordFilterValue={keywordParam}
          onFiltersChange={onFiltersChange}
          onKeywordChange={onKeywordChange}
          filterByKeywordLabel={t("keywordFilter.filterByKeyword", {
            ns: "common",
          })}
          showButtonLabel={t("filter.showFilterButton", { ns: "common" })}
          hideButtonLabel={t("filter.hideFilterButton", { ns: "common" })}
          clearAllFiltersLabel={t("filter.clearAllFiltersLabel", {
            ns: "common",
          })}
          errorAdornmentAriaLabel={t("ariaLabels.textFieldError", {
            ns: "common",
          })}
        />

        {user.isBusinessAdmin && (
          <Button
            startIcon={<PlusIcon />}
            variant="contained"
            onClick={onClickCreateProduct}
            aria-label={t("product.addNewProduct")}
            sx={{ ml: 2 }}
          >
            {t("product.addNewProduct")}
          </Button>
        )}
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <ReactTable<TProductsRowData>
            sx={styles.table}
            tableInstance={tableInstance}
            isPaginated
            onRowClick={(row) => row.toggleExpanded()}
            rowRenderer={(props) => (
              <ReactTableRow<TProductsRowData>
                {...props}
                renderSubComponent={(row) => (
                  <ProductVariantsTable
                    productId={row.original[ProductsTableColumns.Id]}
                    onEditProductVariant={(productVariant) =>
                      onEditProductVariant(
                        entityProducts?.find(
                          (product) =>
                            product?.id ===
                            row.original[ProductsTableColumns.Id],
                        ),
                        productVariant,
                      )
                    }
                    onChangeProductVariantStatus={(
                      productVariant,
                      variantIndex,
                    ) =>
                      onChangeProductVariantStatus(
                        entityProducts?.find(
                          (product) =>
                            product?.id ===
                            row.original[ProductsTableColumns.Id],
                        ),
                        productVariant,
                        variantIndex,
                      )
                    }
                  />
                )}
              />
            )}
            EmptyStateComponent={
              <NoDataFallback message={t("noProductsFound")} noBorders />
            }
          />
        </Card>
      )}
    </Stack>
  );
};

export default ProductsTable;
