import { Box } from "@mui/material";
import Chip, { TChipProps } from "components/shared/Chip";
import { DocumentStatus } from "utils/constants/doc.constants";
import {
  InvitationStatus,
  SubmissionPlan,
} from "utils/constants/gsp.constants";
import {
  DecisionStatus,
  ProcessingStatus,
} from "utils/constants/project.constants";
import { ProjectMemberStatus } from "utils/constants/projectMembers.constants";
import { UserStatus } from "utils/constants/users.constants";
import { omit } from "utils/helpers";
import styles from "./StatusChip.styles";

export type TStatusVariant =
  | DocumentStatus
  | UserStatus
  | ProjectMemberStatus
  | SubmissionPlan
  | ProcessingStatus
  | InvitationStatus
  | DecisionStatus;

export type TStatusChipProps = {
  status: TStatusVariant | null;
  isIconVisible?: boolean;
  chipProps?: TChipProps;
};

const StatusChip = (props: TStatusChipProps) => {
  if (!props.status || !props.chipProps) {
    return <Box sx={styles.noStatus}>&mdash;</Box>;
  }

  return (
    <Chip
      data-qaid={props.status}
      size="small"
      sx={styles.chip}
      variant="outlined"
      {...(props.isIconVisible
        ? props.chipProps
        : omit(props.chipProps, ["icon"]))}
    />
  );
};

export default StatusChip;
