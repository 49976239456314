import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { MoreVert, People } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { Tooltip } from "components/shared";
import ButtonMenu, {
  TButtonMenuProps,
  TMenuOption,
} from "components/shared/ButtonMenu";
import { useProject } from "context/ProjectContext";
import useShareHistory from "hooks/api/REST/shareHistory/useShareHistory";
import usePermissions from "hooks/permissions";
import useDownloadBlob from "hooks/useDownloadBlob";
import { useUserRoles } from "hooks/user";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import { useDocumentsContext } from "screens/Project/sections/Documents/DocumentsContext";
import { getAvailableDocumentStatusChangeOptions } from "screens/Project/sections/Documents/utils/documentStatusChanges";
import {
  getCanDocumentBeConverted,
  getCanDocumentBeDeleted,
  getCanDocumentBeDownloaded,
  getCanDocumentBeHAQed,
  getCanDocumentBePreviewed,
  getCanDocumentBeSent,
  getCanDocumentBeShared,
  getCanDocumentBeSubmitted,
  getCanViewSubmitHistory,
} from "screens/Project/sections/Documents/utils/documentStatuses";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import {
  DocumentAction,
  DocumentStatus,
  DocumentSubtype,
} from "utils/constants/doc.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import { ShowSubmitHistoryIcon } from "assets/icons";

export type TRowActionsProps = {
  document: IDocument;
};

const RowActions = ({ document }: TRowActionsProps) => {
  const options: TMenuOption[] = [];

  const { project, participatingHAOrganizationsExceptUserOrganization } =
    useProject();

  const downloadBlob = useDownloadBlob(document);

  const { permissions } = usePermissions(project?.id ?? "");

  const [, setSearchParams] = useSearchParams();

  const { setCurrentActionOnDocument } = useDocumentsContext();

  const documentId = document.id;

  const { shareHistory } = useShareHistory(documentId);

  const { t } = useTranslation(["documents", "common", "statuses"]);

  const { user, organization } = useUser();

  const userRoles = useUserRoles(project?.id);

  if (
    project?.active &&
    getCanDocumentBeConverted(document, organization, permissions)
  ) {
    options.push({
      id: DocumentAction.Convert,
      label: t("documents.utils.options.convertToStructuredAAID"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Convert,
        }),
    });
  }

  if (
    permissions.canSendContentToTenancy &&
    project?.active &&
    project?.status === ProjectStatus.InProgress &&
    getCanDocumentBeSubmitted(document, organization)
  ) {
    options.push({
      id: DocumentAction.Submit,
      label: t("documents.utils.options.submitDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Submit,
        }),
    });
  }

  if (project?.active && getCanDocumentBeHAQed(document, organization)) {
    options.push({
      id: DocumentAction.AddQuestion,
      label: t("documents.utils.options.createHAQ"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.AddQuestion,
        }),
    });
  }

  if (
    project?.active &&
    !isEmpty(participatingHAOrganizationsExceptUserOrganization) &&
    getCanDocumentBeShared(document, organization, userRoles)
  ) {
    options.push({
      id: DocumentAction.Share,
      label: t("documents.utils.options.shareDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Share,
        }),
    });
  }

  if (project?.active && getCanDocumentBeSent(document, organization)) {
    options.push({
      id: DocumentAction.SendCopy,
      label: t("documents.utils.options.sendCopy"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.SendCopy,
        }),
    });
  }

  if (getCanDocumentBePreviewed(document, permissions)) {
    options.push({
      id: DocumentAction.Preview,
      label: t("documents.utils.options.previewDocument"),
      onClick: () => {
        setSearchParams(new URLSearchParams({ documentId: document.id }));
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Preview,
        });
      },
    });
  }

  if (getCanDocumentBeDownloaded(document)) {
    options.push({
      id: DocumentAction.Download,
      label: t("documents.utils.options.downloadDocument"),
      onClick: downloadBlob,
    });
  }

  if (
    getCanDocumentBeDeleted(
      project,
      document,
      user,
      organization,
      userRoles,
      permissions,
    )
  ) {
    options.push({
      id: DocumentAction.Delete,
      label: t("documents.utils.options.deleteDocument"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.Delete,
        }),
    });
  }

  const documentStatusChangeOptions = project?.active
    ? getAvailableDocumentStatusChangeOptions(
        document,
        organization,
        user,
        permissions,
        t,
      )
    : [];

  if (documentStatusChangeOptions.length) {
    options.push({
      id: DocumentAction.ChangeStatus,
      label: t("documents.utils.options.changeStatus"),
      items: documentStatusChangeOptions,
      onClick: (option) => {
        if (option && document.status !== option.id) {
          setCurrentActionOnDocument({
            documents: [document],
            documentAction: DocumentAction.ChangeStatus,
            targetStatus: option.id as DocumentStatus,
          });
        }
      },
    });
  }

  options.push({
    id: DocumentAction.ViewContentInfo,
    label: t("documents.utils.options.viewContentInformation"),
    onClick: () => {
      if (document.type !== DocumentSubtype.ProjectParticipants) {
        services.tracking.addTrackingEvent({
          assetId: document?.id ?? "",
          assetType: AssetType.Document,
          eventType: EventType.AppContentMetadataView,
          projectId: project?.id ?? "",
        });
      }

      setCurrentActionOnDocument({
        documents: [document],
        documentAction: DocumentAction.ViewContentInfo,
      });
    },
  });

  if (document.status === DocumentStatus.Acknowledged) {
    const isCleared = Boolean(document.metadata?.cleared);

    const label = isCleared
      ? t("documents.utils.options.unmarkCleared")
      : t("documents.utils.options.markCleared");

    options.push({
      id: DocumentAction.HandleClearedStatus,
      label,
      // ToDo (DL): Replace handler with BE request with integration ticket
      onClick: noop,
    });
  }

  const isDocumentShared =
    permissions.canSeeShareHistory && !isEmpty(shareHistory?.content);

  if (isDocumentShared) {
    options.push({
      id: DocumentAction.ViewShareHistory,
      label: t("documents.utils.options.viewShareHistory"),
      onClick: () =>
        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.ViewShareHistory,
        }),
    });
  }

  if (getCanViewSubmitHistory(document, organization)) {
    options.push({
      id: DocumentAction.ViewSubmitHistory,
      label: t("documents.utils.options.viewSubmitHistory"),
      onClick: () => {
        services.tracking.addTrackingEvent({
          assetId: document?.id ?? "",
          assetType: AssetType.Document,
          eventType: EventType.AppContentSubmitHistoryView,
          projectId: project?.id ?? "",
        });

        setCurrentActionOnDocument({
          documents: [document],
          documentAction: DocumentAction.ViewSubmitHistory,
        });
      },
    });
  }

  const buttonMenuProps: TButtonMenuProps = {
    options,
    onClick: (event) => {
      event.stopPropagation(); // Prevents row selection from firing when clicking a menu option
    },
    ...(documentStatusChangeOptions.length && {
      value: document.status,
    }),
  };

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center">
      {isDocumentShared && (
        <Tooltip
          title={t(`documents.shareHistory.items.status.SHARED`)}
          placement="left"
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setCurrentActionOnDocument({
                documents: [document],
                documentAction: DocumentAction.ViewShareHistory,
              });
            }}
          >
            <People />
          </IconButton>
        </Tooltip>
      )}
      {getCanViewSubmitHistory(document, organization) && (
        <Tooltip
          title={t(`documents.submissionHistory.submitHistoryLabel`)}
          placement="left"
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();

              services.tracking.addTrackingEvent({
                assetId: document?.id ?? "",
                assetType: AssetType.Document,
                eventType: EventType.AppContentSubmitHistoryView,
                projectId: project?.id ?? "",
              });

              setCurrentActionOnDocument({
                documents: [document],
                documentAction: DocumentAction.ViewSubmitHistory,
              });
            }}
          >
            <ShowSubmitHistoryIcon />
          </IconButton>
        </Tooltip>
      )}
      {buttonMenuProps.options.length ? (
        <ButtonMenu {...buttonMenuProps}>
          <IconButton
            aria-label={t("documentHeader.actionsButton")}
            size="large"
          >
            <MoreVert />
          </IconButton>
        </ButtonMenu>
      ) : null}
    </Stack>
  );
};

export default RowActions;
