import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Box, Divider } from "@mui/material";
import Tabs, { Tab } from "components/shared/Tabs";
import { TableSearchURLParams } from "../ReactTable.types";
import styles from "./ReactTableTabs.styles";

export type TReactTableTabs = { label: string; accessor: string };

const ReactTableTabs = ({
  tabs,
  onTabChange,
}: {
  tabs: TReactTableTabs[];
  onTabChange?: () => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const defaultValue = tabs[0]?.accessor || null;
  const tabValueFromUrl = searchParams.get(TableSearchURLParams.Tab);

  const isValidTabFromUrl = tabs.some(
    (tab) => tab.accessor === tabValueFromUrl,
  );

  const [activeValue, setActiveValue] = useState<string | null>(
    isValidTabFromUrl ? tabValueFromUrl : defaultValue,
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setSearchParams(
      new URLSearchParams({ [TableSearchURLParams.Tab]: newValue }),
    );

    onTabChange?.();
  };

  useEffect(() => {
    const activeTab = isValidTabFromUrl ? tabValueFromUrl : activeValue;

    const activeSearchParams = activeTab
      ? createSearchParams({
          [TableSearchURLParams.Tab]: activeTab,
        }).toString()
      : "";

    navigate(
      {
        search: activeSearchParams,
      },
      { replace: true },
    );
    setActiveValue(activeTab);
  }, [activeValue, isValidTabFromUrl, navigate, tabValueFromUrl]);

  return (
    <>
      <Box px={2}>
        <Tabs
          value={activeValue}
          aria-label="table-tabs"
          sx={styles.tabs}
          onChange={handleChange}
        >
          {tabs?.map(({ label, accessor }) => (
            <Tab
              key={label}
              value={accessor}
              label={label}
              tabIndex={0}
              disableFocusRipple
              sx={styles.tab}
            />
          ))}
        </Tabs>
      </Box>
      <Divider sx={styles.divider} />
    </>
  );
};

export default ReactTableTabs;
