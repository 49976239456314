import { CommentsAdapter } from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";
import { Context, Editor } from "@ckeditor/ckeditor5-core";
import { IComment } from "models/annotations.models";
import { TCommentForUpdate } from "../useUpdateComment";

const updateComment =
  (
    editorOrContext: Editor | Context,
    mutationFn: (commentData: TCommentForUpdate) => Promise<{ data: IComment }>,
  ): CommentsAdapter["updateComment"] =>
  async (data) => {
    const { channelId, commentId, threadId } = data;

    const commentsRepository =
      editorOrContext.plugins.get("CommentsRepository");

    const commentFromPlugin = commentsRepository
      .getCommentThread(threadId)
      ?.getComment(commentId);

    await mutationFn({
      componentId: String(channelId),
      threadId,
      commentId,
      requestData: {
        attributes: commentFromPlugin?.attributes || {},
        content: commentFromPlugin?.content || "",
      },
    });
  };

export default updateComment;
