import { Suspense } from "react";
import { Outlet } from "react-router";
import { Box, Stack } from "@mui/material";
import { MainLayoutErrorBoundary } from "components/common/ErrorBoundaries";
import GlobalNavigation from "components/GlobalNavigation";
import Loading from "components/shared/Loading";
import styles from "./MainLayout.styles";

const MainLayout = () => {
  return (
    <Stack spacing={0} direction="row">
      <GlobalNavigation />
      <Box component="main" sx={styles.content}>
        <Suspense fallback={<Loading />}>
          <MainLayoutErrorBoundary>
            <Outlet />
          </MainLayoutErrorBoundary>
        </Suspense>
      </Box>
    </Stack>
  );
};

export default MainLayout;
