import { PropsWithChildren } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import styles from "./TeaserCard.styles";

type TTeaserCardProps = PropsWithChildren<{
  title: string;
  description?: JSX.Element;
  imgBox: JSX.Element;
}>;

const TeaserCard = ({
  title,
  imgBox,
  description,
  children,
}: TTeaserCardProps) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      sx={styles.teaserContainer}
    >
      <Grid item xs={5} sx={styles.imgBlock}>
        {imgBox}
      </Grid>
      <Grid container item xs={4} sx={styles.contentBlock} alignItems="center">
        <Grid item xs zeroMinWidth>
          <Stack
            data-testid="teaser-document-name"
            data-qaid="teaser-document-name"
            mb={0.5}
          >
            <Typography variant="subtitle1" component="h2" noWrap>
              {title}
            </Typography>
            {description}
          </Stack>
        </Grid>
        {children && (
          <Grid item pl={1}>
            {children}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TeaserCard;
