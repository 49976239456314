import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { ConfirmModal } from "components/shared";
import Button from "components/shared/Button";
import FormWrapper from "components/shared/FormWrapper";
import Modal from "components/shared/Modal";
import ModalActionButtons from "components/shared/ModalActionButtons";
import { useProject } from "context/ProjectContext";
import useSubmitGSP from "hooks/api/REST/documents/useSubmitGSP";
import usePermissions from "hooks/permissions";
import useUser from "hooks/useUser";
import { IDocument } from "models/documents.models";
import { EventType } from "models/tracking.models";
import {
  getCanDocumentBeSubmitted,
  getIsDocumentComposite,
} from "screens/Project/sections/Documents/utils/documentStatuses";
import { services } from "services";
import { AssetType } from "utils/constants/assets.constants";
import { DocumentSubtype } from "utils/constants/doc.constants";
import { ProjectStatus } from "utils/constants/project.constants";
import AddDocuments, { useAddDocuments } from "../fields/AddDocuments";
import FieldsLayout from "./components/FieldsLayout";
import {
  SubmitDocumentFieldName,
  TSubmitDocumentForm,
} from "./SubmitDocuments.types";
import {
  getSubmitDocumentsDefaultValues,
  submitDocumentsSchema,
} from "./SubmitDocuments.utils";

export type TSubmitDocumentsModalProps = {
  documents: IDocument[];
  onClose: () => void;
  onSubmitUnstructuredDocs: (submissionData: any) => void;
  projectId: string | undefined;
};

const SubmitDocuments = ({
  documents,
  onClose,
  onSubmitUnstructuredDocs,
  projectId,
}: TSubmitDocumentsModalProps) => {
  const { tasksSuccessfullyLoaded, submitGSP } = useSubmitGSP(projectId);

  const { project } = useProject();
  const { organization } = useUser();
  const { permissions } = usePermissions(project?.id ?? "");

  const { t } = useTranslation(["documents", "common"]);

  const [submissionActionQueued, setSubmissionActionQueued] = useState(false);

  const methods = useForm<TSubmitDocumentForm>({
    mode: "all",
    defaultValues: getSubmitDocumentsDefaultValues(documents),
    resolver: zodResolver(submitDocumentsSchema),
  });

  const {
    formState,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    getValues,
  } = methods;

  useEffect(() => {
    methods.reset(getSubmitDocumentsDefaultValues(documents));
  }, [methods, documents]);

  useEffect(() => {
    reset(getSubmitDocumentsDefaultValues(documents));
  }, [reset, documents]);

  const selectedDocuments = watch(
    SubmitDocumentFieldName.Documents,
  ) as IDocument[];

  const { isAddState, setIsAddState, docsToAdd, ...addDocumentsProps } =
    useAddDocuments({
      projectId,
      validateDocument: (doc: IDocument) =>
        getCanDocumentBeSubmitted(doc, organization),
      selectedDocuments,
    });

  const updateDocumentsValue = useCallback(() => {
    setValue(
      SubmitDocumentFieldName.Documents,
      [...selectedDocuments, ...docsToAdd],
      {
        shouldValidate: true,
      },
    );
    clearErrors(SubmitDocumentFieldName.Documents);
  }, [docsToAdd, selectedDocuments, setValue, clearErrors]);

  const handleDocumentsSubmission = async (data: TSubmitDocumentForm) => {
    // If submitting GSP, need to get the associated 'submit-gsp' task first
    if (isSubmittingGSP) {
      const submitData = {
        ...data,
        // Do not need to include the actual GSP document as a supporting asset
        documents: data.documents.filter(
          (doc) => doc.type !== DocumentSubtype.GSP,
        ),
      };

      await submitGSP(submitData);
      onClose();
      setIsAddState(false);
    }

    if (isSubmittingUnstructuredDoc) {
      services.tracking.addBulkTrackingEvent(
        data.documents.map((document) => ({
          assetId: document.id,
          assetType: AssetType.Document,
          eventType: EventType.AppContentSubmit,
          projectId: project?.id || "",
        })),
      );

      onSubmitUnstructuredDocs(data);
    }
  };

  const isSubmittingGSP = useMemo(() => {
    return Boolean(
      documents.find((document) => document.type === DocumentSubtype.GSP),
    );
  }, [documents]);

  const isSubmittingUnstructuredDoc = useMemo(() => {
    return Boolean(
      documents.some((document) => !getIsDocumentComposite(document)),
    );
  }, [documents]);

  const isSubmitDisabled =
    !formState.isValid ||
    (isSubmittingGSP && !tasksSuccessfullyLoaded) ||
    (permissions.canSubmitDocument &&
      project?.status !== ProjectStatus.InProgress);

  const buttons = useMemo(
    () =>
      isAddState
        ? [
            <Button
              key="cancel"
              data-qaid="add-submit-document-cancel-button"
              variant="text"
              onClick={() => setIsAddState(false)}
            >
              {t("button.cancel", { ns: "common" })}
            </Button>,
            <Button
              color="primary"
              key="submit"
              data-qaid="add-submit-document-add-button"
              variant="contained"
              onClick={(event: SyntheticEvent) => {
                // prevents action from submitting parent modal form
                event?.preventDefault();
                updateDocumentsValue();
                setIsAddState(false);
              }}
              disabled={!Boolean(docsToAdd.length)}
            >
              {t("documents.submitDocuments.modalAddDocumentsSubmit")}
            </Button>,
          ]
        : [
            <Button
              key="cancel"
              data-qaid="submit-document-cancel-button"
              variant="text"
              onClick={onClose}
            >
              {t("button.cancel", { ns: "common" })}
            </Button>,
            <Button
              color="primary"
              key="submit"
              data-qaid="submit-document-submit-button"
              variant="contained"
              disabled={isSubmitDisabled}
              type="submit"
            >
              {t("button.submit", { ns: "common" })}
            </Button>,
          ],
    [
      updateDocumentsValue,
      isSubmitDisabled,
      docsToAdd.length,
      setIsAddState,
      isAddState,
      onClose,
      t,
    ],
  );

  const onQueueSubmission = () => {
    setSubmissionActionQueued(true);
  };

  const onHandleConfirmation = (result: boolean) => {
    if (result) {
      handleDocumentsSubmission(getValues());
    } else {
      setSubmissionActionQueued(false);
    }
  };

  return (
    <Modal
      open
      data-qaid="submit-documents-modal"
      title={t("documents.submitDocuments.modalTitle")}
    >
      <FormWrapper
        methods={methods}
        onSubmit={
          isSubmittingGSP
            ? handleSubmit(handleDocumentsSubmission)
            : handleSubmit(onQueueSubmission)
        }
      >
        {isAddState ? (
          <AddDocuments {...addDocumentsProps} />
        ) : (
          <FieldsLayout
            setIsAddDocsView={(event?: SyntheticEvent) => {
              event?.preventDefault();
              setIsAddState(true);
            }}
          />
        )}
        <ModalActionButtons buttons={buttons} />
      </FormWrapper>
      <ConfirmModal
        title={t("documents.submitDocuments.confirmSubmissionModal.modalTitle")}
        open={submissionActionQueued}
        confirmColor="primary"
        confirmButtonText={t(
          "documents.submitDocuments.confirmSubmissionModal.modalSubmit",
        )}
        cancelButtonText={t("button.cancel", { ns: "common" })}
        onConclude={onHandleConfirmation}
      >
        {t("documents.submitDocuments.confirmSubmissionModal.modalText")}
      </ConfirmModal>
    </Modal>
  );
};

export default SubmitDocuments;
