import { TStyles } from "utils/Styles";

const styles: TStyles = {
  container: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  aaidNote: {
    mb: 1.5,
  },
};

export default styles;
