import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { gql, Variables } from "graphql-request";
import { useAlerts } from "context/AlertContext";
import { services } from "services";
import { AlertSeverity } from "utils/constants/alerts.constants";
import { QueryAPIKey } from "utils/constants/api.constants";
import { IEntityOrganizationsResponse } from "./useEntityOrganizations.models";

export const query = gql`
  query GetEntityOrganizations {
    entityOrganizations {
      id
      name
      alias
      type
      roleTypes
      country
      status
      address
      city
      zipCode
    }
  }
`;

const useEntityOrganizations = (enabled: boolean = true) => {
  const { t } = useTranslation("administration");
  const { showAlert } = useAlerts();

  const { data, isLoading, isSuccess } = useQuery<IEntityOrganizationsResponse>(
    [QueryAPIKey.GetEntityOrganizations],
    () =>
      services.graphQL.request<IEntityOrganizationsResponse, Variables>(query),
    {
      enabled,
      onError: () => {
        showAlert({
          severity: AlertSeverity.Error,
          message: t("notifications.getEntityOrganizationsFailure"),
        });
      },
    },
  );

  return {
    organizations: data?.entityOrganizations ?? [],
    isLoading,
    isSuccess,
  };
};

export default useEntityOrganizations;
